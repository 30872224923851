import Styled from 'styled-components';

export const ContainerFaceMatch = Styled.div`
  width: 45%;
`;

export const CardInfo = Styled.div`
  background-color: ${(props) => props.theme.colors.veryLightGray_2};
  padding: 1.5rem;
  border-radius: 10px;
`;

export const Flex = Styled.div`
  display: flex;
`;

export const ConteinerSearch = Styled.div`
  padding-right: 2vw;
`;

export const SpanButtonTitle = Styled.span`
  font-size: 12px;
`;

export const ConteinerResult = Styled.div`
  width: 100%;
`;

export const SectionLabel = Styled.div`
  display: flex;
  align-items: center;
`;

export const CompareDocument = Styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const SectionResult = Styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColorMatch = Styled.h4`
  color: ${(props) => props.color};
`;

export const ColorProbability = Styled.h4`
  color: ${(props) => props.color};
`;

export const StyleH3 = Styled.h3`
  padding-right: 0.8vw;
`;

export const ColorOcrMatch = Styled.h6`
  color: ${(props) => props?.color} !important;
  margin-left: 0.2vw;
`;

export const ContainerOCR = Styled.div`
  width: 53%;
`;

export const ContainerResultOcrDetail = Styled.div`
  color = ${(props) => props?.color};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 0 0.6vw 0;

  & h6{
    color: ${(props) => props.theme.colors.vividRed_2};
    font-size: 12px;
    margin-left: 0.6vw;
  }
`;

export const DetailInfoOcr = Styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoOcr = Styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0.6vw ;

  & h4{
    padding: 1vw 0 0.6vw 0;
  }
`;

export const ResultOCR = Styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const TitleResultOcr = Styled.h3`
  margin: 0 0 1.2vw 0;
`;

export const defaultResultOcr = Styled.div`
  h4{
    margin-left: 2.4vw;
  }
`;
