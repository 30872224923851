import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.2vw" height="1.2vw" fill="none" viewBox="0 0 48 48">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M41.5 10h-6M27.5 6v8M27.5 10h-22M13.5 24h-8M21.5 20v8M43.5 24h-22M41.5 38h-6M27.5 34v8M27.5 38h-22"
      ></path>
    </svg>
  );
}

export default Icon;
