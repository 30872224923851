import Styled from 'styled-components';

export const LoadingContainer = Styled.div`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  color: ${(props) => props.theme.colors.loader};
`;
