import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  listDocumentRepresentative: [],
};

export const approveUserActionLoading = createAction('APPROVE_USER_ACTION_LOADING');
export const approveUserActionSuccess = createAction('APPROVE_USER_ACTION_SUCCESS');
export const approveUserActionError = createAction('APPROVE_USER_ACTION_ERROR');
export const listTypeSignatoriesLoading = createAction('LIST_TYPE_SIGNATORIES_ACTION_LOADING');
export const listTypeSignatoriesSuccess = createAction('LIST_TYPE_SIGNATORIES_ACTION_SUCCESS');
export const listTypeSignatoriesError = createAction('LIST_TYPE_SIGNATORIES_ACTION_ERROR');
export const listTypeRepresentativeLoading = createAction('LIST_TYPE_REPRESENTATIVE_ACTION_LOADING');
export const listTypeRepresentativeSuccess = createAction('LIST_TYPE_REPRESENTATIVE_ACTION_SUCCESS');
export const listTypeRepresentativeError = createAction('LIST_TYPE_REPRESENTATIVE_ACTION_ERROR');
export const listDocumentsRepresentativeLoading = createAction('LIST_DOCUMENTS_REPRESENTATIVE_ACTION_LOADING');
export const listDocumentsRepresentativeSuccess = createAction('LIST_DOCUMENTS_REPRESENTATIVE_ACTION_SUCCESS');
export const listDocumentsRepresentativeError = createAction('LIST_DOCUMENTS_REPRESENTATIVE_ACTION_ERROR');
export const approveComiteActionLoading = createAction('APPROVE_COMITE_ACTION_LOADING');
export const approveComiteActionSuccess = createAction('APPROVE_COMITE_ACTION_SUCCESS');
export const approveComiteActionError = createAction('APPROVE_COMITE_ACTION_ERROR');
export const negadeComiteActionLoading = createAction('APPROVE_COMITE_ACTION_LOADING');
export const negadeComiteActionSuccess = createAction('APPROVE_COMITE_ACTION_SUCCESS');
export const negadeComiteActionError = createAction('APPROVE_COMITE_ACTION_ERROR');
export const insertRepresentativeActionLoading = createAction('INSERT_REPRESENTATIVE_ACTION_LOADING');
export const insertRepresentativeActionSuccess = createAction('INSERT_REPRESENTATIVE_ACTION_SUCCESS');
export const insertRepresentativeActionError = createAction('INSERT_REPRESENTATIVE_ACTION_ERROR');
export const sendDocumentsAllActionLoading = createAction('SEND_DOCUMENTS_ALL_ACTION_LOADING');
export const sendDocumentsAllActionSuccess = createAction('SEND_DOCUMENTS_ALL_ACTION_SUCCESS');
export const sendDocumentsAllActionError = createAction('SEND_DOCUMENTS_ALL_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [approveUserActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingApproveUser: true,
      errorApproveUser: false,
    };
  },
  [approveUserActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApproveUser: false,
      errorApproveUser: false,
      retorno: payload.retorno,
      message: payload?.mensagem,
    };
  },
  [approveUserActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingApproveUser: false,
      errorApproveUser: true,
    };
  },
  [listTypeSignatoriesLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingListTypeSignatories: true,
      errorListTypeSignatories: false,
    };
  },
  [listTypeSignatoriesSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListTypeSignatories: false,
      errorListTypeSignatories: false,
      listSignatories: payload?.tipos_signatarios,
    };
  },
  [listTypeSignatoriesError.type]: (state, payload) => {
    return {
      ...state,
      loadingListTypeSignatories: false,
      errorListTypeSignatories: true,
    };
  },
  [approveComiteActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingApproveComite: true,
      errorApproveComite: false,
    };
  },
  [approveComiteActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApproveComite: false,
      errorApproveComite: false,
      retorno: payload.retorno,
      message: payload?.mensagem,
    };
  },
  [approveComiteActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingApproveComite: false,
      errorApproveComite: true,
    };
  },

  [negadeComiteActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingNegadeComite: true,
      errorNegadeComite: false,
    };
  },
  [negadeComiteActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingNegadeComite: false,
      errorNegadeComite: false,
      retorno: payload.retorno,
      message: payload?.mensagem,
    };
  },
  [negadeComiteActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingNegadeComite: false,
      errorNegadeComite: true,
    };
  },

  [listTypeRepresentativeLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingListTypeRepresentative: true,
      errorListTypeRepresentative: false,
    };
  },
  [listTypeRepresentativeSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListTypeRepresentative: false,
      errorListTypeRepresentative: false,
      listRepresentative: payload?.data,
    };
  },
  [listTypeRepresentativeError.type]: (state, payload) => {
    return {
      ...state,
      loadingListTypeRepresentative: false,
      errorListTypeRepresentative: true,
    };
  },
  [listDocumentsRepresentativeLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingListDocumentsRepresentative: true,
      errorListDocumentsRepresentative: false,
    };
  },
  [listDocumentsRepresentativeSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListDocumentsRepresentative: false,
      errorListDocumentsRepresentative: false,
      listDocumentRepresentative: payload?.payload,
    };
  },
  [listDocumentsRepresentativeError.type]: (state, payload) => {
    return {
      ...state,
      loadingListDocumentsRepresentative: false,
      errorListDocumentsRepresentative: true,
    };
  },
  [insertRepresentativeActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingInsertRepresentative: true,
      errorInsertRepresentative: false,
    };
  },
  [insertRepresentativeActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingInsertRepresentative: false,
      errorInsertRepresentative: false,
      retornoInsertRepresentative: payload?.retorno,
      messageInsertRepresentative: payload?.mensagem,
    };
  },
  [insertRepresentativeActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingInsertRepresentative: false,
      errorInsertRepresentative: true,
    };
  },
  [sendDocumentsAllActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSendDocumentsAll: true,
      errorSendDocumentsAll: false,
    };
  },
  [sendDocumentsAllActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendDocumentsAll: false,
      errorSendDocumentsAll: false,
      retornoSendDocumentsAll: payload?.retorno,
      messageSendDocumentsAll: payload?.mensagem,
    };
  },
  [sendDocumentsAllActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingSendDocumentsAll: false,
      errorSendDocumentsAll: true,
    };
  },
});
