import api from '../../utils/services/api';
import { unlockUserActionLoading, unlockUserActionSuccess, unlockUserActionError } from '../ducks/unlockUser';
import { showAlert } from '../ducks/sweetAlert';
import { blockedUsersFetchAction } from '../fetchActions/blockedUsersActions';

export const unlockUserFetchAction = (params, callback) => {
  return async (dispatch) => {
    dispatch(unlockUserActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`usuario/desbloquear`, params);
      dispatch(unlockUserActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
        }),
      );
      dispatch(blockedUsersFetchAction());
      callback();
    } catch (error) {
      const errorMessage = error?.response?.data?.mensagem;
      dispatch(
        showAlert({
          title: 'Ops...',
          text: errorMessage,
          icon: 'error',
        }),
      );
      dispatch(unlockUserActionError(error?.response?.data));
    }
  };
};
