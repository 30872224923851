import {
  editRepresentativesActionLoading,
  editRepresentativesActionSuccess,
  editRepresentativesActionError,
  activeRepresentativesActionLoading,
  activeRepresentativesActionSuccess,
  activeRepresentativesActionError,
  inativeRepresentativesActionLoading,
  inativeRepresentativesActionSuccess,
  inativeRepresentativesActionError,
} from '../ducks/representatives';
import { showAlert } from '../ducks/sweetAlert';
import api from '../../utils/services/api';
import { userFetchAction } from './usersActions';

export const editRepresentativesActions = (params, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(userFetchAction(params?.REPRESENTANTES[0]?.CNPJ_EMPRESA, 'willBeLoaded'));
    };

    dispatch(editRepresentativesActionLoading());
    dispatch(showAlert({}));
    try {
      const response = await api.post(`/usuario/representante/atualizar`, params);
      dispatch(editRepresentativesActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Representante atualizado com sucesso',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editRepresentativesActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const activeRepresentativesActions = (params) => {
  return async (dispatch) => {
    dispatch(activeRepresentativesActionLoading());
    dispatch(showAlert({}));
    try {
      const response = await api.post(`/usuario/representante/ativar`, params);
      dispatch(activeRepresentativesActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response.SUA_MENSAGEM,
          icon: 'success',
        }),
      );
    } catch (error) {
      dispatch(activeRepresentativesActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const inativeRepresentativesActions = (params, callback) => {
  return async (dispatch) => {
    const refresh = () => {
      dispatch(userFetchAction(callback));
    };
    dispatch(inativeRepresentativesActionLoading());
    dispatch(showAlert({}));
    try {
      const response = await api.post(`/admin/usuario/representante/desativar`, params);
      dispatch(inativeRepresentativesActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Representante inativado com sucesso!',
          icon: 'success',
          func: refresh,
        }),
      );
    } catch (error) {
      dispatch(inativeRepresentativesActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: refresh,
        }),
      );
    }
  };
};
