import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationLoan: { totalResult: 0 },
  loadingLoan: false,
  messageLoan: '',
  dataLoan: [],
  errorLoan: false,
};

export const loanActionLoading = createAction('LOAN_ACTION_LOADING');
export const loanActionSuccess = createAction('LOAN_ACTION_SUCCESS');
export const loanActionError = createAction('LOAN_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [loanActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingLoan: true,
      errorLoan: false,
    };
  },
  [loanActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLoan: false,
      errorLoan: false,
      dataLoan: payload.data,
      paginationLoan: payload.pagination,
    };
  },
  [loanActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingLoan: false,
      errorLoan: true,
      messageLoan: payload?.mensagem,
      paginationLoan: {},
      dataLoan: [],
    };
  },
});
