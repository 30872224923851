import api from '../../utils/services/api';

import {
  paymentDetailActionLoading,
  paymentDetailActionSuccess,
  paymentDetailActionError,
} from '../ducks/paymentDetail';

export const paymentDetailFetchAction = (id) => {
  return async (dispatch) => {
    dispatch(paymentDetailActionLoading());

    try {
      const response = await api.get(`transacao/listar/pagamento?id=${id}`);
      dispatch(paymentDetailActionSuccess(response.data));
    } catch (error) {
      dispatch(paymentDetailActionError(error?.response?.data));
    }
  };
};
