import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingTaxes: false,
  errorTaxes: false,
  taxesData: [],
};

export const taxesActionLoading = createAction('TAXES_ACTION_LOADING');
export const taxesActionSuccess = createAction('TAXES_ACTION_SUCCESS');
export const taxesActionError = createAction('TAXES_ACTION_ERROR');
export const editTaxesActionLoading = createAction('EDIT_TAXES_ACTION_LOADING');
export const editTaxesActionSuccess = createAction('EDIT_TAXES_ACTION_SUCCESS');
export const editTaxesActionError = createAction('EDIT_TAXES_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [taxesActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingTaxes: true,
      errorTaxes: false,
      taxesData: [],
    };
  },
  [taxesActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingTaxes: false,
      errorTaxes: false,
      taxesData: payload.TAXAS,
    };
  },
  [taxesActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingTaxes: false,
      errorTaxes: true,
      taxesData: [],
    };
  },
  [editTaxesActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingTaxes: true,
      errorTaxes: false,
      editTaxesData: [],
    };
  },
  [editTaxesActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingTaxes: false,
      errorTaxes: false,
    };
  },
  [editTaxesActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingTaxes: false,
      errorTaxes: true,
      editTaxesData: [],
    };
  },
});
