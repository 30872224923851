import api from '../../utils/services/api';

import { deniedUsersActionError, deniedUsersActionLoading, deniedUsersActionSuccess } from '../ducks/deniedUsers';

export const deniedUsersFetchAction = (filtro = { limit: 20, page: 1 }) => {
  return async (dispatch) => {
    dispatch(deniedUsersActionLoading());
    try {
      const response = await api.post(`usuario/listar/negado`, filtro);
      dispatch(deniedUsersActionSuccess(response.data));
    } catch (error) {
      dispatch(deniedUsersActionError(error?.response?.data));
    }
  };
};
