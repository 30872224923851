import api from '../../utils/services/api';
import dayjs from 'dayjs';

import {
  pixTransactionsActionLoading,
  pixTransactionsActionSuccess,
  pixTransactionsActionError,
  detailsPixTransactionActionLoading,
  detailsPixTransactionActionSuccess,
  detailsPixTransactionActionError,
} from '../ducks/pixTransactions';

export const pixTransactionsFetchAction = (filter = { LIMIT: 10, PAGE: 1 }) => {
  return async (dispatch) => {
    dispatch(pixTransactionsActionLoading());

    try {
      const response = await api.post(`pix/relatorio_admin`, filter);
      dispatch(pixTransactionsActionSuccess(response));
    } catch (error) {
      dispatch(pixTransactionsActionError(error?.response?.data));
    }
  };
};

export const pixTransactionDebitDetailsFetchAction = (id) => {
  return async (dispatch) => {
    dispatch(detailsPixTransactionActionLoading());

    try {
      const response = await api.post(`pix/detalhes_debito`, id);
      dispatch(detailsPixTransactionActionSuccess(response));
    } catch (error) {
      dispatch(detailsPixTransactionActionError(error?.response?.data));
    }
  };
};

export const pixTransactionCreditDetailsFetchAction = (id) => {
  return async (dispatch) => {
    dispatch(detailsPixTransactionActionLoading());

    try {
      const response = await api.post(`pix/detalhes_credito`, id);
      dispatch(detailsPixTransactionActionSuccess(response));
    } catch (error) {
      dispatch(detailsPixTransactionActionError(error?.response?.data));
    }
  };
};
