import { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import * as Styled from '../../PerfisUsuarios.styles';
import TextField from '../../../../components/TextField';
import validateSenha from '../../../../utils/functions/ValidatePassword';
import PasswordCheckList from '../../../../components/PasswordCheckList/PasswordCheckList';
import { editSystemPasswordUserFetchAction } from '../../../../store/fetchActions/systemUserActions';
import { showAlert } from '../../../../store/ducks/sweetAlert';
import { useForm } from 'react-hook-form';
import { IconButton, InputAdornment } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import formatCPF from '../../../../utils/functions/formatCPF';
import formatCNPJ from '../../../../utils/functions/formatCNPJ';

const ChangePasswordUser = ({ open, close, editUsuario }) => {
  const [passwordCheckList, setPasswordCheckList] = useState('');
  const [passwordCheckListValid, setPasswordCheckListValid] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrong, setPasswordStrong] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control, register } = useForm({});

  const callEditPasswordAction = (data) => {
    data.SENHA = passwordCheckList;
    delete data.create_user;
    passwordStrong && !validateSenha(passwordCheckList)
      ? dispatch(editSystemPasswordUserFetchAction(data.SENHA, editUsuario.CPFCNPJ, close))
      : dispatch(
          showAlert({
            title: 'Atenção',
            text: 'Sua senha precisa ser mais forte. Para garantir a segurança, não é permitido o uso de números  sequenciais. Ex: (123, 321)',
            icon: 'warning',
          }),
        );
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal open={open} close={close} title="Editar Senha do Usuario">
      <form onSubmit={handleSubmit(callEditPasswordAction)}>
        <Styled.SectionInputs>
          <TextField
            label="CPF"
            name={'CPFCNPJ_USUARIO'}
            width={'29.306vw'}
            control={control}
            value={
              editUsuario.CPFCNPJ?.length === 11 ? formatCPF(editUsuario.CPFCNPJ) : formatCNPJ(editUsuario.CPFCNPJ)
            }
            disabled={true}
          />
        </Styled.SectionInputs>
        <Styled.SectionInputs>
          <TextField
            inputProps={{
              maxLength: 8,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            control={control}
            label={'Senha'}
            disabled={false}
            width={'29.306vw'}
            name={'SENHA'}
            variant={'filled'}
            type={showPassword ? 'text' : 'password'}
            {...register('SENHA', {
              onChange: (e) => setPasswordCheckList(e.target.value),
            })}
          />
        </Styled.SectionInputs>
        <Styled.SectionInputs>
          <TextField
            inputProps={{
              maxLength: 8,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            control={control}
            label={'Confirmar Senha'}
            disabled={false}
            width={'29.306vw'}
            name={'CONFIRMARSENHA'}
            type={showPassword ? 'text' : 'password'}
            variant={'filled'}
            {...register('CONFIRMARSENHA', {
              onChange: (e) => setPasswordCheckListValid(e.target.value),
            })}
          />
        </Styled.SectionInputs>
        <PasswordCheckList
          name={'edit_user'}
          value={passwordCheckList}
          valueAgain={passwordCheckListValid}
          control={control}
          onChange={(e) => setPasswordStrong(e)}
        />
        <Button
          backgroundcolor={theme.colors.buttonConfirm}
          width={'29.306vw'}
          height={'3.125vw'}
          margin={'1vw 0'}
          hoverbgcolor={theme.colors.buttonConfirmHover}
          hovercolor={theme.colors.buttonConfirmHoverText}
          mycolor={theme.colors.buttonConfirmText}
          disabled={!passwordStrong}
          type="submit"
        >
          <span>SALVAR</span>
        </Button>
      </form>
    </Modal>
  );
};

export default ChangePasswordUser;
