import { useSelector } from 'react-redux';
import * as Styled from '../../styles/UsuariosAprovados.styles';
import ImageElement from '../../../../components/ImageElement/ImageElement';
import ProfileDefault from '../../../../assets/Images/Mon/ProfileDefault.png';

export default function InformationCollum() {
  const { usersData } = useSelector((state) => state.user);

  return (
    <Styled.ProfileContainer>
      {usersData?.IMAGEM_PERFIL ? <ImageElement image={usersData?.IMAGEM_PERFIL} /> : <img src={ProfileDefault} />}
    </Styled.ProfileContainer>
  );
}
