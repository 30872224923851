import { useSelector } from 'react-redux';
import formatMoney from '../../../../utils/functions/formatMoneyBRL';
import { ContainerLegalRepresentative } from '../representativesCard/RepresentativesCard.styles';
import verifyEmptyValues from '../../../../utils/verifyEmptyValues';

export default function TaxCollum() {
  const { TAXAS } = useSelector((state) => state.user.usersData);

  TAXAS?.forEach((taxa) => {
    switch (taxa.ID_TIPO_VALOR) {
      case '1':
        return {
          name: taxa.DESCRICAO,
          value: `R$ ${formatMoney(taxa.VALOR)}`,
        };
      case '2':
        return {
          name: taxa.DESCRICAO,
          value: `${formatMoney(taxa.VALOR)} %`,
        };
      default:
        return {
          name: taxa.DESCRICAO,
          value: `${parseInt(taxa.VALOR)}`,
        };
    }
  });

  return (
    <ContainerLegalRepresentative>
      {TAXAS?.length > 0 && <h2>Taxas Aplicadas</h2>}
      <div>
        <div className="card">
          <div className="box-legal-representative-data">
            <p className="box-legal-representative-title-data">BOLETO</p>
            <p>{`R$ ${verifyEmptyValues(formatMoney(TAXAS[0].VALOR))}`}</p>
          </div>
          <div className="box-legal-representative-data">
            <p className="box-legal-representative-title-data">TED</p>
            <p>{`R$ ${verifyEmptyValues(formatMoney(TAXAS[1].VALOR))}`}</p>
          </div>
        </div>
        <div className="card">
          <div className="box-legal-representative-data">
            <p className="box-legal-representative-title-data">PAGAMENTO</p>
            <p>{`R$ ${verifyEmptyValues(formatMoney(TAXAS[2].VALOR))}`}</p>
          </div>
          <div className="box-legal-representative-data">
            <p className="box-legal-representative-title-data">PIX</p>
            <p>{`R$ ${verifyEmptyValues(formatMoney(TAXAS[3].VALOR))}`}</p>
          </div>
        </div>
      </div>
    </ContainerLegalRepresentative>
  );
}
