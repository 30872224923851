import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  usersData: [],
  errorMessageUser: '',
  errorUser: false,
  loadingUser: true,

  errorUserBalance: false,
  errorMessageUserBalance: '',
  usersBalance: {
    HISTORICO: null,
  },
  loadingUserBalance: true,

  legalRepresentativesLoading: false,
  legalRepresentativesData: [],
};

export const userActionLoading = createAction('USER_ACTION_LOADING');
export const userActionSuccess = createAction('USER_ACTION_SUCCESS');
export const userActionError = createAction('USER_ACTION_ERROR');
export const userBalanceActionError = createAction('USER_BALANCE_ACTION_ERROR');
export const userBalanceActionLoading = createAction('USER_BALANCE_ACTION_LOADING');
export const userBalanceActionSuccess = createAction('USER_BALANCE_ACTION_SUCCESS');

export default createReducer(INITIAL_STATE, {
  [userActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingUser: true,
      errorUser: false,
      usersData: [],
      legalRepresentativesLoading: true,
      legalRepresentativesData: [],
    };
  },
  [userActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingUser: false,
      errorUser: false,
      usersData: payload,
      legalRepresentativesLoading: false,
      legalRepresentativesData: payload?.REPRESENTANTES,
    };
  },
  [userActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingUser: false,
      errorUser: true,
      errorMessageUser: payload?.mensagem,
      usersData: [],
      legalRepresentativesLoading: false,
      legalRepresentativesData: [],
    };
  },
  [userBalanceActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingUserBalance: true,
      errorUserBalance: false,
      usersBalance: {},
    };
  },
  [userBalanceActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingUserBalance: false,
      errorUserBalance: false,
      usersBalance: payload,
    };
  },

  [userBalanceActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingUserBalance: false,
      errorUserBalance: true,
      errorMessageUserBalance: payload?.mensagem,
      usersBalance: {},
    };
  },
});
