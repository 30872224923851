import styled from 'styled-components';

export const LoadingContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  color: ${(props) => props.theme.colors.loader};
`;

export const FirstFilterLine = styled.div`
  display: flex;
  border-radius: 5px;
  justify-content: flex-start;
  width: ${(props) => props.width || 'auto'};
  align-items: center;
  margin-bottom: 1vw;
  gap: 1vw;
`;
