import styled from 'styled-components';
import { Grid as GridMaterial } from '@material-ui/core';
export const Container = styled.div`
  /* margin-top: 100px;
  margin-left: 73px;
  width: 94%; */
`;

export const IconContainer = styled.div`
  border-radius: 50%;
  width: 2.5vw;
  height: 2.5vw;
  background-color: ${(props) => props.backgroundcolor || rbmWhite} !important;
  display: flex;
  align-items: center;
  color: ${(props) => props.iconColor || mainColor} !important;
  justify-content: center;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.389vw;
  background-color: #f8f8f8;
  h5,
  h6 {
    font-family: 'poppins';
  }

  h4 {
    font-weight: 600;
    font-size: 1.667vw;
  }
  h5 {
    font-size: 1.319vw;
    font-weight: 500;
  }

  h6 {
    font-size: 0.833vw;
    font-weight: 400;
    align-self: flex-end;
    letter-spacing: 1.5px;
  }
`;

export const TableHeader = styled(Header)`
  padding: 0 0 1.389vw 0;
`;

export const TextBold = styled.span`
  font-weight: 600;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2vw;
`;

export const CardElement = styled.div`
  width: 100%;
  box-shadow: 4px 9px 20px rgb(56 54 54 / 8%), 0px 1px 10px rgb(56 54 54 / 10%);
  border-radius: 10px;
`;
export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2vw 0;
`;

export const TableContainer = styled.div`
  padding: 1.528vw;
  background-color: #f8f8f8;
`;

export const ChartElement = styled.div`
  box-shadow: 4px 9px 20px 0px rgb(56 54 54 / 8%), 0px 1px 10px 0px rgb(56 54 54 / 10%);
`;
export const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 98%;
  grid-gap: 2vw;
`;

export const ChartTableGrid = styled(ChartGrid)`
  grid-template-columns: 50% 50%;
  margin-top: 2vw;
`;

export const LargeChart = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  box-shadow: 4px 9px 20px 0px rgb(56 54 54 / 8%), 0px 1px 10px 0px rgb(56 54 54 / 10%);
`;
