import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingSaldoGeral: false,
  messageSaldoGeral: '',
  dataSaldoGeral: [],
  errorSaldoGeral: false,
};

export const saldoGeralActionLoading = createAction('SALDO_GERAL_ACTION_LOADING');
export const saldoGeralActionSuccess = createAction('SALDO_GERAL_ACTION_SUCCESS');
export const saldoGeralActionError = createAction('SALDO_GERAL_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [saldoGeralActionLoading.type]: (state) => {
    return {
      ...state,
      loadingSaldoGeral: true,
      errorSaldoGeral: false,
      dataSaldoGeral: [],
    };
  },
  [saldoGeralActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSaldoGeral: false,
      errorSaldoGeral: false,
      dataSaldoGeral: payload?.total,
    };
  },
  [saldoGeralActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSaldoGeral: false,
      errorSaldoGeral: true,
      messageSaldoGeral: payload?.mensagem,
      dataSaldoGeral: [],
    };
  },
});
