import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { deletePerfisFetchAction } from '../../../../store/fetchActions/perfisActions';
import { ButtonContainer, SmallerModal } from '../../../GerenciarClientes/styles/GerenciarClientes.styles';
import { MdErrorOutline } from 'react-icons/md';

const DeletePerfil = ({ open, close, info }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Modal open={open} close={close}>
      <SmallerModal>
        <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
        <h3>Excluir Perfil</h3>
        <h5>Tem certeza que deseja excluir o perfil?</h5>
        <ButtonContainer>
          <Button
            backgroundcolor={theme.colors.buttonBack}
            width={'13.792vw'}
            height={'3.125vw'}
            hoverbgcolor={theme.colors.buttonBackHover}
            hovercolor={theme.colors.buttonBackHoverText}
            mycolor={theme.colors.buttonBackText}
            onClick={() => {
              close();
            }}
          >
            <span>Voltar</span>
          </Button>
          <Button
            backgroundcolor={theme.colors.buttonRemove}
            width={'13.792vw'}
            height={'3.125vw'}
            hoverbgcolor={theme.colors.buttonRemoveHover}
            hovercolor={theme.colors.buttonRemoveHoverText}
            mycolor={theme.colors.buttonRemoveText}
            onClick={() => dispatch(deletePerfisFetchAction(info.name, info.id, close))}
          >
            <span>Excluir</span>
          </Button>
        </ButtonContainer>
      </SmallerModal>
    </Modal>
  );
};

export default DeletePerfil;
