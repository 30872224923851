import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function useCallFilter(data, setFilter, route, value, index) {
  const dispatch = useDispatch();
  const { SEARCH, LIMIT, STATUS } = data;

  const removeSpecialChar = (search) => {
    if (typeof search === 'string') {
      return search.replaceAll('.', '').replaceAll('-', '');
    }
  };

  useEffect(() => {
    const filterData = {
      ...data,
      SEARCH: removeSpecialChar(SEARCH),
      LIMIT: LIMIT ? LIMIT : 10,
      PAGE: 1,
      STATUS: STATUS,
    };

    setFilter({
      ...data,
      SEARCH: removeSpecialChar(data.SEARCH) ? removeSpecialChar(data.SEARCH) : '',
      LIMIT: data.LIMIT ? data.LIMIT : 10,
      PAGE: 1,
      STATUS: data.STATUS ? data.STATUS : 0,
    });
    value === index && data && dispatch(route(filterData));
  }, [data, dispatch, index, LIMIT, route, SEARCH, setFilter, STATUS, value]);
}
