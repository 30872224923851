import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
export default function RequireAuth({ children }) {
  let location = useLocation();
  let jwt = localStorage.getItem('is_authenticate');

  if (!jwt) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
RequireAuth.defaultProps = {
  children: null,
};
RequireAuth.propTypes = {
  children: PropTypes.node,
};
