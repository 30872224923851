import React from 'react';
import PropTypes from 'prop-types';
import { PaginationComponent } from './Pagination.styles';

const Pagination = ({ paginationData, setFilter, filter }) => {
  const nextPage = (clickedPage) => {
    setFilter({ ...filter, PAGE: clickedPage });
  };

  return (
    <PaginationComponent
      count={paginationData?.totalPages ?? 1}
      defaultPage={paginationData?.page ?? 1}
      siblingCount={1}
      shape="rounded"
      onChange={(e, page) => nextPage(page)}
    />
  );
};

export default Pagination;

Pagination.propTypes = {
  setFilter: PropTypes.func,
  filter: PropTypes.object,
  paginationData: PropTypes.object,
};
