import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../components/Modal/Modal';
import { faceMatchOcrRelatorioDetalhes } from '../../../../store/fetchActions/faceMatchServicesActions';
import verifyArray from '../../../../utils/verifyArray';
import * as S from './DetalheOcrFaceMatch.styles';
import formatDateHora from '../../../../utils/functions/formatDateHora';
import { useTheme } from 'styled-components';
import Loading from '../../../../components/Loading';

const DetalhesOcrFaceMatch = ({ open, close, info }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(faceMatchOcrRelatorioDetalhes(info));
  }, []);

  const { dataFaceMatchOcrRelatorioDetalhe, loadingFaceMatchOcrRelatorioDetalhe } = useSelector(
    (selector) => selector.faceMatch,
  );

  const colorMatch = (val, name) => {
    switch (
      dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.validation_detections[0][val]
        ?.valid
    ) {
      case true:
        return <S.ColorOcrMatch color={theme.colors.darklimeGreen}>{name}</S.ColorOcrMatch>;

      case 'NOT_VALIDATED':
        return <S.ColorOcrMatch color={theme.colors.vividRed_2}>{name}</S.ColorOcrMatch>;
    }
  };

  const colorFaceMatch = (name) => {
    if (dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.probability < 50) {
      return <S.ColorOcrMatch color={theme.colors.vividRed_2}>{name}</S.ColorOcrMatch>;
    } else if (dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.probability < 80) {
      return <S.ColorOcrMatch color={theme.colors.vividOrange_2}>{name}</S.ColorOcrMatch>;
    } else {
      return <S.ColorOcrMatch color={theme.colors.darklimeGreen}>{name}</S.ColorOcrMatch>;
    }
  };

  return (
    <Modal open={open} close={close} title={dataFaceMatchOcrRelatorioDetalhe?.TIPO_SERVICO} width={'40vw'}>
      {loadingFaceMatchOcrRelatorioDetalhe ? (
        <Loading />
      ) : dataFaceMatchOcrRelatorioDetalhe?.TIPO_SERVICO == 'OCR' ? (
        <S.ContainerDetailOcrFaceMatch className="OCR">
          <div>
            <div className="card">
              <div className="box-legal-detail-data">
                <p className="box-legal-detail-title-data">Data/Hora</p>
                <p>{formatDateHora(dataFaceMatchOcrRelatorioDetalhe?.DATAHORA_CONSULTA)}</p>
              </div>
              <div className="box-legal-detail-data">
                <p className="box-legal-detail-title-data">ID</p>
                <p>{dataFaceMatchOcrRelatorioDetalhe?.ID}</p>
              </div>
              <div className="box-legal-detail-data">
                <p className="box-legal-detail-title-data">Documento</p>
                <p>
                  {verifyArray(
                    dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.detections,
                  )
                    ? dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.detections[0]
                        ?.label ?? '---'
                    : '---'}
                </p>
              </div>
              <S.Title>Cliente</S.Title>
              <div className="box-legal-detail-data">
                <p className="box-legal-detail-title-data">CPF</p>
                <p>{dataFaceMatchOcrRelatorioDetalhe?.CPFCNPJ_CONSULTA}</p>
              </div>
              <S.Title>Dados</S.Title>

              {dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.msg_errors[0]?.msg ? (
                <div>
                  <S.MsgErro>
                    {
                      dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.msg_errors[0]
                        ?.msg
                    }
                  </S.MsgErro>
                </div>
              ) : dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.msg_infos[0]?.msg ? (
                <div>
                  <S.MsgErro>
                    {dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.msg_infos[0]?.msg}
                  </S.MsgErro>
                </div>
              ) : (
                dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.detections[0]
                  ?.fields &&
                Object?.entries(
                  dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.data?.detections[0]
                    ?.fields,
                )?.map((val, indx) => (
                  <div className="box-legal-detail-data" key={'dados-' + indx}>
                    <p className="box-legal-detail-title-data">{val[0]}</p>
                    {colorMatch(val[0], val[1])}
                  </div>
                ))
              )}
            </div>
          </div>
        </S.ContainerDetailOcrFaceMatch>
      ) : (
        <S.ContainerDetailOcrFaceMatch className="FaceMatch">
          {dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse && (
            <div>
              <div className="card">
                <div className="box-legal-detail-data">
                  <p className="box-legal-detail-title-data">Data/Hora</p>
                  <p>{formatDateHora(dataFaceMatchOcrRelatorioDetalhe?.DATAHORA_CONSULTA)}</p>
                </div>
                <div className="box-legal-detail-data">
                  <p className="box-legal-detail-title-data">ID</p>
                  <p>{dataFaceMatchOcrRelatorioDetalhe?.ID}</p>
                </div>
                <div className="box-legal-detail-data">
                  <p className="box-legal-detail-title-data">Documento</p>
                  <p>Selfie x RG Frente</p>
                </div>
                <div className="box-legal-detail-data">
                  <p className="box-legal-detail-title-data">Match</p>
                  {dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.match == true ? (
                    <S.ColorOcrMatch color={theme.colors.darklimeGreen}>VERDADEIRO</S.ColorOcrMatch>
                  ) : (
                    <S.ColorOcrMatch color={theme.colors.vividRed_2}>FALSO</S.ColorOcrMatch>
                  )}
                </div>
                <div className="box-legal-detail-data">
                  <p className="box-legal-detail-title-data">Porcentagem</p>
                  {colorFaceMatch(
                    dataFaceMatchOcrRelatorioDetalhe?.RESPONSE?.response?.externalApiResponse?.probability + '%',
                  )}
                </div>
                <S.Title>Cliente</S.Title>
                <div className="box-legal-detail-data">
                  <p className="box-legal-detail-title-data">CPF</p>
                  <p>{dataFaceMatchOcrRelatorioDetalhe?.CPFCNPJ_CONSULTA}</p>
                </div>
              </div>
            </div>
          )}
        </S.ContainerDetailOcrFaceMatch>
      )}
    </Modal>
  );
};

export default DetalhesOcrFaceMatch;
