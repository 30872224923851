import Styled from 'styled-components';
import { FormControl, FormLabel, TextField } from '@mui/material';

export const InputContainer = Styled.div`
  width: 23.958vw;
  margin: 1.389vw 0 0 0;
`;

export const TitleContainer = Styled.h2`
  font-family: 'Poppins';
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.667vw;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.5rem;
`;

export const RadioContainer = Styled(FormControl)`
  margin-top: 2rem !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row !important;

  & .css-u4tvz2-MuiFormLabel-root.Mui-focused{
    color: ${(props) => props.theme.colors.nutBrown} !important;
  }
`;

export const RadioLabel = Styled(FormLabel)`
  font-weight: 800 !important;
  width: 20%;
`;

export const Row = Styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ContainerUploadFiles = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  outline: none;
  align-items: center;
  padding: 2rem 0;

  .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
    height: 3.2vw;
  }
`;

export const DocumentField = Styled(TextField)`
  width: 60%;
  height: 3.2vw !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 4px;
  background-color: rgba(51, 51, 51, 0.05);

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
    height: 3.2vw;
  }

  .css-1v4ccyo.Mui-disabled {
    height: 3.3vw;
  }
`;

export const Input = Styled('input')({
  display: 'none',
});

export const AnexoContainer = Styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

export const TitleAnexos = Styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #262424;
  padding-bottom: 0.8rem;
`;

export const SubTitleAnexos = Styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #909194;
`;

export const ContainerButtom = Styled.footer`
  padding: 2rem 0;
`;

export const InfoAnexo = Styled.div`
  display: flex; 
  width: 40%;

  & h2 {
    padding-left: 0.5rem;
  }
`;

export const ButtonAnexo = Styled.label`
  width: 20%;
`;

export const NameAnexoContainer = Styled.div`
  width: 40%;
`;

export const NameAnexo = Styled.div`
  display: flex;
`;
