import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Information.styles';
export default function Information({ title, data, color, bold }) {
  return (
    <Styled.ContainerStyled>
      <Styled.TitleStyled>{title}</Styled.TitleStyled>
      {typeof data === 'string' ? (
        <Styled.ParagraphName>{data}</Styled.ParagraphName>
      ) : (
        data?.map((data, index) => {
          return (
            <Styled.RowContainer key={index} role={'information-row'}>
              <Styled.ParagraphName>{data.name}</Styled.ParagraphName>
              <Styled.ParagraphValue color={color} bold={bold}>
                {data.value}
              </Styled.ParagraphValue>
            </Styled.RowContainer>
          );
        })
      )}
    </Styled.ContainerStyled>
  );
}

Information.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  bold: PropTypes.bool,
  children: PropTypes.node,
};
