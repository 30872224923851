import * as Styled from '../../styles/UsuariosAprovados.styles';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Modal from '../../../../components/Modal/Modal';
import { Skeleton } from '@mui/material';
import convertBase64 from '../../../../utils/functions/convertBase64';
import { imageAction } from '../../../../store/fetchActions/imageActions';
import { useMemo } from 'react';

export default function DocumentRepresentative({ document }) {
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState(0);
  const [imageURL, setImageURL] = useState('');
  const [imageName, setImageName] = useState('');
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);

  const changeImage = (newURL, name, index) => {
    setSelectedImage(index);
    setImageURL(newURL);
    setImageName(name);
  };

  useEffect(() => {
    document?.length > 0 && setImageURL(document[0]?.CAMINHO_ARQUIVO);
  }, []);

  useMemo(() => {
    imageURL && dispatch(imageAction(imageURL));
  }, [imageURL]);

  const { typeImage, imgData, loadingImage } = useSelector((selector) => selector.image);

  const urlImg = useMemo(() => convertBase64(imgData, typeImage), [imgData]);

  return (
    <Styled.ContainerDocRepresentative>
      <Styled.TitleStyled>Documentos</Styled.TitleStyled>
      <Styled.DocContaineRepresentative>
        {document?.map((documento, index) => {
          return (
            <Styled.Taxa key={index}>
              <Styled.ImageButton
                index={index}
                selectedImage={selectedImage}
                onClick={() => {
                  changeImage(documento.CAMINHO_ARQUIVO, documento.CATEGORIA, index), setIsModalImageOpen(true);
                }}
                type="button"
              >
                {documento.CATEGORIA}
              </Styled.ImageButton>
            </Styled.Taxa>
          );
        })}
      </Styled.DocContaineRepresentative>

      <Modal
        open={isModalImageOpen}
        close={() => setIsModalImageOpen(false)}
        title={imageName}
        width={typeImage === 'application/pdf' && !loadingImage ? '1000px' : 'auto'}
      >
        <>
          {loadingImage ? (
            <Skeleton variant="rounded" width={'550px'} height={350} />
          ) : typeImage === 'application/pdf' ? (
            <object data={urlImg} type="application/pdf" width="100%" height="800px"></object>
          ) : (
            <img src={`data:${typeImage};base64,${imgData}`} style={{ width: '100%' }} />
          )}
        </>
      </Modal>
    </Styled.ContainerDocRepresentative>
  );
}
