import { memo, useEffect, useState } from 'react';
import Filter from '../../components/Filter/Filter';
import TextField from '../../components/TextField';
import DateInput from '../../components/DateInput';
import { ImSearch } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { FirstFilterLine, SecondFilterLine } from '../../components/Filter/Filter.styles';
import Button from '../../components/Button/Button';
import SelectOptions from '../../components/SelectOptions';
import { useForm } from 'react-hook-form';
import { paymentsFetchAction } from '../../store/fetchActions/paymentsActions';
import { paymentDetailFetchAction } from '../../store/fetchActions/paymentDetailActions';
import Wrapper from '../../components/Wrapper/Wrapper';
import Table from '../../components/Table/Table';
import useCallFilter from '../../utils/hooks/useCallFilter';
import Modal from '../../components/Modal/Modal';
import { translateMessage } from '../../utils/functions/translateMessage';
import Information from '../../components/Information/Information';
import convertObjToArrayPayments from './ConvertObjToArrayPayments';
import formatCPF from '../../utils/functions/formatCPF';
import formatCNPJ from '../../utils/functions/formatCNPJ';
import formatMoney from '../../utils/functions/formatMoneyBRL';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { arrayStatus, pagesOptions } from '../../utils/constant';
import Loading from '../../components/Loading';

const Pagamentos = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    STATUS: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control } = useForm({});

  const { dataPayments, errorPayments, loadingPayments, paginationPayments, errorMessagePayments } = useSelector(
    (state) => state.payments,
  );
  const { paymentDetailData, errorPaymentDetail, messageError, loadingPaymentDetail } = useSelector(
    (state) => state.paymentDetail,
  );

  const paymentDetailArray = convertObjToArrayPayments(paymentDetailData);

  useCallFilter(filterData, setFilter, paymentsFetchAction, 0, 0);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  const closeModalInfo = () => {
    setIsModalInfoOpen(false);
  };

  const body = dataPayments?.map((payment) => {
    return {
      data: [
        { info: payment.DATA, align: 'center' },
        {
          info: payment.CPFCNPJ?.length === 11 ? formatCPF(payment.CPFCNPJ) : formatCNPJ(payment.CPFCNPJ),
          align: 'left',
        },
        { info: payment.NOME, align: 'left' },
        { info: payment.DESCRICAO, align: 'left' },
        { info: 'R$ ' + formatMoney(payment.VALOR), align: 'right' },
        {
          info: payment.STATUSTRANSACAO ? (
            <StatusContainer code={payment.STATUSTRANSACAO} description={payment.DESCRICAO_STATUS}></StatusContainer>
          ) : (
            '-----'
          ),
          align: 'center',
        },
      ],
      onClick: () => inspectUser(payment.ID),
    };
  });

  function inspectUser(id) {
    dispatch(paymentDetailFetchAction(id));
    setIsModalInfoOpen(true);
  }

  useEffect(() => {
    dispatch(paymentsFetchAction(filter));
  }, [filter.PAGE]);

  const header = [
    {
      id: 'data de registro',
      name: 'DATA DE PAGAMENTO',
      align: 'center',
    },
    { id: 'CPFCNPJ', name: 'CPF/CNPJ', align: 'left' },
    { id: 'nome', name: 'NOME', align: 'left' },
    { id: 'descricao', name: 'DESCRIÇÃO', align: 'left' },
    { id: 'valor pago', name: 'VALOR PAGO', align: 'right' },
    { id: 'status', name: 'STATUS', align: 'center' },
  ];

  return (
    <Wrapper title="Pagamentos">
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'22.042vw'} control={control} />
              <SelectOptions
                control={control}
                options={arrayStatus}
                name={'STATUS'}
                width={'13.528vw'}
                label={'Status'}
                initialValue={0}
              />
              <DateInput
                label="Data Inicial"
                name={'DATA_INICIAL'}
                control={control}
                value={dateInicial}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATA_FINAL'}
                control={control}
                value={dateFinal}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />
              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width="12.153vw"
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
                margin={'0 1.389vw 0 0'}
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por página</h5>
              <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
              <h5>Total de resultados: {paginationPayments?.totalResult ?? 0}</h5>
            </SecondFilterLine>
          </form>
        </Filter>
        {loadingPayments ? (
          <Loading />
        ) : (
          <Table
            loading={loadingPayments}
            error={errorPayments}
            hasPagination={true}
            filter={filter}
            pagination={paginationPayments}
            setFilter={setFilter}
            errorMessage={errorMessagePayments}
            filterTable={filterTable}
            header={header}
            body={body}
            limit={filter.LIMIT}
          />
        )}

        {isModalInfoOpen && (
          <Modal open={isModalInfoOpen} close={closeModalInfo} title="Dados do Pagamento" width={'47.889vw'}>
            {loadingPaymentDetail ? (
              <Loading />
            ) : errorPaymentDetail ? (
              <h1>{translateMessage(messageError)}</h1>
            ) : (
              <Information data={paymentDetailArray} />
            )}
          </Modal>
        )}
      </div>
    </Wrapper>
  );
};

export default memo(Pagamentos);
