import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingDailyMovements: true,
  errorDailyMovements: false,
  infos: {
    PAGINATION: {},
    TOTAL_CARTAO_DEBITO: 0,
    TOTAL_CREDITO: 0,
    TOTAL_DEBITO: 0,
    TOTAL_DEPOSITO: 0,
    TOTAL_EMPRESTIMO: 0,
    TOTAL_PAGAMENTO: 0,
    TOTAL_PIX: 0,
    TOTAL_TARIFAS: 0,
    TOTAL_TRANSACAO_INTERNA_CREDITO: 0,
    TOTAL_TRANSACAO_INTERNA_DEBITO: 0,
    TRANSACOES: [],
    mensagem: '',
  },
};

export const dailyMovementsActionLoading = createAction('DAILYMOVEMENTS_ACTION_LOADING');
export const dailyMovementsActionSuccess = createAction('DAILYMOVEMENTS_ACTION_SUCCESS');
export const dailyMovementsActionError = createAction('DAILYMOVEMENTS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [dailyMovementsActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingDailyMovements: true,
      errorDailyMovements: false,
    };
  },
  [dailyMovementsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDailyMovements: false,
      paginationMovements: payload?.pagination,
      movimentacaoData: payload?.transacoes,
      dailyMovements: {
        totalAvulsoCredito: payload?.totalAvulsoCredito,
        totalAvulsoDebito: payload?.totalAvulsoDebito,
        totalCartaoDebito: payload?.totalCartaoDebito,
        totalCredito: payload?.totalCredito,
        totalDebito: payload?.totalDebito,
        totalDeposito: payload?.totalDeposito,
        totalEmprestimo: payload?.totalEmprestimo,
        totalPagamento: payload?.totalPagamento,
        totalPix: payload?.totalPix,
        totalQrcodeCredito: payload?.totalQrcodeCredito,
        totalQrcodeDebito: payload?.totalQrcodeDebito,
        totalRecargas: payload?.totalRecargas,
        totalSaquePdvCredito: payload?.totalSaquePdvCredito,
        totalSaquePdvDebito: payload?.totalSaquePdvDebito,
        totalTarifas: payload?.totalTarifas,
        totalTransacaoInternaCredito: payload?.totalTransacaoInternaCredito,
        totalTransacaoInternaDebito: payload?.totalTransacaoInternaDebito,
        totalDebitoTed: payload?.totalDebitoTed,
        totalCreditoTed: payload?.totalCreditoTed,
      },
      errorDailyMovements: false,
    };
  },
  [dailyMovementsActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDailyMovements: false,
      errorDailyMovements: true,
      errorMessage: payload.mensagem,
    };
  },
});
