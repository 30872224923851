import api from '../../utils/services/api';

import { pendingUsersActionError, pendingUsersActionLoading, pendingUsersActionSuccess } from '../ducks/pendingUsers';

export const pendingUsersFetchAction = (filtro = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(pendingUsersActionLoading());
    try {
      const response = await api.post(`/usuario/listar/espera`, filtro);
      dispatch(pendingUsersActionSuccess(response.data));
    } catch (error) {
      dispatch(pendingUsersActionError(error?.response?.data));
    }
  };
};
