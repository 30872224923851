import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  pendingUsersData: [],
  pagination: { totalResult: 0 },
  errorMessagePendingUsers: '',
};

export const pendingUsersActionLoading = createAction('PENDING_USERS_ACTION_LOADING');
export const pendingUsersActionSuccess = createAction('PENDING_USERS_ACTION_SUCCESS');
export const pendingUsersActionError = createAction('PENDING_USERS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pendingUsersActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPendingUsers: true,
      errorPendingUsers: false,
      pendingUsersData: [],
      pagination: {},
    };
  },
  [pendingUsersActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPendingUsers: false,
      errorPendingUsers: false,
      pendingUsersData: payload.data,
      pagination: payload.pagination,
    };
  },
  [pendingUsersActionError.type]: (state) => {
    return {
      ...state,
      loadingPendingUsers: false,
      errorPendingUsers: true,
      // errorMessagePendingUsers: payload.mensagem,
      pendingUsersData: [],
      pagination: {},
    };
  },
});
