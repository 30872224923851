import api from '../../utils/services/api';
import {
  limitPaymentActionError,
  limitPaymentActionLoading,
  limitPaymentActionSuccess,
  editLimitPaymentLoading,
  editLimitPaymentSuccess,
  editLimitPaymentError,
} from '../ducks/limitPayments';
import { showAlert } from '../ducks/sweetAlert';

export const limitPaymentAction = () => {
  return async (dispatch) => {
    dispatch(limitPaymentActionLoading());
    try {
      const response = await api.get(`/configuracoes/limitePagamento`);
      dispatch(limitPaymentActionSuccess(response.data));
    } catch (error) {
      dispatch(limitPaymentActionError(error?.response?.data?.mensagem));
    }
  };
};

export const editLimitPaymentAction = (params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(limitPaymentAction());
    };
    dispatch(editLimitPaymentLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.put(`/configuracoes/atualizarLimitePagamento`, params);
      dispatch(editLimitPaymentSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Limite atualizado',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editLimitPaymentError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Limite não atualizado',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
