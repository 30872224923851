import { memo, useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import DateInput from '../../components/DateInput';
import Filter from '../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../components/Filter/Filter.styles';
import TextField from '../../components/TextField';
import Wrapper from '../../components/Wrapper/Wrapper';
import Button from '../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { ImSearch } from 'react-icons/im';
import SelectOptions from '../../components/SelectOptions';
import { useDispatch, useSelector } from 'react-redux';
import { transfersFetchAction, transfersIDFetchAction } from '../../store/fetchActions/transfersActions';
import formatCPF from '../../utils/functions/formatCPF';
import StatusContainerTed from '../../components/StatusContainer/StatusContainerTed';
import * as dayjs from 'dayjs';
import formatMoney from '../../utils/functions/formatMoneyBRL';
import InformationTransfer from './components/InformationTed';
import Modal from '../../components/Modal/Modal';
import transformObjectUppercase from '../../utils/functions/transformObjectUppercase';
import useCallFilter from '../../utils/hooks/useCallFilter';
import { getPaymentWayAndTransactionTypes } from '../../store/fetchActions/reportsActions';
import * as S from './Transferencias.styles';
import verifyEmptyValues from '../../utils/verifyEmptyValues';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { arrayTipoTransacao, pagesOptions } from '../../utils/constant';
import Loading from '../../components/Loading';

function TransferenciaTed() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [ModalInfoData, setModalInfoData] = useState({});
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    AGENCIA: '',
    CONTA: '',
    TIPO: 'Ted',
    TIPO_TRANSACAO: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    dispatch(getPaymentWayAndTransactionTypes());
  }, []);

  const { transfersData, paginationTransfers, loadingTransfers, errorTransfers, messageTransfers } = useSelector(
    (state) => state.transfers,
  );

  const { status_transacao_ted } = useSelector((state) => state?.reports);

  useCallFilter(filterData, setFilter, transfersFetchAction, 0, 0);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    data.TIPO = 'Ted';
    setFilterData(data);
  };

  const { handleSubmit, control } = useForm({});

  useEffect(() => {
    dispatch(transfersFetchAction(transformObjectUppercase({ ...filter, TIPO: 'Ted' })));
  }, [filter.PAGE]);

  const arrayStatus = status_transacao_ted?.map((status) => {
    return { ID: status.ID, NOME: status.DESCRICAO };
  });

  arrayStatus.unshift({ ID: 0, NOME: 'TODOS' });

  const header = [
    {
      id: 'data e hora',
      name: 'DATA/HORA',
      align: 'center',
    },
    { id: 'cpf/cnpj', name: 'CPF/CNPJ', align: 'left' },
    { id: 'nome', name: 'NOME', align: 'left' },
    { id: 'banco', name: 'BANCO', align: 'left' },
    { id: 'agencia', name: 'AGENCIA', align: 'left' },
    { id: 'conta', name: 'CONTA', align: 'left' },
    { id: 'tipo', name: 'TIPO', align: 'left' },
    { id: 'valor', name: 'VALOR', align: 'right' },
    { id: 'status', name: 'STATUS', align: 'center' },
  ];

  const body = transfersData?.map((transfer) => {
    return {
      data: [
        {
          info: dayjs(transfer.DATA_INSERT).format('DD/MM/YYYY HH:mm:ss') || '-----',
          align: 'center',
        },
        {
          info: formatCPF(transfer.CPFCNPJ),
          align: 'left',
        },
        {
          info: transfer.NOME,
          align: 'left',
          width: '25rem',
        },
        {
          info: verifyEmptyValues(transfer.TEDINTERNA === 'S' ? transfer.BANCO_DESTINO : transfer.BANCO),
          align: 'left',
          width: '15rem',
        },
        {
          info: verifyEmptyValues(transfer.TEDINTERNA === 'S' ? transfer.AGENCIA_DESTINO : transfer.AGENCIA),
          align: 'left',
          width: '15rem',
        },
        {
          info: verifyEmptyValues(transfer.TEDINTERNA === 'S' ? transfer.CONTA_DESTINO : transfer.CONTA),
          align: 'left',
          width: '15rem',
        },
        {
          info: transfer.TIPOTRANSFERENCIA,
          align: 'left',
          width: '15rem',
        },
        {
          info:
            transfer.TIPOTRANSFERENCIA === 'CRÉDITO' ? (
              <S.ColorValue mycolor={theme.colors.darklimeGreen} myfont={'500'}>
                {'R$ + ' + formatMoney(transfer.VALOR)}
              </S.ColorValue>
            ) : (
              <S.ColorValue mycolor={theme.colors.vividRed_2} myfont={'500'}>
                {'R$ - ' + formatMoney(transfer.VALOR)}
              </S.ColorValue>
            ),
          align: 'right',
        },
        {
          info: transfer.STATUSTRANSACAO ? (
            <StatusContainerTed
              code={transfer.STATUSTRANSACAO}
              description={transfer.STATUS_DESCRICAO}
            ></StatusContainerTed>
          ) : (
            '-----'
          ),
          align: 'center',
        },
      ],
      onClick: () => inspectUser(transfer.ID),
    };
  });

  const inspectUser = (ID) => {
    dispatch(
      transfersIDFetchAction(ID, (res) => {
        setModalInfoData({ ...res, ID });
        setIsModalInfoOpen(true);
      }),
    );
  };

  return (
    <Wrapper title="Transferências TED">
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'24.042vw'} control={control} />
              <SelectOptions
                label={'Status'}
                width={'9.528vw'}
                name={'STATUS'}
                control={control}
                options={arrayStatus}
                initialValue={'0'}
              />
              <SelectOptions
                label={'Tipo'}
                width={'9.528vw'}
                name={'TIPO_TRANSACAO'}
                control={control}
                options={arrayTipoTransacao}
                initialValue={'0'}
              />
              <DateInput
                label="Data Inicial"
                name={'DATA_INICIAL'}
                width={'9.528vw'}
                control={control}
                value={dateInicial}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATA_FINAL'}
                width={'9.528vw'}
                control={control}
                value={dateFinal}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />

              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width="17.153vw"
                margin={'0 1.389vw 0 0'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por página</h5>
              <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
              <h5>Total de resultados: {paginationTransfers?.totalResult}</h5>
            </SecondFilterLine>
          </form>
        </Filter>

        {loadingTransfers ? (
          <Loading />
        ) : (
          <Table
            loading={loadingTransfers}
            error={errorTransfers}
            hasPagination={true}
            filter={filter}
            pagination={paginationTransfers}
            setFilter={setFilter}
            errorMessage={messageTransfers}
            filterTable={filterTable}
            header={header}
            body={body}
            limit={filter.LIMIT}
          />
        )}

        {isModalInfoOpen && (
          <Modal
            open={isModalInfoOpen}
            close={() => setIsModalInfoOpen(false)}
            title="Dados da Transferência"
            width={'45.889vw'}
          >
            <InformationTransfer data={ModalInfoData} close={() => setIsModalInfoOpen(false)} />
          </Modal>
        )}
      </div>
    </Wrapper>
  );
}

export default memo(TransferenciaTed);
