import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataReports: [],
  errorReports: false,
  errorMessageReports: '',
  loadingReports: true,
  paymentWays: [],
  transactionTypes: [],
  paginationReports: {},
  status_recarga: [],
  status_transacao: [],
  status_transacao_ted: [],
};

export const reportsActionLoading = createAction('REPORTS_ACTION_LOADING');
export const reportsActionSuccess = createAction('REPORTS_ACTION_SUCCESS');
export const reportsActionError = createAction('REPORTS_ACTION_ERROR');
export const paymentWaysAndTransactionTypesActionSuccess = createAction(
  'PAYMENT_WAYS_TRANSACTIONS_TYPE_ACTION_SUCCESS',
);
export const paymentWaysAndTransactionTypesActionError = createAction('PAYMENT_WAYS_TRANSACTIONS_TYPE_ACTION_ERROR');
export const reportsExcelActionLoading = createAction('REPORTS_EXCEL_ACTION_LOADING');
export const reportsExcelActionStopLoading = createAction('REPORTS_EXCEL_ACTION_STOP_LOADING');

export default createReducer(INITIAL_STATE, {
  [reportsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingReports: true,
      errorReports: false,
    };
  },
  [reportsActionSuccess.type]: (state, payload) => {
    const { data, pagination } = payload?.payload?.data?.data;

    return {
      ...state,
      loadingReports: false,
      errorReports: false,
      dataReports: data,
      paginationReports: pagination,
    };
  },
  [reportsActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingReports: false,
      errorReports: true,
      errorMessageReports: payload?.mensagem,
      dataReports: [],
      paginationReports: {},
    };
  },
  [paymentWaysAndTransactionTypesActionSuccess.type]: (state, payload) => {
    const { modo_pagamento, tipo_transacao, status_recarga, status_transacao, status_transacao_ted } =
      payload?.payload?.data;

    return {
      ...state,
      paymentWays: modo_pagamento,
      transactionTypes: tipo_transacao,
      status_recarga,
      status_transacao,
      status_transacao_ted,
    };
  },
  [paymentWaysAndTransactionTypesActionError.type]: (state) => {
    return {
      ...state,
      paymentWays: [],
      transactionTypes: [],
      status_recarga: [],
      status_transacao_ted: [],
    };
  },
  [reportsExcelActionLoading.type]: (state) => {
    return {
      ...state,
      loadingReports: true,
    };
  },
  [reportsExcelActionStopLoading.type]: (state) => {
    return {
      ...state,
      loadingReports: false,
    };
  },
});
