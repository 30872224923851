import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../../Information/Information.styles';
import StatusContainer from '../../StatusContainer/StatusContainer';
import formatDateHora from '../../../utils/functions/formatDateHora';
import { useTheme } from 'styled-components';
export default function Information({ title, data, color, bold }) {
  const theme = useTheme();

  return (
    <Styled.ContainerStyled>
      <Styled.TitleStyled>{title}</Styled.TitleStyled>
      {typeof data === 'string' ? (
        <Styled.ParagraphName>{data}</Styled.ParagraphName>
      ) : (
        <>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Data/Hora'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {formatDateHora(data?.DATA_INSERT ?? '---')}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Tipo de Transferência'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.TIPO ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Valor'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.VALOR ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Taxa'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.TAXA ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Valor Total'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.VALOR_TOTAL ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Status'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              <StatusContainer code={data?.STATUSTRANSACAO} description={data?.STATUS_DESCRICAO}></StatusContainer>
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Transação'}</Styled.ParagraphName>
            <Styled.ParagraphValue
              color={data?.TIPOTRANSFERENCIA != 'CRÉDITO' ? theme.colors.vividRed_2 : theme.colors.darklimeGreen}
              bold={bold}
            >
              {data?.TIPOTRANSFERENCIA ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>

          <Styled.TitleContainer role={'information-row'}>
            <Styled.TitleStyled>{'ORIGEM'}</Styled.TitleStyled>
          </Styled.TitleContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Nome'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.NOME ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'CPF/CNPJ'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.CPFCNPJ ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Agência/Conta'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {(data?.AGENCIA ?? '--') + ' - ' + (data?.CONTA ?? '----')}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Instituição'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.BANCO ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>

          <Styled.TitleContainer role={'information-row'}>
            <Styled.TitleStyled>{'DESTINO'}</Styled.TitleStyled>
          </Styled.TitleContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Nome'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.NOME_DESTINO ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'CPF/CNPJ'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.CPFCNPJ_DESTINO ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Instituição'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {data?.BANCO_DESTINO ?? '---'}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
          <Styled.RowContainer role={'information-row'}>
            <Styled.ParagraphName>{'Agência/Conta'}</Styled.ParagraphName>
            <Styled.ParagraphValue color={color} bold={bold}>
              {(data?.AGENCIA_DESTINO ?? '--') + ' - ' + (data?.CONTA_DESTINO ?? '----')}
            </Styled.ParagraphValue>
          </Styled.RowContainer>
        </>
      )}
    </Styled.ContainerStyled>
  );
}

Information.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  bold: PropTypes.bool,
  children: PropTypes.node,
};
