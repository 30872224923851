import api from '../../utils/services/api';

import {
  loginActionError,
  loginActionLoading,
  loginActionSuccess,
  authenticateActionError,
  authenticateActionLoading,
  authenticateActionSuccess,
} from '../ducks/login';
import { showAlert } from '../ducks/sweetAlert';
import removeSpecialChars from '../../utils/functions/removeSpecialChars';

export const loginFetchAction = (params, navigate) => {
  const CPFCNPJ = removeSpecialChars(['.', '-', '/'], params.CPFCNPJ);
  const FONECEL = removeSpecialChars(['(', ')', ' ', '-'], params.FONECEL);

  let dataObject = {
    CPFCNPJ,
    FONECEL,
    SENHA: params.SENHA,
    TOKEN: params.TOKEN,
  };

  return async (dispatch) => {
    dispatch(loginActionLoading());
    try {
      const response = await api.post(`coban/novaAutenticacao/validar`, dataObject);
      dispatch(loginActionSuccess(response.data));
      localStorage.setItem('is_authenticate', response?.data?.jwt);
      api.defaults.headers = {
        jwt: `${response?.data?.jwt}`,
      };
      localStorage.setItem('cpfcnpj', params.CPFCNPJ);
      localStorage.setItem('nivelAcesso', response?.data?.nivelAcesso);
      navigate('/dashboard');
    } catch (error) {
      const errorMessage = error?.response?.data?.mensagem || 'Ocorreu algo inesperado';
      dispatch(loginActionError(errorMessage));
      dispatch(
        showAlert({
          title: errorMessage,
          text: 'Verifique o código recebido.',
          icon: 'error',
        }),
      );
    }
  };
};

export const authenticateFetchAction = (params, callback) => {
  const CPFCNPJ = removeSpecialChars(['.', '-', '/'], params.CPFCNPJ);
  const FONECEL = removeSpecialChars(['(', ')', ' ', '-'], params.FONECEL);

  let dataObject = { CPFCNPJ, FONECEL, SENHA: params.SENHA, RESPONSE: params.RESPONSE };

  return async (dispatch) => {
    dispatch(authenticateActionLoading());
    try {
      const response = await api.post(`coban/autenticacao/enviar`, dataObject);
      dispatch(authenticateActionSuccess(response.data));
      callback();
    } catch (error) {
      const errorMessage = error?.response?.data?.mensagem;
      dispatch(authenticateActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: errorMessage ?? 'Fora do ar',
          icon: 'error',
        }),
      );
    }
  };
};

export const validateJwt = (callback = () => null) => {
  return async (dispatch) => {
    const momentDateTime = new Date();
    try {
      const validateJwt = JSON.parse(window.atob(localStorage.getItem('is_authenticate')?.split('.')[1])).time;
      const jwtDateTime = new Date(validateJwt);

      const logOutJwt = momentDateTime >= jwtDateTime ? true : false;
      callback(logOutJwt);
    } catch (error) {
      callback(false);
    }
  };
};

export const renovationJwtAction = (params, callback = () => null) => {
  const CPFCNPJ = removeSpecialChars(['.', '-', '/'], params.CPFCNPJ);

  let dataObject = {
    CPFCNPJ,
    SENHA: params.SENHA,
  };

  return async (dispatch) => {
    dispatch(loginActionLoading());
    try {
      const response = await api.put(`/admin/refresh/session`, dataObject);
      dispatch(loginActionSuccess(response.data));
      localStorage.setItem('is_authenticate', response?.data?.jwt);
      api.defaults.headers = {
        jwt: `${response?.data?.jwt}`,
      };
      document.location.reload();
      callback(true);
    } catch (error) {
      dispatch(loginActionError(error?.response?.data?.mensagem));
      dispatch(
        showAlert({
          title: error?.response?.data?.mensagem,
          text: 'Digite novamente sua senha.',
          icon: 'error',
        }),
      );
    }
  };
};
