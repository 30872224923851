import styled, { css } from 'styled-components';
import Divider from '@material-ui/core/Divider';

export const ContainerStyled = styled.div`
  width: 100%;
  padding: 2rem 0 4rem 0;
`;

export const TitleStyled = styled.span`
  margin-top: 0.764vw;
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  font-size: 1.389vw;
  line-height: 2.083vw;
`;

export const Bar = styled(Divider)`
  width: 100%;
  color: #909194;
`;

export const ParagraphName = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  font-size: 1.111vw;
  line-height: 1.667vw;
  color: ${(props) => (props.color ? props.color : ' #262424')};
  margin-top: 0.764vw;
  height: 1.667vw;
`;

export const ParagraphValue = styled(ParagraphName)`
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : ' #909194')};

  ${(props) =>
    props.bold &&
    css`
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: right;
    `}
`;

export const RowContainer = styled.div`
  border-bottom: 1.5px solid #9091941a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.694vw;

  width: 100%;
  color: #909194;
`;

export const Account = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #9091941a;
  &:last-child {
    border: none;
  }
`;
