import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import ModalPassword from '../../../components/ModalPassword/ModalPassword';
import Table from '../../../components/Table/Table';
import { limitPaymentAction } from '../../../store/fetchActions/limitPayments';
import formatMoney from '../../../utils/functions/formatMoneyBRL';
import formatDateHora from '../../../utils/functions/formatDateHora';
import ModalLimit from '../Modals/Limites/EditLimit';
import * as Styled from '../PerfisUsuarios.styles';
import { useTheme } from 'styled-components';

const Limite = ({ value, index }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [limitEdit, setLimitEdit] = useState(false);
  const [limitEditData, setLimitEditData] = useState();
  const [passwordOpen, setPasswordOpen] = useState(false);

  useEffect(() => {
    dispatch(limitPaymentAction());
  }, []);

  const { limitPaymentData, loadingLimitPayment, errorLimitPayment, errorMessageLimitPayment } = useSelector(
    (state) => state.limitPayment,
  );
  const { cobansData } = useSelector((state) => state.cobans);

  const filterUser = (data) => {
    const result = cobansData?.filter((el) => el.CPFCNPJ === data);
    return result[0]?.NOME;
  };

  const header = [
    {
      id: 'descricao',
      name: 'DESCRIÇÃO',
      align: 'left',
    },
    {
      id: 'valor',
      name: 'VALOR',
      align: 'left',
    },
    {
      id: 'data',
      name: 'DATA DE MODIFICAÇÃO',
      align: 'left',
    },
    {
      id: 'usuario',
      name: 'USUÁRIO',
      align: 'left',
    },
    {
      id: 'acoes',
      name: 'AÇÕES',
      align: 'center',
    },
  ];

  const body = [
    {
      data: [
        { info: limitPaymentData?.DESCRICAO, align: 'left' },
        { info: 'R$ ' + formatMoney(limitPaymentData?.VALOR), align: 'left' },
        { info: formatDateHora(limitPaymentData?.DATAHORA || '---'), align: 'left' },
        { info: filterUser(limitPaymentData?.ADMIN) || '-----', align: 'left' },
        {
          info: (
            <Styled.Row>
              <Tooltip title="Editar">
                <Styled.IconContainer
                  onClick={() => {
                    setLimitEditData({
                      VALOR: formatMoney(limitPaymentData?.VALOR),
                      ITEM: limitPaymentData?.DESCRICAO,
                    });
                    setPasswordOpen(true);
                  }}
                  backgroundcolor={theme.colors.grayTransparent}
                >
                  <MdModeEdit size={25} />
                </Styled.IconContainer>
              </Tooltip>
            </Styled.Row>
          ),
          align: 'center',
        },
      ],
    },
  ];

  return (
    <div hidden={value !== index}>
      <Table
        loading={loadingLimitPayment}
        error={errorLimitPayment}
        errorMessage={errorMessageLimitPayment}
        header={header}
        body={body}
      />

      <ModalPassword
        openModal={passwordOpen}
        closeModal={() => setPasswordOpen(false)}
        nextFunction={() => setLimitEdit(true)}
      />
      {limitEdit && (
        <ModalLimit open={limitEdit} close={() => setLimitEdit(false)} title="Editar" info={limitEditData} />
      )}
    </div>
  );
};

export default Limite;
