import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none" viewBox="0 0 19 20">
      <path
        fill="#fff"
        d="M10.44 5.25c0 .265-.11.52-.303.707a1.052 1.052 0 01-.731.293c-.274 0-.537-.105-.731-.293a.984.984 0 01-.303-.707c0-.265.109-.52.303-.707.194-.188.457-.293.73-.293.275 0 .538.105.732.293a.984.984 0 01.303.707zm.033-3.925A1.85 1.85 0 009.406.988a1.85 1.85 0 00-1.067.337L.667 6.74C-.344 7.453.178 9 1.428 9h.224v5.8c-.467.23-.858.581-1.132 1.013-.274.433-.419.93-.418 1.437v1.5c0 .413.347.75.775.75h17.058a.79.79 0 00.548-.22.738.738 0 00.227-.53v-1.5c0-.507-.144-1.004-.418-1.437a2.808 2.808 0 00-1.133-1.013V9h.225c1.25 0 1.77-1.547.76-2.26l-7.671-5.416v.001zm-1.22 1.211a.264.264 0 01.305 0L16.59 7.5H2.22l7.033-4.964h-.001zM15.609 9v5.5H13.54V9h2.068zM11.99 9v5.5h-1.81V9h1.81zM8.63 9v5.5H6.82V9h1.81zm-5.686 7h12.922c.714 0 1.293.56 1.293 1.25V18H1.652v-.75c0-.69.58-1.25 1.293-1.25zm.258-1.5V9h2.068v5.5H3.203z"
      ></path>
    </svg>
  );
}

export default Icon;
