import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none" viewBox="0 0 19 16">
      <path
        fill="#fff"
        d="M5 1h14v2H5V1zm0 8V7h14v2H5zM2 .5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0 6a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM5 15v-2h14v2H5zm-3-2.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
      ></path>
    </svg>
  );
}

export default Icon;
