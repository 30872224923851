import Chart from '../../../components/Chart/index';
import * as Styled from '../Dashboard.styles';
import * as dayjs from 'dayjs';
import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transactionsFetchAction } from '../../../store/fetchActions/transactionsActions';
import { listNewUsersFetchAction, listNewUsersTableFetchAction } from '../../../store/fetchActions/listUsersActions';
import Table from '../../../components/Table/Table';
import StatusContainer from '../../../components/StatusContainer/StatusContainer';
import formatMoney from '../../../utils/functions/formatMoneyBRL';
import formatDateHora from '../../../utils/functions/formatDateHora';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { dailyMovementsFetchAction } from '../../../store/fetchActions/dailyMovementsActions';
import moment from 'moment';
import { useTheme } from 'styled-components';

export default function ChartContainer() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    LIMIT: 10,
    PAGE: 1,
  });
  const [filterClients, setFilterClients] = useState({
    LIMIT: 10,
    PAGE: 1,
  });

  useEffect(() => {
    dispatch(dailyMovementsFetchAction(filter));
  }, [filter.PAGE]);

  useEffect(() => {
    dispatch(
      listNewUsersTableFetchAction({
        page: filterClients.PAGE,
        limit: filterClients.LIMIT,
        dataInicial: menosDate,
        dataFinal: initialDate,
      }),
    );
  }, [filterClients.PAGE]);

  const { movimentacaoData, paginationMovements, errorMessage, errorDailyMovements, loadingDailyMovements } =
    useSelector((state) => state.dailyMovements);

  const {
    errorListUsers,
    messageListUsers,
    loadingListUsers,
    dataListNewApprovedUsers,
    dataListNewPendingUsers,
    dataListNewUsersTable: { data, pagination },
    loadingListNewUsersTable,
  } = useSelector((state) => state.listUsers);

  const initialDate = moment().format('YYYY-MM-DD');
  const menosDate = moment().add(-6, 'days').format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(
      transactionsFetchAction({
        dataHoje: initialDate,
        dataMenoSete: menosDate,
      }),
    );
    dispatch(
      listNewUsersFetchAction({
        dataHoje: initialDate,
        dataMenoSete: menosDate,
      }),
    );
  }, []);

  const { transactionsData } = useSelector((state) => state.transactions);

  const defaultChartData = [0, 0, 0, 0, 0, 0, 0];

  const formatDates = (dates) => {
    var formattedDataArray = [];
    formattedDataArray = dates.map((date) => {
      return dayjs(date).format('DD/MM/YYYY');
    });

    return formattedDataArray;
  };

  const headerMovimentacao = [
    {
      id: 'hora',
      name: 'HORA',
      align: 'left',
    },
    {
      id: 'operacao',
      name: 'OPERAÇÃO',
      align: 'left',
    },
    {
      id: 'valor',
      name: 'VALOR',
      align: 'right',
    },
    {
      id: 'status',
      name: 'STATUS',
      align: 'center',
    },
  ];

  const headerEntradaCliente = [
    {
      id: 'data',
      name: 'DATA',
      align: 'left',
    },
    {
      id: 'nome',
      name: 'NOME',
      align: 'center',
    },
    {
      id: 'status',
      name: 'STATUS',
      align: 'center',
    },
  ];

  const bodyMovimentacao =
    movimentacaoData?.length > 0 &&
    movimentacaoData?.map((item) => {
      return {
        data: [
          { info: item.hora, align: 'left' },
          { info: item.operacao, align: 'left' },
          { info: `R$ ${formatMoney(item.valor)}`, align: 'right' },
          {
            info:
              item.status == 1 ? (
                <Styled.IconContainer iconColor={theme.colors.red} backgroundcolor={theme.colors.redTransparent}>
                  <ArrowUpward />
                </Styled.IconContainer>
              ) : (
                <Styled.IconContainer
                  iconColor={theme.colors.limeGreen}
                  backgroundcolor={theme.colors.limeGreenTransparent}
                >
                  <ArrowDownward />
                </Styled.IconContainer>
              ),
          },
        ],
      };
    });

  const bodyEntradaCliente =
    data?.length > 0 &&
    data?.map((item, index) => {
      return {
        data: [
          { info: formatDateHora(item.dataInsert), align: 'left' },
          { info: item.nome, align: 'left' },
          {
            info: item.idStatus ? (
              <StatusContainer code={`${item.idStatus}`} description={item.status}></StatusContainer>
            ) : (
              '-----'
            ),
            align: 'center',
          },
        ],
      };
    });

  let dates = transactionsData?.debito !== undefined ? Object.keys(transactionsData?.debito) : [];
  let formattedDates = formatDates(dates);
  let debitData = transactionsData?.debito !== undefined ? Object.values(transactionsData?.debito) : defaultChartData;
  let creditData = transactionsData?.debito !== undefined ? Object.values(transactionsData?.credito) : defaultChartData;

  const chartData = {
    title: 'Transações',
    secondaryTitle: 'Últimos 7 dias',
    labels: formattedDates,
    datasets: [
      {
        label: 'Crédito',
        backgroundColor: theme.colors.graficTrasacoesCredito,
        data: creditData,
        barPercentage: 0.6,
      },
      {
        label: 'Débito',
        backgroundColor: theme.colors.graficTrasacoesDebito,
        data: debitData,
        barPercentage: 0.6,
      },
    ],
  };

  const secondChartData = {
    title: 'Entrada de clientes',
    secondaryTitle: 'Últimos 7 dias',
    labels: formattedDates,
    datasets: [
      {
        label: 'Aguardando',
        backgroundColor: theme.colors.graficEntradaClientesAguardando,
        data: dataListNewPendingUsers,
        barPercentage: 0.6,
        borderWidth: 1.5,
      },
      {
        label: 'Aprovados',
        backgroundColor: theme.colors.graficEntradaClientesAprovados,
        data: dataListNewApprovedUsers,
        barPercentage: 0.6,
        borderWidth: 1.5,
      },
    ],
  };

  return (
    <Styled.ChartContainer>
      <Styled.ChartGrid>
        <Styled.ChartElement>
          <Styled.Header>
            <Typography variant="h5">{chartData.title}</Typography>
            <Typography variant="h6">{chartData.secondaryTitle}</Typography>
          </Styled.Header>
          <Chart
            title={chartData.subtitle}
            labels={chartData.labels}
            data={chartData.datasets}
            containerheight={'24.792vw'}
          />
        </Styled.ChartElement>
        <Styled.ChartElement>
          <Styled.Header>
            <Typography variant="h5">{secondChartData.title}</Typography>
            <Typography variant="h6">{secondChartData.secondaryTitle}</Typography>
          </Styled.Header>
          <Chart
            title={secondChartData.subtitle}
            labels={secondChartData.labels}
            data={secondChartData.datasets}
            moneyFormat={false}
            containerheight={'24.792vw'}
          />
        </Styled.ChartElement>
      </Styled.ChartGrid>
      <Styled.ChartTableGrid>
        <Styled.ChartElement>
          <Styled.TableContainer>
            <Styled.TableHeader>
              <Typography variant="h5">{'Movimentação do dia'}</Typography>
              <Typography variant="h6">
                QUANTIDADE:
                <Styled.TextBold>
                  {`${paginationMovements?.totalResult ?? 0} ${
                    paginationMovements?.totalResult === 1 ? 'movimentação' : 'movimentações'
                  }`}
                </Styled.TextBold>
              </Typography>
            </Styled.TableHeader>
            {!loadingDailyMovements && (
              <Table
                loading={loadingDailyMovements}
                error={errorDailyMovements}
                errorMessage={errorMessage}
                hasPagination={true}
                maxheight={'330px'}
                header={headerMovimentacao}
                body={bodyMovimentacao}
                filter={filter}
                setFilter={setFilter}
                pagination={paginationMovements}
                limit={filter.LIMIT}
              />
            )}
          </Styled.TableContainer>
        </Styled.ChartElement>
        <Styled.ChartElement>
          <Styled.TableContainer>
            <Styled.TableHeader>
              <Typography variant="h5">{'Entrada de clientes'}</Typography>
              <Typography variant="h6">
                QUANTIDADE:
                <Styled.TextBold>
                  {`${pagination?.totalResult ?? 0} ${pagination?.totalResult === 1 ? 'entrada' : 'entradas'}`}
                </Styled.TextBold>
              </Typography>
            </Styled.TableHeader>
            {!loadingListNewUsersTable && (
              <Table
                loading={loadingListUsers}
                error={errorListUsers}
                errorMessage={messageListUsers}
                hasPagination={true}
                maxheight={'330px'}
                header={headerEntradaCliente}
                body={bodyEntradaCliente}
                filter={filterClients}
                setFilter={setFilterClients}
                pagination={pagination}
                limit={filterClients.LIMIT}
              />
            )}
          </Styled.TableContainer>
        </Styled.ChartElement>
      </Styled.ChartTableGrid>
    </Styled.ChartContainer>
  );
}
