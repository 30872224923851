import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="24"
      fill="none"
      viewBox="0 0 74 24"
      onClick={(e) => props.onClick()}
    >
      <path
        fill="#fff"
        d="M44.976 7.312V17h-1.274V9.776L40.482 17h-.896l-3.234-7.238V17h-1.274V7.312h1.372l3.584 8.008 3.584-8.008h1.358zm9.31 5.558c0 .243-.013.5-.041.77h-6.132c.046.756.303 1.349.77 1.778.476.42 1.05.63 1.722.63.55 0 1.008-.126 1.372-.378.373-.261.634-.607.784-1.036h1.372A3.332 3.332 0 0152.9 16.44c-.616.457-1.382.686-2.296.686-.728 0-1.382-.163-1.96-.49A3.495 3.495 0 0147.3 15.25c-.327-.607-.49-1.307-.49-2.1 0-.793.158-1.489.476-2.086a3.309 3.309 0 011.33-1.372c.578-.327 1.24-.49 1.988-.49.728 0 1.372.159 1.932.476.56.317.989.756 1.288 1.316.308.55.462 1.176.462 1.876zm-1.315-.266c0-.485-.108-.9-.322-1.246a2.026 2.026 0 00-.882-.798 2.63 2.63 0 00-1.218-.28c-.644 0-1.195.205-1.652.616-.448.41-.705.98-.77 1.708h4.844zm6.885-3.416c.934 0 1.69.285 2.268.854.579.56.868 1.372.868 2.436V17h-1.26v-4.34c0-.765-.191-1.349-.574-1.75-.382-.41-.905-.616-1.568-.616-.672 0-1.208.21-1.61.63-.392.42-.588 1.031-.588 1.834V17h-1.274V9.328h1.274v1.092a2.54 2.54 0 011.022-.91c.439-.215.92-.322 1.442-.322zm12.171.14V17h-1.274v-1.134c-.242.392-.583.7-1.022.924-.429.215-.905.322-1.428.322a3.494 3.494 0 01-1.61-.364 2.786 2.786 0 01-1.134-1.12c-.27-.495-.406-1.097-.406-1.806V9.328h1.26v4.326c0 .756.192 1.34.574 1.75.383.401.906.602 1.568.602.682 0 1.218-.21 1.61-.63.392-.42.588-1.031.588-1.834V9.328h1.274zM3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
      ></path>
    </svg>
  );
}

export default Icon;
