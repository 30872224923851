import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Styled from './Dashboard.styles';
import Wrapper from '../../components/Wrapper/Wrapper';
import CardContainer from './components/CardsContainer';
import ChartContainer from './components/ChartContainer';
import { limitBalanceFetchAction } from '../../store/fetchActions/limitBalanceActions';

export default function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(limitBalanceFetchAction());
  }, []);

  return (
    <Wrapper title="Dashboard" margin="1.7vw 0">
      <Styled.Container>
        <CardContainer />
        <ChartContainer />
      </Styled.Container>
    </Wrapper>
  );
}
