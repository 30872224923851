import React, { useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import Tabs from '../../components/Tabs/Tabs';
import ContasPendentes from './components/TabContent/ContasPendentes';
import ContasAprovadas from './components/TabContent/ContasAprovadas';
import ContasNegadas from './components/TabContent/ContasNegadas';
import ContasBloqueadas from './components/TabContent/ContasBloqueadas';
import ContasEncerradas from './components/TabContent/ContasEncerradas';

export default function GerenciarContas() {
  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Pendentes', 'Aprovadas', 'Negadas', 'Bloqueadas', 'Encerradas'];

  return (
    <Wrapper title="Gerenciar Contas">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" account={true} />
      {tabIndex == 0 && <ContasPendentes value={tabIndex} index={0} />}
      {tabIndex == 1 && <ContasAprovadas value={tabIndex} index={1} />}
      {tabIndex == 2 && <ContasNegadas value={tabIndex} index={2} />}
      {tabIndex == 3 && <ContasBloqueadas value={tabIndex} index={3} />}
      {tabIndex == 4 && <ContasEncerradas value={tabIndex} index={4} />}
    </Wrapper>
  );
}
