import Styled from 'styled-components';
import { Button } from '@mui/material';

export const Container = Styled.div`
  display: flex;
  flex-direction: row;
`;

export const Form = Styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Detail = Styled.div`
  padding: 1.4rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const MensagemContainer = Styled.form`
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const TitleContainerMensagem = Styled.h2`

`;

export const InputMensagem = Styled.div`
  padding: 0.6rem 0.8rem;
  width: 100%;
`;

export const Infos = Styled.h3`
  color: ${(props) => props.theme.colors.white_2} !important;
  font-size: 12px;
  font-weight: normal;
`;

export const ButtonMui = Styled(Button)`
  && span{
    font-size: 11px !important;
    font-weight: unset;
  }
  && {
    display: flex;
    align-items: flex-start;
    background-color: ${(props) => props.theme.colors.veryLightGray_3};
    border-radius: 3rem;
    color: ${(props) => props.theme.colors.veryDark_2};
    margin-bottom: 0.6rem;
    cursor: unset;
    margin-right: 0.4rem;
  }
  &&:hover{
    background-color: ${(props) => props.theme.colors.veryLightGray_3};
    color: ${(props) => props.theme.colors.veryDark_2};
  }
`;

export const ContainerSelect = Styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0.6rem 0;
`;
