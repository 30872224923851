import api from '../../utils/services/api';

import {
  sendMensagemError,
  sendMensagemLoading,
  sendMensagemSuccess,
  sendMensagemPerfilError,
  sendMensagemPerfilLoading,
  sendMensagemPerfilSuccess,
  sendMensagemTopicError,
  sendMensagemTopicLoading,
  sendMensagemTopicSuccess,
  sendMensagemUniqueError,
  sendMensagemUniqueLoading,
  sendMensagemUniqueSuccess,
  sendMensagemMultipleError,
  sendMensagemMultipleLoading,
  sendMensagemMultipleSuccess,
  listMensagemlLoading,
  listMensagemSuccess,
  listMensagemError,
  sendTopicMensagemError,
  sendTopicMensagemSuccess,
  sendTopicMensagemLoading,
  createTopicError,
  createTopicSuccess,
  createTopicLoading,
  editTopicError,
  editTopicSuccess,
  editTopicLoading,
  listTopicCustumersError,
  listTopicCustumersSuccess,
  listTopicCustumersLoading,
  deleteTopicError,
  deleteTopicSuccess,
  deleteTopicLoading,
} from '../ducks/notification';
import { showAlert } from '../ducks/sweetAlert';

export const searchCpfSendMensagem = (filtro = { LIMIT: 10, PAGE: 1 }, callback) => {
  return async (dispatch) => {
    dispatch(sendMensagemLoading());
    try {
      const response = await api.post(`usuario/listar/aprovado`, filtro);
      dispatch(sendMensagemSuccess(response.data));
      callback(response.data.data);
    } catch (error) {
      dispatch(sendMensagemError(error?.response?.data));
      callback(error?.response?.data);
    }
  };
};

export const topicMensagem = (filtro, callback = () => null) => {
  return async (dispatch) => {
    dispatch(sendMensagemPerfilLoading());
    try {
      const response = await api.get(
        `/topicos?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro.SEARCH ?? ''}`,
      );
      dispatch(sendMensagemPerfilSuccess(response.data));
      callback(response.data);
    } catch (error) {
      dispatch(sendMensagemPerfilError(error?.response?.data));
      callback(error?.response?.data);
    }
  };
};

export const listMensagens = (filtro, callback = () => null) => {
  return async (dispatch) => {
    dispatch(listMensagemlLoading());
    try {
      const response = await api.get(
        `/push-notification/report?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro.SEARCH}&DATA_INICIAL=${filtro.DATA_INICIAL}&DATA_FINAL=${filtro.DATA_FINAL}`,
      );

      dispatch(listMensagemSuccess(response.data));
      callback(response.data);
    } catch (error) {
      dispatch(listMensagemError(error?.response?.data));
      callback(error?.response?.data);
    }
  };
};

export const sendMensagemTopic = (params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };
    dispatch(sendMensagemTopicLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/push-notification/topic`, params);
      dispatch(sendMensagemTopicSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Notificação enviada',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(sendMensagemTopicError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Notificação não enviada',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const sendMensagemUnique = (params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };
    dispatch(sendMensagemUniqueLoading());
    try {
      const response = await api.post(`/push-notification/user`, params);
      dispatch(sendMensagemUniqueSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Menssagen enviada com sucesso',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(sendMensagemUniqueError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const sendMensagemMultiple = (params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };
    dispatch(sendMensagemMultipleLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/push-notification/users`, params);
      dispatch(sendMensagemMultipleSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Notificação enviada',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(sendMensagemMultipleError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Notificação não enviada',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const searchTopicSendMensagem = (filtro, callback) => {
  return async (dispatch) => {
    dispatch(sendTopicMensagemLoading());
    try {
      const response = await api.get(
        `usuarios/notificaveis?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro.SEARCH}&DATA_NASCIMENTO=${filtro.DATA}&IS_PDV=${filtro.IS_PDV}`,
      );

      dispatch(sendTopicMensagemSuccess(response.data));
      callback(response.data.data);
    } catch (error) {
      dispatch(sendTopicMensagemError(error?.response?.data));
      callback([]);
    }
  };
};

export const deleteTopic = (params, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(topicMensagem({ PAGE: 1, SEARCH: '', LIMIT: 10 }));
    };
    dispatch(deleteTopicLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.delete(`/topic`, { data: params });
      dispatch(deleteTopicSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Grupo removido com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(deleteTopicError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Grupo não removido',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const createTopic = (params, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(topicMensagem({ PAGE: 1, SEARCH: '', LIMIT: 10 }));
    };
    dispatch(createTopicLoading());
    dispatch(showAlertAction({}));

    try {
      const response = await api.post(`/topic/subscribe`, params);
      dispatch(createTopicSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Grupo criado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(createTopicError(error?.response?.data?.mensagem));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const editTopic = (params, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(topicMensagem({ PAGE: 1, SEARCH: '', LIMIT: 10 }));
    };
    dispatch(editTopicLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.put(`/topic`, params);
      dispatch(editTopicSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Grupo editado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editTopicError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel editar o grupo.',
          icon: 'error',
        }),
      );
    }
  };
};

export const listTopicCustumers = (filtro, callback = () => undefined) => {
  return async (dispatch) => {
    dispatch(listTopicCustumersLoading());
    try {
      const response = await api.get(`/topic/users?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&ID=${filtro.ID}`);
      dispatch(listTopicCustumersSuccess(response.data));
      callback(response?.data?.data);
    } catch (error) {
      dispatch(listTopicCustumersError(error?.response?.data));
      callback([]);
    }
  };
};

export const addCustumersTopic = (params, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(topicMensagem({ PAGE: 1, SEARCH: '', LIMIT: 10 }));
    };
    dispatch(editTopicLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/topic/subscribe`, params);
      dispatch(editTopicSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Grupo editado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editTopicError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel editar o grupo.',
          icon: 'error',
        }),
      );
    }
  };
};

export const removeCustumersTopic = (params, callback = () => undefined) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(topicMensagem({ PAGE: 1, SEARCH: '', LIMIT: 10 }));
    };
    dispatch(editTopicLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.delete(`/topic/unsubscribe`, { data: params });
      dispatch(editTopicSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Grupo editado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editTopicError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel editar o grupo.',
          icon: 'error',
        }),
      );
    }
  };
};
