import api from '../../utils/services/api';

import { editUserActionError, editUserActionLoading, editUserActionSuccess } from '../ducks/editUser';
import { showAlert } from '../ducks/sweetAlert';
import { approvedUsersFetchAction } from './approvedUsersActions';
import { blockedUsersFetchAction } from './blockedUsersActions';

export const editUserFetchAction = (param, usersType, calback = () => undefined) => {
  return async (dispatch) => {
    const refreshTable = () => {
      if (usersType === 'approvedUsers') {
        calback();
        dispatch(approvedUsersFetchAction());
      } else {
        dispatch(blockedUsersFetchAction());
      }
    };
    dispatch(editUserActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.put(`/usuario/adm/editar`, param);
      dispatch(editUserActionSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Usuário editado com sucesso!',
          icon: 'success',
          func: refreshTable,
        }),
      );
    } catch (error) {
      dispatch(editUserActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: refreshTable,
        }),
      );
    }
  };
};
