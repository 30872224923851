import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../components/Loading';
import Modal from '../../../../components/Modal/Modal';
import { translateMessage } from '../../../../utils/functions/translateMessage';
import * as Styled from '../../styles/UsuariosAprovados.styles';
import { optionsClientsAprovedFunctions } from '../../../../utils/constant';
import DropDown from '../../../../components/DropDown/DropDown';
import { useState } from 'react';
import InformationCollum from '../Collums/InformationCollum';
import AboutCollum from '../Collums/AboutCollum';
import ModalPassword from '../../../../components/ModalPassword/ModalPassword';
import Accounts from '../Accounts/Accounts';
import TaxCollum from '../Collums/TaxCollum';
import LegalRepresentative from '../representativesCard/RepresentativesCard';
import { retrievePasswordFetchAction } from '../../../../store/fetchActions/retrievePassword';
import { approvedUsersFetchAction } from '../../../../store/fetchActions/approvedUsersActions';
import ModalBlockUser from './BlockUser';

const ClientesAprovados = ({ open, close }) => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [nameSelectedItem, setNameSelectedItem] = useState('');
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
  const [isModalBlockUserOpen, setIsModalBlockUserOpen] = useState(false);
  const [optionText, setOptionText] = useState('');
  const [functionSelectedItem, setFunctionSelectedItem] = useState(() => {});

  const { loadingUser, errorUser, errorMessageUser } = useSelector((state) => state.user);
  const { loadingEditUser } = useSelector((res) => res.editUser);
  const { CPFCNPJ } = useSelector((state) => state?.user?.usersData);

  const callFunction = () => {
    const callFunction = {
      editData: () => setIsEditing(true),
      blockUser: () => setIsModalBlockUserOpen(true),
      unlockUser: () => setIsModalBlockUserOpen(true),
      recoverPassword: () => recoverPassword(),
      showLegalRepresentative: () => showLegalRepresentative(),
      logOutUser: () => {
        setOptionText('deslogar');
        setIsModalBlockUserOpen(true);
      },
    };

    setFunctionSelectedItem(callFunction[nameSelectedItem]);
  };

  const recoverPassword = () => {
    const param = { CPFCNPJ_USUARIO: CPFCNPJ };
    dispatch(retrievePasswordFetchAction(param));
  };

  return (
    <Modal open={open} close={close} title="Detalhes Cliente" width={'96.222vw'}>
      {loadingUser ? (
        <Loading />
      ) : errorUser ? (
        <h1>{translateMessage(errorMessageUser)}</h1>
      ) : (
        <>
          <Styled.EditButtonContainer>
            <h4>Informações Pessoais</h4>
            <DropDown
              isEditing={isEditing}
              openModalPassword={() => setIsModalPasswordOpen(true)}
              options={optionsClientsAprovedFunctions}
              setNameSelectedItem={(e) => setNameSelectedItem(e)}
            />
          </Styled.EditButtonContainer>
          <Styled.FirstBlock>
            <InformationCollum />
            {loadingEditUser ? <Loading /> : <AboutCollum isEditing={isEditing} cancel={() => setIsEditing(false)} />}
          </Styled.FirstBlock>
          <Styled.SecondBlock>
            <Accounts />
          </Styled.SecondBlock>
          <Styled.Line />
          <Styled.ThirdBlock>
            <TaxCollum />
          </Styled.ThirdBlock>
          <Styled.Line />

          {CPFCNPJ?.length === 14 && <LegalRepresentative />}
        </>
      )}

      {isModalPasswordOpen && (
        <ModalPassword
          openModal={isModalPasswordOpen}
          closeModal={() => setIsModalPasswordOpen(false)}
          nextFunction={callFunction}
        />
      )}

      {isModalBlockUserOpen && (
        <ModalBlockUser
          openModal={isModalBlockUserOpen}
          closeModal={() => {
            setIsModalBlockUserOpen(false);
            setIsEditing(false);
            dispatch(
              approvedUsersFetchAction({
                SEARCH: '',
                DATA_INICIAL: '',
                DATA_FINAL: '',
                LIMIT: 10,
                PAGE: 1,
              }),
            );
          }}
          blockUser
          optionText={optionText}
        />
      )}
    </Modal>
  );
};

export default ClientesAprovados;
