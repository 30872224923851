import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPixKeys: [],
  errorPixKeys: false,
  errorLoadingPixKeys: false,
  errorMessagePixKeys: '',
  loadingPixKeys: false,
  paginationPixKeys: {},
};

export const pixKeysActionLoading = createAction('PIX_KEYS_ACTION_LOADING');
export const pixKeysActionSuccess = createAction('PIX_KEYS_ACTION_SUCCESS');
export const pixKeysActionError = createAction('PIX_KEYS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pixKeysActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPixKeys: true,
      errorPixKeys: false,
    };
  },
  [pixKeysActionSuccess.type]: (state, payload) => {
    const { data, pagination } = payload?.payload?.data?.data;

    return {
      ...state,
      loadingPixKeys: false,
      errorPixKeys: false,
      dataPixKeys: data,
      paginationPixKeys: pagination,
    };
  },
  [pixKeysActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingPixKeys: false,
      errorPixKeys: true,
      errorMessagePixKeys: payload?.mensagem,
      dataPixKeys: [],
      paginationPixKeys: {},
    };
  },
});
