import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Container } from './Chart.styles';
import formatMoney from '../../utils/functions/formatMoneyBRL';
import { useTheme } from 'styled-components';

const Chart = ({ labels, data, height, width, title, moneyFormat, containerheight, containerWidth, displayLegend }) => {
  const theme = useTheme();

  return (
    <Container width={containerWidth} height={containerheight}>
      <Bar
        data={{
          labels: labels,
          datasets: data,
        }}
        width={height}
        height={width}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
              ticks: {
                precision: 0,
                callback: function (value) {
                  if (moneyFormat) return 'R$ ' + formatMoney(value);
                  else return parseInt(value);
                },
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: title,
              align: 'start',
              font: { size: '16px', weight: '100', family: 'poppins' },
              padding: {
                bottom: 30,
              },
            },
            labels: {
              fontSize: 10,
              fontColor: theme.colors.white,
            },
            legend: {
              display: displayLegend,
              position: 'bottom',
              labels: {
                usePointStyle: true,
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  var currentDataValue = context.raw;
                  if (moneyFormat) return 'R$ ' + formatMoney(currentDataValue);
                  else return parseInt(currentDataValue);
                },
              },
            },
          },
        }}
      />
    </Container>
  );
};
Chart.defaultProps = {
  labels: [],
  data: [],
  title: ' ',
  height: 300,
  width: 300,
  type: '',
  moneyFormat: true,
};
Chart.propTypes = {
  labels: PropTypes.array,
  data: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  title: PropTypes.string,
  moneyFormat: PropTypes.bool,
  secondaryTitle: PropTypes.string,
  containerheight: PropTypes.string,
  containerWidth: PropTypes.number,
};
export default Chart;
