import Modal from '../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import InputFormatValue from '../../../../components/InputFormatValue';
import { Button } from '../../../../components/Button/Button.styles';
import { useDispatch } from 'react-redux';
import { editLimitPaymentAction } from '../../../../store/fetchActions/limitPayments';
import removeSpecialChars from '../../../../utils/functions/removeSpecialChars';
import * as S from './EditLimit.styles';
import { useTheme } from 'styled-components';

const EditLimit = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control, reset } = useForm({});

  const edit = (data) => {
    const param = {
      VALOR: data.novo_limite.replace('R$', '').replace('.', '').replace(',', '.'),
      CPFCNPJ: removeSpecialChars(['.', '-', '/'], localStorage.getItem('cpfcnpj')),
    };
    dispatch(editLimitPaymentAction(param, props?.close));
  };

  return (
    <Modal
      open={props?.open}
      close={() => props?.close()}
      title={props?.title + ' - ' + props?.info?.ITEM}
      width={'35vw'}
    >
      <form onSubmit={handleSubmit(edit)}>
        <S.Section>
          <InputFormatValue
            name={'limite_atual'}
            control={control}
            label={'Limite atual'}
            value={props?.info?.VALOR}
            disabled={true}
          />
          <InputFormatValue name={'novo_limite'} control={control} label={'Novo Limite'} />
        </S.Section>
        <Button
          backgroundcolor={theme.colors.buttonConfirm}
          width={'100%'}
          height={'3.125vw'}
          margin={'1vw 0'}
          hoverbgcolor={theme.colors.buttonConfirmHover}
          hovercolor={theme.colors.buttonConfirmHoverText}
          stylecolor={theme.colors.buttonConfirmText}
          type="submit"
        >
          <span>SALVAR</span>
        </Button>
      </form>
    </Modal>
  );
};

export default EditLimit;
