import api from '../../utils/services/api';
import { hideAlert, showAlert } from '../ducks/sweetAlert';

import {
  validatePasswordActionError,
  validatePasswordActionLoading,
  validatePasswordActionSuccess,
} from '../ducks/validatePassword';

export const validatePasswordFetchAction = (params, callback) => {
  return async (dispatch) => {
    dispatch(
      showAlert({
        title: 'Carregando',
        text: '',
        icon: '',
        loadingSpinner: true,
      }),
    );
    dispatch(validatePasswordActionLoading());
    try {
      const response = await api.post(`coban/senha/validar`, params);
      dispatch(hideAlert());
      dispatch(validatePasswordActionSuccess(response.data));
      callback(response.status);
    } catch (error) {
      dispatch(hideAlert());
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
      dispatch(validatePasswordActionError(error?.response?.data));
      callback(error?.response?.status);
    }
  };
};
