import api from '../../utils/services/api';

import { reversedBillsActionError, reversedBillsActionLoading, reversedBillsActionSuccess } from '../ducks/reversedBills';

export const reversedBillsFetchAction = (filtro = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(reversedBillsActionLoading());
    try {
      const response = await api.get(`boleto/buscar_boletos_estornados?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}&SEARCH=${filtro.SEARCH}&DATA_INICIAL=${filtro.DATA_INICIAL}&DATA_FINAL=${filtro.DATA_FINAL}`);
      dispatch(reversedBillsActionSuccess(response.data));
    } catch (error) {
      dispatch(reversedBillsActionError(error?.response?.data));
    }
  };
};
