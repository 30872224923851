import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import Button from '../../../../../components/Button/Button';
import Modal from '../../../../../components/Modal/Modal';
import * as S from '../CreatGroup.styles';
import * as M from './index';

const EditGroup = (props) => {
  const theme = useTheme();
  const [openEditDescription, setOpenEditDescription] = useState(false);
  const [openRemoveCustomers, setOpenRemoveCustomers] = useState(false);
  const [openAddCustumers, setOpenAddCustumers] = useState(false);

  return (
    <>
      <Modal open={props.openModal} close={props.closeModal} width={'100vh'} title={'Edição de grupo'}>
        <div>
          <S.ConteinerBottom>
            <Button
              backgroundcolor={theme.colors.buttonConfirm}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonConfirmHover}
              hovercolor={theme.colors.buttonConfirmHoverText}
              mycolor={theme.colors.buttonConfirmText}
              onClick={() => setOpenAddCustumers(true)}
            >
              <span>Adicionar Clientes</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonRemove}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonRemoveHover}
              hovercolor={theme.colors.buttonRemoveHoverText}
              mycolor={theme.colors.buttonRemoveText}
              onClick={() => {
                setOpenRemoveCustomers(true);
              }}
            >
              <span> Remover Clientes</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.veryDarkCyan}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.strongOrange}
              hovercolor={theme.colors.white}
              mycolor={theme.colors.white}
              onClick={() => {
                setOpenEditDescription(true);
              }}
            >
              <span> Editar Descrições</span>
            </Button>
          </S.ConteinerBottom>
        </div>
      </Modal>

      {openEditDescription && (
        <M.EditDescription
          openModal={openEditDescription}
          closeModal={() => setOpenEditDescription(false)}
          closeInitial={props.closeModal}
          info={props.info}
        />
      )}

      {openAddCustumers && (
        <M.AddClient
          openModal={openAddCustumers}
          closeModal={() => setOpenAddCustumers(false)}
          closeInitial={props.closeModal}
          info={props.info}
        />
      )}

      {openRemoveCustomers && (
        <M.RemoveClient
          openModal={openRemoveCustomers}
          closeModal={() => setOpenRemoveCustomers(false)}
          closeInitial={props.closeModal}
          info={props.info}
        />
      )}
    </>
  );
};

export default EditGroup;
