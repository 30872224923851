import api from '../../utils/services/api';

import {
  deleteSystemUserActionError,
  deleteSystemUserActionLoading,
  deleteSystemUserActionSuccess,
  editSystemUserActionError,
  editSystemUserActionLoading,
  editSystemUserActionSuccess,
  createSystemUserActionError,
  createSystemUserActionLoading,
  createSystemUserActionSuccess,
} from '../ducks/systemUser';
import { hideAlert, showAlert } from '../ducks/sweetAlert';
import { perfisFetchAction } from './perfisActions';
import { cobansFetchAction } from './cobansActions';

export const deleteSystemUserFetchAction = (name, id, CPFCNPJCOBAN, EMAIL, FONECEL, callback) => {
  const params = {
    ID: id,
    NOME: name,
    STATUS: true,
    CPFCNPJCOBAN: CPFCNPJCOBAN,
    EMAIL: EMAIL,
    FONECEL: FONECEL,
  };
  return async (dispatch) => {
    const refreshTable = () => {
      dispatch(perfisFetchAction());
      dispatch(cobansFetchAction());
      callback();
    };
    dispatch(deleteSystemUserActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`coban/atualizar`, params);
      dispatch(deleteSystemUserActionSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Usuário removido com sucesso!',
          icon: 'success',
          func: refreshTable,
        }),
      );
    } catch (error) {
      dispatch(deleteSystemUserActionError(error?.response?.data));
    }
  };
};

export const editSystemUserFetchAction = (
  name,
  id,
  fonecel,
  email,
  cpfcnpj,
  idPerfilAcesso,
  callback = () => undefined,
) => {
  const params = {
    ID: id,
    NOME: name,
    FONECEL: fonecel,
    EMAIL: email,
    CPFCNPJCOBAN: cpfcnpj,
    ID_PERFIL: idPerfilAcesso,
  };

  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(perfisFetchAction());
      dispatch(cobansFetchAction());
    };
    dispatch(editSystemUserActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`coban/atualizar`, params);
      dispatch(editSystemUserActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso!',
          text: 'Usuário editado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editSystemUserActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops!',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const createSystemUserFetchAction = (params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(perfisFetchAction());
      dispatch(cobansFetchAction());
    };
    dispatch(createSystemUserActionLoading());

    dispatch(
      showAlert({
        title: 'Carregando',
        text: '',
        icon: '',
        loadingSpinner: true,
      }),
    );
    try {
      const response = await api.post(`coban/inserir`, params);
      dispatch(createSystemUserActionSuccess(response.data));
      dispatch(hideAlert());
      dispatch(
        showAlert({
          title: 'Sucesso!',
          text: 'Usuário criado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(createSystemUserActionError(error?.response?.data));
      dispatch(hideAlert());
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};

export const editSystemPasswordUserFetchAction = (senha, cpfcnpj, callback) => {
  const params = {
    SENHA: senha,
    CPFCNPJ_USUARIO: cpfcnpj,
  };
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(perfisFetchAction());
      dispatch(cobansFetchAction());
    };
    dispatch(editSystemUserActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`coban/alterar_senha`, params);
      dispatch(editSystemUserActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso!',
          text: 'Usuário editado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editSystemUserActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...!',
          text: error?.response?.data?.mensagem,
          icon: 'error',
        }),
      );
    }
  };
};
