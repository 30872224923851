import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  errorMessage: '',
  QRCODE: '',
  jwt: '',
  nivelAcesso: '',
};

export const loginActionLoading = createAction('LOGIN_ACTION_LOADING');
export const loginActionSuccess = createAction('LOGIN_ACTION_SUCCESS');
export const loginActionError = createAction('LOGIN_ACTION_ERROR');
export const authenticateActionLoading = createAction('LOGIN_ACTION_LOADING');
export const authenticateActionSuccess = createAction('LOGIN_ACTION_SUCCESS');
export const authenticateActionError = createAction('LOGIN_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [loginActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingLogin: true,
      errorLogin: false,
      loginData: [],
    };
  },
  [loginActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLogin: false,
      errorLogin: false,
      QRCODE: payload.QRCODE,
    };
  },
  [loginActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLogin: false,
      errorLogin: true,
      errorMessageLogin: payload,
      loginData: [],
    };
  },
  [authenticateActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingLogin: true,
      errorLogin: false,
    };
  },
  [authenticateActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLogin: false,
      errorLogin: false,
      jwt: payload.jwt,
      nivelAcesso: payload.nivelAcesso,
    };
  },
  [authenticateActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLogin: false,
      errorLogin: true,
      errorMessageLogin: payload,
    };
  },
});
