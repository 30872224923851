import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      fill="none"
      viewBox="0 0 24 24"
      style={{ paddingRight: props.paddingRight }}
    >
      <path
        fill={props.color ? props.color : '#F29305'}
        d="M11.944 0C5.357 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.59 0 11.944 0zM12 21.6c-5.293 0-9.6-4.307-9.6-9.6 0-5.293 4.28-9.6 9.543-9.6C17.27 2.4 21.6 6.707 21.6 12c0 5.293-4.307 9.6-9.6 9.6z"
      ></path>
      <path fill={props.color ? props.color : '#F29305'} d="M10.8 6h2.4v8.4h-2.4V6zm0 9.6h2.4V18h-2.4v-2.4z"></path>
    </svg>
  );
}

export default Icon;
