import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  approvedUsersData: [],
  paginationMensagem: { totalResult: 0 },
  paginationListMensagem: { totalResult: 0 },
  paginationListTopic: { totalResult: 0 },
  errorMenssagem: '',
  loadingSendMensagem: true,
  loadingSendMensagemPerfil: true,
};

export const sendMensagemLoading = createAction('SEND_MENSAGEM_LOADING');
export const sendMensagemSuccess = createAction('SEND_MENSAGEM_SUCCESS');
export const sendMensagemError = createAction('SEND_MENSAGEM_ERROR');
export const sendMensagemPerfilLoading = createAction('SEND_MENSAGEM_PERFIL_LOADING');
export const sendMensagemPerfilSuccess = createAction('SEND_MENSAGEM_PERFIL_SUCCESS');
export const sendMensagemPerfilError = createAction('SEND_MENSAGEM_PERFIL_ERROR');
export const sendMensagemTopicLoading = createAction('SEND_MENSAGEM_TOPIC_LOADING');
export const sendMensagemTopicSuccess = createAction('SEND_MENSAGEM_TOPIC_SUCCESS');
export const sendMensagemTopicError = createAction('SEND_MENSAGEM_TOPIC_ERROR');
export const sendMensagemUniqueLoading = createAction('SEND_MENSAGEM_UNIQUE_LOADING');
export const sendMensagemUniqueSuccess = createAction('SEND_MENSAGEM_UNIQUE_SUCCESS');
export const sendMensagemUniqueError = createAction('SEND_MENSAGEM_UNIQUE_ERROR');
export const sendMensagemMultipleLoading = createAction('SEND_MENSAGEM_MULTIPLE_LOADING');
export const sendMensagemMultipleSuccess = createAction('SEND_MENSAGEM_MULTIPLE_SUCCESS');
export const sendMensagemMultipleError = createAction('SEND_MENSAGEM_MULTIPLE_ERROR');
export const listMensagemlLoading = createAction('LIST_MENSAGEM_LOADING');
export const listMensagemSuccess = createAction('LIST_MENSAGEM_SUCCESS');
export const listMensagemError = createAction('LIST_MENSAGEM_ERROR');
export const sendTopicMensagemLoading = createAction('SEND_TOPIC_MENSAGEM_LOADING');
export const sendTopicMensagemSuccess = createAction('SEND_TOPIC_MENSAGEM_SUCCESS');
export const sendTopicMensagemError = createAction('SEND_TOPIC_MENSAGEM_ERROR');
export const createTopicLoading = createAction('CREATE_TOPIC_LOADING');
export const createTopicSuccess = createAction('CREATE_TOPIC_SUCCESS');
export const createTopicError = createAction('CREATE_TOPIC_ERROR');
export const editTopicLoading = createAction('EDIT_TOPIC_LOADING');
export const editTopicSuccess = createAction('EDIT_TOPIC_SUCCESS');
export const editTopicError = createAction('EDIT_TOPIC_ERROR');
export const listTopicCustumersLoading = createAction('LIST_TOPIC_CUSTUMERS_LOADING');
export const listTopicCustumersSuccess = createAction('LIST_TOPIC_CUSTUMERS_SUCCESS');
export const listTopicCustumersError = createAction('LIST_TOPIC_CUSTUMERS_ERROR');
export const deleteTopicLoading = createAction('DELETE_TOPIC_LOADING');
export const deleteTopicSuccess = createAction('DELETE_TOPIC_SUCCESS');
export const deleteTopicError = createAction('DELETE_TOPIC_ERROR');

export default createReducer(INITIAL_STATE, {
  [sendMensagemLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSendMensagem: true,
      error: false,
    };
  },
  [sendMensagemSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagem: false,
      error: false,
    };
  },
  [sendMensagemError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagem: false,
      error: true,
      errorMenssagem: payload?.mensagem,
    };
  },
  [sendMensagemPerfilLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSendMensagemPerfil: true,
      errorPerfil: false,
    };
  },
  [sendMensagemPerfilSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemPerfil: false,
      errorPerfil: false,
      topicosMensagem: payload?.data,
      paginationListTopic: payload?.pagination,
    };
  },
  [sendMensagemPerfilError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemPerfil: false,
      errorPerfil: true,
      errorMenssagemPerfil: payload?.mensagem,
    };
  },
  [sendMensagemTopicLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSendMensagemTopic: true,
      errorSendMensagemTopic: false,
    };
  },
  [sendMensagemTopicSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemTopic: false,
      errorSendMensagemTopic: false,
      topicosMensagem: payload?.data,
    };
  },
  [sendMensagemTopicError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemTopic: false,
      errorSendMensagemTopic: true,
      errorMenssagemTopic: payload?.mensagem,
    };
  },
  [sendMensagemUniqueLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSendMensagemUnique: true,
      errorSendMensagemUnique: false,
    };
  },
  [sendMensagemUniqueSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemUnique: false,
      errorSendMensagemUnique: false,
      uniqueMensagem: payload?.data,
    };
  },
  [sendMensagemUniqueError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemUnique: false,
      errorSendMensagemUnique: true,
      errorMenssagemUnique: payload?.mensagem,
    };
  },
  [sendMensagemMultipleLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSendMensagemMultiple: true,
      errorSendMensagemMultiple: false,
    };
  },
  [sendMensagemMultipleSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemMultiple: false,
      errorSendMensagemMultiple: false,
      multipleMensagem: payload?.data,
    };
  },
  [sendMensagemMultipleError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemMultiple: false,
      errorSendMensagemMultiple: true,
      errorMenssagemMultiple: payload?.mensagem,
    };
  },
  [listMensagemlLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingListMensagem: true,
      errorListMensagem: false,
      paginationListMensagem: {},
    };
  },
  [listMensagemSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListMensagem: false,
      errorListMensagem: false,
      listMensagem: payload?.data,
      paginationListMensagem: payload?.pagination,
    };
  },
  [listMensagemError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListMensagem: false,
      errorListMensagem: true,
      errorMenssagemList: payload?.mensagem,
      paginationListMensagem: {},
    };
  },
  [sendMensagemTopicLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingSendMensagemTopic: true,
      errorSendMensagemTopic: false,
    };
  },
  [sendMensagemTopicSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemTopic: false,
      errorSendMensagemTopic: false,
      topicosMensagem: payload?.data,
    };
  },
  [sendMensagemTopicError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingSendMensagemTopic: false,
      errorSendMensagemTopic: true,
      errorMenssagemTopic: payload?.mensagem,
    };
  },
  [createTopicLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingCreateTopic: true,
      errorCreateTopic: false,
    };
  },
  [createTopicSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingCreateTopic: false,
      errorCreateTopic: false,
      createTopicosMensagem: payload?.data,
    };
  },
  [createTopicError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingCreateTopic: false,
      errorCreateTopic: true,
      errorMenssagemCreateTopic: payload?.mensagem,
    };
  },
  [editTopicLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingEditTopic: true,
      errorEditTopic: false,
    };
  },
  [editTopicSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingEditTopic: false,
      errorEditTopic: false,
      editTopicosMensagem: payload?.data,
    };
  },
  [editTopicError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingEditTopic: false,
      errorEditTopic: true,
      errorMenssagemEditTopic: payload?.mensagem,
    };
  },
  [listTopicCustumersLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingListTopicCustumers: true,
      errorListTopicCustumersMensagem: false,
      paginationListTopicCustumersMensagem: {},
    };
  },
  [listTopicCustumersSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListTopicCustumers: false,
      errorListTopicCustumersMensagem: false,
      ListTopicCustumersData: payload?.data,
      paginationListTopicCustumersMensagem: payload?.pagination,
    };
  },
  [listTopicCustumersError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingListTopicCustumers: false,
      errorListTopicCustumersMensagem: true,
      errorListTopicCustumersMensagem: payload?.mensagem,
      paginationListTopicCustumersMensagem: {},
    };
  },
  [deleteTopicLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingDeleteTopic: true,
      errorDeleteTopic: false,
    };
  },
  [deleteTopicSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeleteTopic: false,
      errorDeleteTopic: false,
      deleteTopicosMensagem: payload?.data,
    };
  },
  [deleteTopicError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeleteTopic: false,
      errorDeleteTopic: true,
      errorMenssagemDeleteTopic: payload?.mensagem,
    };
  },
});
