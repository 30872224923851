const Default = {
  colors: {
    // Default
    button: '#C48320',
    buttonText: '#FFFFFF',

    // Menu
    menuOpen: '#262424',
    menuClose: '#262424',
    menuSelectIten: 'rgba(196, 131, 32, 0.2)',
    menuSelectItenBorder: '#c48320',

    // Botão Confirma/Salvar/Entrar/Criar
    buttonConfirm: '#C48320',
    buttonConfirmHover: '#C48320',
    buttonConfirmText: '#FFFFFF',
    buttonConfirmHoverText: '#FFFFFF',

    // Botão Voltar/Sair/Cancelar
    buttonBack: '#F8F8F8',
    buttonBackHover: '#F8F8F8',
    buttonBackText: '#000',
    buttonBackHoverText: '#000',

    //Botão Remover
    buttonRemove: '#C48320',
    buttonRemoveHover: 'rgba(204, 33, 10, 1)',
    buttonRemoveText: '#FFFFFF',
    buttonRemoveHoverText: '#FFFFFF',

    //Botão criar usuarios
    buttonCreateUsuario: '#C48320',
    buttonCreateUsuarioHover: '#C48320',
    buttonCreateUsuarioText: '#FFFFFF',
    buttonCreateUsuarioHoverText: '#FFFFFF',

    //Botão buscar/enviar/exportar
    buttonSearch: '#262424',
    buttonSearchHover: '#C48320',
    buttonSearchText: '#FFFFFF',
    buttonSearchHoverText: '#FFFFFF',

    //Botão aprovar
    buttonApproved: '#219326',
    buttonApprovedHover: '#219326',
    buttonApprovedText: '#FFFFFF',
    buttonApprovedHoverText: '#FFFFFF',

    //Botão negar
    buttonNegaded: '#262424',
    buttonNegadedHover: '#DA100B',
    buttonNegadedText: '#FFFFFF',
    buttonNegadedHoverText: '#FFFFFF',

    // Load SweetAlert
    loader: '#C48320',

    // Cards Dashboard
    iconCardCredito: '#029F70',
    iconCardCreditoBorder: '#029F70',
    iconCardCreditoContainer: 'rgba(2, 159, 112, 0.1)',

    iconCardDebito: '#B8302C',
    iconCardDebitoBorder: '#B8302C',
    iconCardDebitoContainer: 'rgba(184, 48, 44, 0.1)',

    iconCardContaDigital: '#262424',
    iconCardContaDigitalBorder: '#262424',
    iconCardContaDigitalContainer: 'rgba(38, 36, 36, 0.15)',

    // Graficos Dashboard
    graficTrasacoesCredito: '#029F70',
    graficTrasacoesDebito: '#B8302C',

    graficEntradaClientesAprovados: '#029F70',
    graficEntradaClientesAguardando: '#E69C2E',

    // Tabela
    tableHeader: '#707070',
    tableHeaderText: '#F8F8F8',
    tableScrollBackground: '#3333334d',
    tableScroll: '#9091944d',
    tableHoverRow: '#e9e9e9',

    // Paginação
    paginationText: '#000',
    pagination: '#9DD6CE',
    paginationEvent: 'rgba(51, 51, 51, 0.1)',

    // Dropdown Gerenciar
    dropDownLI: '#f1f1f1',
    dropDownLIHover: '#C48320',
    dropDownLIHoverText: '#F8F8F8',
    dropDownButton: '#333333',
    dropDownButtonHover: '#C48320',
    dropDownButtonHoverText: '#F8F8F8',
    dropDownButtonText: '#F8F8F8',

    // CORES
    white: '#F8F8F8',
    white_2: '#fff',
    whiteTransparent: '#ffffff0',
    black: '#000',
    veryDark: '#101410',
    veryDark_2: '#262424',
    veryDark_3: '#1F1A17',
    brown: '#c48320',
    nutBrown: '#F29305',
    blueCyan: '#e8f0fe',
    darkBlue: '#303545',
    darkBlueTransparent: '#1b589f0d',
    gray: '#333333',
    grayTransparent: 'rgba(38, 36, 36, 0.1)',
    darkGrayishBlue: '#909194',
    darkGrayishBlueTransparent: '#9091941a',
    lightGray: '#c0c0c0',
    veryLightGray: '#e9e9e9',
    veryLightGray_2: '#f1f1f1',
    veryLightGray_3: '#DEDEDE',
    veryLightGray_4: '#ECECEC',
    veryDarkGrayTransparent: '#3333330d',
    red: '#EC2A00',
    vividRed: '#F23005',
    vividRed_2: '#DA100B',
    vividRed_2Transparent: 'rgba(218, 16, 11, 0.2)',
    veryPaleRed: '#ffdede',
    strongRed: 'rgba(204, 33, 10, 1)',
    redTransparent: '#EC2A001A',
    redTransparent1: 'rgba(242, 48, 5, 0.1)',
    brightRed: '#e0232e',
    limeGreen: '#00EC2A',
    limeGreenTransparent: '#00EC2A26',
    darklimeGreen: '#219326',
    darklimeGreenTransparent: 'rgba(33, 147, 38, 0.2)',
    darkModerateLimeGreen: '#32A54A',
    strongCyan: '#1A9EBB',
    strongBlue: '#0092D4',
    veryDarkCyan: '#0C4842',
    veryDarkDesaturatedBlue: '#2F4858',
    lightGrayishYellow: '#fefae3',
    verySoftYellow: '#f0e1a1',
    veryDarkGrayishOrange: '#61534e',
    veryDarkGray: '#3333330d',
    strongOrange: '#c48220',
    vividOrangeTransparent: '#F8AF2240',
    vividOrange: '#FFAA05',
    vividOrange_2: '#f29305',
  },
};

const light = {
  yellow: '#f29305',
};

export default { Default, light };
