import api from '../../utils/services/api';

import {
  listNewUsersActionError,
  listNewUsersActionLoading,
  listNewUsersActionSuccess,
  listNewUsersTableActionError,
  listNewUsersTableActionLoading,
  listNewUsersTableActionSuccess,
} from '../ducks/listUsers';

export const listNewUsersFetchAction = (params) => {
  return async (dispatch) => {
    dispatch(listNewUsersActionLoading());
    try {
      const response = await api.get(
        `usuario/buscar/entrada-usuarios?DATA_INICIAL=${params.dataMenoSete}&DATA_FINAL=${params.dataHoje}`,
      );
      dispatch(listNewUsersActionSuccess(response.data));
    } catch (error) {
      dispatch(listNewUsersActionError(error?.response?.data));
    }
  };
};

export const listNewUsersTableFetchAction = (filter) => {
  return async (dispatch) => {
    dispatch(listNewUsersTableActionLoading());
    try {
      const response = await api.get(
        `/usuario/listar/dashboard?LIMIT=${filter.limit ?? 10}&PAGE=${filter.page ?? 1}&DATA_INICIAL=${
          filter.dataInicial
        }&DATA_FINAL=${filter.dataFinal}`,
      );
      dispatch(listNewUsersTableActionSuccess(response.data));
    } catch (error) {
      dispatch(listNewUsersTableActionError(error?.response?.data));
    }
  };
};
