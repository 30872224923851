import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Button.styles';

const Button = ({
  backgroundcolor,
  mycolor,
  border = 'none',
  width,
  height,
  hoverbgcolor,
  hovercolor,
  type,
  onClick,
  margin,
  roleName,
  onMouseOver,
  onMouseOut,
  outlined,
  component,
  cursor,
  dataTestId,
  disabled,
  children,
}) => {
  return (
    <Styled.Button
      variant="contained"
      size="large"
      backgroundcolor={backgroundcolor}
      stylecolor={mycolor}
      border={border}
      width={width}
      height={height}
      hoverbgcolor={hoverbgcolor}
      hovercolor={hovercolor}
      type={type}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      margin={margin}
      role={roleName}
      component={component}
      outlined={outlined}
      cursor={cursor}
      data-testid={dataTestId}
      disabled={disabled}
    >
      {children}
    </Styled.Button>
  );
};

export default Button;

Button.propTypes = {
  backgroundcolor: PropTypes.string,
  // color: PropTypes.string,
  border: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  hoverbgcolor: PropTypes.string,
  hovercolor: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string,
  onClick: PropTypes.func,
  margin: PropTypes.string,
  roleName: PropTypes.string,
};
