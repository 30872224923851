import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './SelectOptions.styles';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel } from '@material-ui/core';

const SelectOptions = ({ options, name, width, height, variant, padding, initialValue, disabled, label, onChange }) => {
  return (
    <FormControl variant={variant} style={{ width: `${width}` }}>
      {label && <InputLabel id={name}>{label}</InputLabel>}
      <Styled.SelectStyled
        height={height}
        width={width}
        padding={padding}
        disabled={disabled}
        defaultValue={initialValue}
        onChange={(event) => onChange(event?.target?.value)}
      >
        {options?.map((option) => (
          <MenuItem key={option.ID} value={option.ID}>
            {option.NOME}
          </MenuItem>
        ))}
      </Styled.SelectStyled>
    </FormControl>
  );
};

export default SelectOptions;

SelectOptions.defaultProps = {
  name: ' ',
  options: [],
  variant: 'standard',
  initialName: null,
  initialValue: null,
  label: '',
  disabled: false,
};

SelectOptions.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  control: PropTypes.any,
  width: PropTypes.string,
  variant: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  initialName: PropTypes.any,
  initialValue: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
