const validatePassword = (string) => {
  try {
    if (typeof string != 'string') {
      string = string + '';
    }
    let array = string.split('');
    const rest = array.map((val, ind) => {
      let sequencial = '';
      if (ind > 0) {
        let validSequencia = array[ind] - array[ind - 1];
        if (validSequencia === 1) {
          sequencial = true;
        } else if (validSequencia === -1) {
          sequencial = true;
        } else {
          sequencial = false;
        }
      }
      if (sequencial == true) {
        return true;
      } else {
        return false;
      }
    });
    if (rest.filter((x) => x === true).length >= 2) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return '';
  }
};
export default validatePassword;
