import { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Information from './components/InformationPf';
import { showAlert } from '../../../../store/ducks/sweetAlert';
import { CheckCircle, DeniCircle } from '../../../../assets/icon';
import { approveUserFetchAction } from '../../../../store/fetchActions/approveUserActions';
import Button from '../../../../components/Button/Button';
import SelectOptions from '../../../../components/SelectOptions';
import { translateMessage } from '../../../../utils/functions/translateMessage';
import * as S from './ClientesPendentesPf.styles';
import { useForm } from 'react-hook-form';
import { detailAccountsFetchActionReset } from '../../../../store/fetchActions/accountActions';
import { useTheme } from 'styled-components';
import Loading from '../../../../components/Loading';
import FaceMatch from './components/faceMatchOcr/FaceMatch';
import Ocr from './components/faceMatchOcr/Ocr';
import { ocrStatusReset } from '../../../../store/fetchActions/ocrServicesActions';
import { faceMatchStatusReset } from '../../../../store/fetchActions/faceMatchServicesActions';

const ClientesPendentesPf = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [hoverColorDeni, setHoverColorDeni] = useState();

  const { handleSubmit: handleSubmitApproval, control: controlAproval } = useForm({});

  const { loadingApproveUser } = useSelector((state) => state.approveUser);
  const { usersData, loadingUser, errorUser, errorMessageUser } = useSelector((state) => state.user);
  const { cobansData } = useSelector((state) => state.cobans);

  const submitApprovation = (data) => (data.perfil ? approveUser(data) : approveError());

  const perfisArray = cobansData.filter((val) => val.CPFCNPJ === null);

  const approveError = () => {
    dispatch(
      showAlert({
        title: 'Perfil não selecionado!',
        text: 'Selecione um perfil para aprovar o cliente',
        icon: 'error',
      }),
    );
  };

  const approveUser = (data) => {
    const param = { CPFUSUARIO: props?.userID, ID_COBAN: data.perfil };
    dispatch(approveUserFetchAction(param, props.close));
  };

  return (
    <Modal
      open={props.open}
      close={() => {
        props.close();
        dispatch(detailAccountsFetchActionReset());
        dispatch(ocrStatusReset());
        dispatch(faceMatchStatusReset());
      }}
      title="Dados Cliente"
      width={'96.222vw'}
    >
      {loadingUser || loadingApproveUser ? (
        <Loading />
      ) : errorUser ? (
        <h1>{translateMessage(errorMessageUser)}</h1>
      ) : (
        <>
          <S.DataValidation>
            <FaceMatch />
            <Ocr />
          </S.DataValidation>

          <S.CardInfo>
            <Information title="Informações Pessoais" data={usersData} />
          </S.CardInfo>

          <S.Form onSubmit={handleSubmitApproval(submitApprovation)}>
            <S.ContainerOptions>
              <S.SectionTitle>Perfil Associado ao cliente</S.SectionTitle>
              <SelectOptions
                control={controlAproval}
                options={perfisArray}
                name={'perfil'}
                width="35.111vw"
                label={'Perfil'}
                required={true}
              />
            </S.ContainerOptions>

            <S.ContainerButtons>
              <S.SectionTitle>Aprovação do Cliente</S.SectionTitle>
              <Button
                backgroundcolor={theme.colors.buttonApproved}
                width="35.111vw"
                height="3.125vw"
                hoverbgcolor={theme.colors.buttonApprovedHover}
                hovercolor={theme.colors.buttonApprovedHoverText}
                mycolor={theme.colors.buttonApprovedText}
                type="submit"
                margin={'0 0 1.083vw 0'}
              >
                <CheckCircle />
                <span>Aprovar</span>
              </Button>
              <Button
                backgroundcolor={theme.colors.buttonNegaded}
                width="35.111vw"
                height="3.125vw"
                hoverbgcolor={theme.colors.buttonNegadedHover}
                hovercolor={theme.colors.buttonNegadedHoverText}
                mycolor={theme.colors.buttonNegadedText}
                onClick={props.deny}
                onMouseOver={() => setHoverColorDeni(theme.colors.white)}
                onMouseOut={() => setHoverColorDeni(theme.colors.white)}
              >
                <DeniCircle color={hoverColorDeni} />
                <span>Negar</span>
              </Button>
            </S.ContainerButtons>
          </S.Form>
        </>
      )}
    </Modal>
  );
};

export default ClientesPendentesPf;
