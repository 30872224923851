import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { perfisFetchAction } from '../../../store/fetchActions/perfisActions';
import { MdDelete } from 'react-icons/md';
import * as Styled from '../PerfisUsuarios.styles';
import Table from '../../../components/Table/Table';
import Tooltip from '@material-ui/core/Tooltip';
import { HiOutlineReceiptTax } from 'react-icons/hi';
import { FaUserCog } from 'react-icons/fa';
import { taxesFetchAction } from '../../../store/fetchActions/taxesActions';
import { LoadingContainer } from '../../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@material-ui/core';
import ModalPassword from '../../../components/ModalPassword/ModalPassword';
import { Percent } from '../../../assets/icon';
import Permission from '../../../components/Permission/Permission';
import ModalRates from '../Modals/Perfis/TargetRates';
import { useTheme } from 'styled-components';
import ModalCriarPerfils from '../Modals/Perfis/CreatePerfil';
import ModalEditPerfils from '../Modals/Perfis/EditPerfil';
import ModalEditarTaxas from '../Modals/Perfis/EditTaxes';
import ModalDeletarPerfil from '../Modals/Perfis/DeletePerfil';

const Perfis = ({ value, index }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [editPerfil, setEditPerfil] = useState({});
  const [modalEditarPerfil, setModalEditarPerfil] = useState(false);
  const [modalCriarPerfil, setModalCriarPerfil] = useState(false);
  const [modalTaxas, setModalTaxas] = useState(false);
  const [modalExcluirPerfil, setModalExcluirPerfil] = useState(false);
  const [dadosExcluirUsuario, setDadosExcluirUsuario] = useState({
    name: '',
    id: '',
  });
  const [modalPasswordEditarPerfil, setModalPasswordEditarPerfil] = useState(false);
  const [modalPasswordExcluirPerfil, setModalPasswordExcluirPerfil] = useState(false);
  const [modalPasswordEditarTaxas, setModalPasswordEditarTaxas] = useState(false);
  const [taxasID, setTaxasID] = useState([]);
  const [passwordEditTaxas, setPasswordEditTaxas] = useState(false);
  const [targetRates, setTargetRates] = useState(false);

  const { cobansData, loadingCobans, errorCobans, errorMessageCobans } = useSelector((state) => state.cobans);

  useEffect(() => {
    dispatch(perfisFetchAction());
  }, []);

  const editTaxes = (id) => {
    dispatch(taxesFetchAction(taxasID));
    setModalTaxas(true);
  };

  function filterProfile(profile) {
    if (profile.CPFCNPJ === null) {
      return true;
    } else {
      return false;
    }
  }

  function ordenArray(a, b) {
    return b.ID - a.ID;
  }

  const header = [
    {
      id: 'nome',
      name: 'NOME',
      align: 'left',
    },
    {
      id: 'ações',
      name: 'AÇÕES',
      align: 'center',
    },
  ];

  const body =
    cobansData?.length > 0 &&
    cobansData
      ?.filter(filterProfile)
      .sort(ordenArray)
      .map((perfil, index) => {
        return {
          data: [
            { info: perfil.NOME, align: 'left' },
            {
              info: (
                <Styled.Row>
                  <Tooltip title="Editar Taxas">
                    <Styled.IconContainer
                      onClick={() => {
                        setTaxasID(perfil.ID);
                        setModalPasswordEditarTaxas(true);
                      }}
                      backgroundcolor={theme.colors.grayTransparent}
                    >
                      <HiOutlineReceiptTax size={25} />
                    </Styled.IconContainer>
                  </Tooltip>

                  <Permission permissions={[1]}>
                    <Tooltip title="Editar Tarifas">
                      <Styled.IconContainer
                        onClick={() => {
                          setTaxasID(perfil.ID);
                          setPasswordEditTaxas(true);
                        }}
                        backgroundcolor={theme.colors.grayTransparent}
                      >
                        <Percent />
                      </Styled.IconContainer>
                    </Tooltip>
                  </Permission>

                  <Tooltip title="Editar Perfil">
                    <Styled.IconContainer
                      onClick={() => {
                        setEditPerfil({ name: perfil.NOME, id: perfil.ID });
                        setModalPasswordEditarPerfil(true);
                      }}
                      backgroundcolor={theme.colors.grayTransparent}
                    >
                      <FaUserCog size={25} />
                    </Styled.IconContainer>
                  </Tooltip>

                  <Tooltip title="Excluir Perfil">
                    <Styled.IconContainer
                      onClick={() => {
                        setDadosExcluirUsuario({
                          name: perfil.NOME,
                          id: perfil.ID,
                        });
                        setModalPasswordExcluirPerfil(true);
                      }}
                      backgroundcolor={theme.colors.redTransparent1}
                      color={theme.colors.vividRed}
                    >
                      <MdDelete size={25} />
                    </Styled.IconContainer>
                  </Tooltip>
                </Styled.Row>
              ),
            },
          ],
        };
      });

  return (
    <div hidden={value !== index}>
      <Styled.ButtonContainer>
        <Styled.ButtonStatusUsers
          backgroundcolor={theme.colors.buttonConfirm}
          width="12.153vw"
          height="3vw"
          hoverbgcolor={theme.colors.buttonConfirmHover}
          hovercolor={theme.colors.buttonConfirmHoverText}
          styledcolor={theme.colors.buttonConfirmText}
          onClick={() => setModalCriarPerfil(true)}
        >
          <span className="plus-span">+</span>
          <span>CRIAR PERFIL</span>
        </Styled.ButtonStatusUsers>
      </Styled.ButtonContainer>

      {loadingCobans ? (
        <LoadingContainer>
          <CircularProgress size={'5vw'} color="inherit" />
        </LoadingContainer>
      ) : (
        <Table
          loading={loadingCobans}
          error={errorCobans}
          errorMessage={errorMessageCobans}
          header={header}
          body={body}
        />
      )}

      {modalEditarPerfil && (
        <ModalEditPerfils open={modalEditarPerfil} close={() => setModalEditarPerfil(false)} info={editPerfil} />
      )}
      {modalCriarPerfil && <ModalCriarPerfils open={modalCriarPerfil} close={() => setModalCriarPerfil(false)} />}
      {modalTaxas && <ModalEditarTaxas open={modalTaxas} close={() => setModalTaxas(false)} id={taxasID} />}
      {modalExcluirPerfil && (
        <ModalDeletarPerfil
          open={modalExcluirPerfil}
          close={() => setModalExcluirPerfil(false)}
          info={dadosExcluirUsuario}
        />
      )}
      {targetRates && <ModalRates open={targetRates} close={() => setTargetRates(false)} id={taxasID} />}

      <ModalPassword
        openModal={modalPasswordExcluirPerfil}
        closeModal={() => setModalPasswordExcluirPerfil(false)}
        nextFunction={() => setModalExcluirPerfil(true)}
      />
      <ModalPassword
        openModal={modalPasswordEditarPerfil}
        closeModal={() => setModalPasswordEditarPerfil(false)}
        nextFunction={() => setModalEditarPerfil(true)}
      />
      <ModalPassword
        openModal={modalPasswordEditarTaxas}
        closeModal={() => setModalPasswordEditarTaxas(false)}
        nextFunction={editTaxes}
      />
      <ModalPassword
        openModal={passwordEditTaxas}
        closeModal={() => setPasswordEditTaxas(false)}
        nextFunction={() => setTargetRates(true)}
      />
    </div>
  );
};

export default Perfis;
