import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  transactionsData: [],
  messageTransactions: '',
  loadingTransactions: false,
};

export const transactionsActionLoading = createAction('TRANSACTIONS_ACTION_LOADING');
export const transactionsActionSuccess = createAction('TRANSACTIONS_ACTION_SUCCESS');
export const transactionsActionError = createAction('TRANSACTIONS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [transactionsActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingTransactions: true,
      errorTransactions: false,
      transactionsData: [],
    };
  },
  [transactionsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingTransactions: false,
      errorTransactions: false,
      transactionsData: payload,
    };
  },
  [transactionsActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingTransactions: false,
      errorTransactions: true,
      messageTransactions: payload,
      transactionsData: [],
    };
  },
});
