import api from '../../utils/services/api';
import dayjs from 'dayjs';

import {
  reportsActionSuccess,
  reportsActionError,
  reportsActionLoading,
  paymentWaysAndTransactionTypesActionSuccess,
  paymentWaysAndTransactionTypesActionError,
  reportsExcelActionLoading,
  reportsExcelActionStopLoading,
} from '../ducks/reports';

export const reportsFetchAction = (filter = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(reportsActionLoading());

    try {
      const response = await api.post(`usuario/historico/relatorio_parametrizado`, filter);
      dispatch(reportsActionSuccess(response));
    } catch (error) {
      dispatch(reportsActionError());
    }
  };
};

export const getPaymentWayAndTransactionTypes = () => {
  return async (dispatch) => {
    try {
      const response = await api.get(`transacao/tipo_transacao`);
      dispatch(paymentWaysAndTransactionTypesActionSuccess(response));
    } catch (error) {
      dispatch(paymentWaysAndTransactionTypesActionError());
    }
  };
};

export const reportsExcelFetchAction = (filter) => {
  return async (dispatch) => {
    dispatch(reportsExcelActionLoading());

    api
      .post(`transacao/exportar/movimentacao`, filter, {
        responseType: 'blob',
      })
      .then((response) => {
        let fileName = `relatorio_parametrizado${dayjs().format('DD/MM/YYYY')}.xlsx`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
            fileName,
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
    dispatch(reportsExcelActionStopLoading());
  };
};
