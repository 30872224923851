import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 16 16"
      style={{ paddingRight: '1rem' }}
    >
      <g fill="#fff" clipPath="url(#clip0_1_2)">
        <path d="M15.964.686a.5.5 0 00-.65-.65L.767 5.855a.75.75 0 00-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 00.844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.501.501 0 10.928.372l2.8-7zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733z"></path>
        <path d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm-1.993-1.679a.499.499 0 00-.686.172l-1.17 1.95-.547-.547a.5.5 0 00-.708.708l.774.773a.75.75 0 001.174-.144l1.335-2.226a.499.499 0 00-.172-.686z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <path fill="#000" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
