import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationDigitalServices: { totalResult: 0 },
  loadingDigitalServices: false,
  messageDigitalServices: '',
  dataDigitalServices: [],
  errorDigitalServices: false,
};

export const digitalServicesActionLoading = createAction('DIGITAL_SEVICES_ACTION_LOADING');
export const digitalServicesActionSuccess = createAction('DIGITAL_SEVICES_ACTION_SUCCESS');
export const digitalServicesActionError = createAction('DIGITAL_SEVICES_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [digitalServicesActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingDigitalServices: true,
      errorDigitalServices: false,
      dataDigitalServices: [],
    };
  },
  [digitalServicesActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDigitalServices: false,
      errorDigitalServices: false,
      dataDigitalServices: payload.data,
      paginationDigitalServices: payload.pagination,
    };
  },
  [digitalServicesActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDigitalServices: false,
      errorDigitalServices: true,
      messageDigitalServices: payload?.mensagem,
      dataDigitalServices: [],
    };
  },
});
