import React, { useState, useEffect } from 'react';
import Table from '../../../components/Table/Table';
import { deleteTopic, topicMensagem } from '../../../store/fetchActions/notification';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../components/Filter/Filter';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button/Button';
import { ImSearch, ImPlus } from 'react-icons/im';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { SecondFilterLine } from '../../../components/Filter/Filter.styles';
import SelectOptions from '../../../components/SelectOptions';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import { FirstFilterLine } from './ViewNotification.styles.jsx';
import * as S from './ManageGroup.styles';
import ModalCreateGroup from './modal/CreateGroup';
import { useTheme } from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import ModalEditGroup from './modal/EditGroup/EditGroup';
import ModalPassword from '../../../components/ModalPassword/ModalPassword';
import { pagesOptions } from '../../../utils/constant';
import Loading from '../../../components/Loading';

const ViewNotigication = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [editGroup, setEditGroup] = useState(false);
  const [dataGroup, setDataGroup] = useState();
  const [idDelete, setIdDelete] = useState();
  const [modalPassword, setModalPassword] = useState(false);

  const { handleSubmit, control, reset } = useForm({});

  const { topicosMensagem, paginationListTopic, loadingSendMensagemPerfil, errorPerfil, errorMenssagemPerfil } =
    useSelector((state) => state.notification);

  useCallFilter(filterData, setFilter, topicMensagem);

  useEffect(() => {
    setFilterData('');
    reset({ SEARCH: '', LIMIT: 10, PAGE: 1 });
  }, []);

  useEffect(() => {
    dispatch(topicMensagem(filter));
  }, [filter.PAGE]);

  const header = [
    {
      id: 'Nome',
      name: 'Nome',
      align: 'left',
    },
    {
      id: 'Descricao',
      name: 'Descrição',
      align: 'left',
    },
    {
      id: '',
      name: '',
      align: 'center',
    },
  ];

  const body = topicosMensagem?.map((val) => {
    return {
      data: [
        {
          info: val.nome || '-----',
          align: 'left',
        },
        {
          info: val.descricao,
          align: 'left',
        },
        {
          info: (
            <S.ContainerBottom>
              <Tooltip title="Editar">
                <S.IconContainer
                  onClick={() => {
                    setEditGroup(true);
                    setDataGroup(val);
                  }}
                  backgroundcolor={theme.colors.grayTransparent}
                >
                  <MdModeEdit size={25} />
                </S.IconContainer>
              </Tooltip>
              <Tooltip title="Remover">
                <S.IconContainer
                  onClick={() => {
                    setModalPassword(true);
                    setIdDelete(val.id);
                  }}
                  backgroundcolor={theme.colors.redTransparent1}
                  color={theme.colors.vividRed}
                >
                  <MdDelete size={25} />
                </S.IconContainer>
              </Tooltip>
            </S.ContainerBottom>
          ),
          align: 'center',
        },
      ],
    };
  });

  const filterTable = (data) => {
    setFilterData(data);
  };

  return (
    <div>
      <Filter>
        <S.CreateButtonGroup>
          <Button
            backgroundcolor={theme.colors.buttonSearch}
            width="22.153vw"
            hoverbgcolor={theme.colors.buttonSearchHover}
            hovercolor={theme.colors.buttonSearchHoverText}
            mycolor={theme.colors.buttonSearchText}
            onClick={() => setIsModalInfoOpen(true)}
          >
            <ImPlus className="default-svg" size={'1.181vw'} />
            <span>Criar Grupo</span>
          </Button>
        </S.CreateButtonGroup>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Título" name={'SEARCH'} width={'28.042vw'} margin={'0 0 0 1.369vw'} control={control} />
            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>

          <SecondFilterLine>
            <h5>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {paginationListTopic?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>

      {loadingSendMensagemPerfil ? (
        <Loading />
      ) : body ? (
        <Table
          loading={loadingSendMensagemPerfil}
          error={errorPerfil}
          hasPagination={true}
          filter={filter}
          pagination={paginationListTopic}
          setFilter={setFilter}
          errorMessage={errorMenssagemPerfil}
          filterTable={filterTable}
          header={header}
          body={body}
        />
      ) : (
        <Table
          loading={false}
          error={false}
          hasPagination={true}
          filter={filter}
          pagination={paginationListTopic}
          setFilter={setFilter}
          errorMessage={errorMenssagemPerfil}
          filterTable={filterTable}
          header={header}
          body={[]}
        />
      )}

      {isModalInfoOpen && (
        <ModalCreateGroup openModal={isModalInfoOpen} closeModal={(e) => setIsModalInfoOpen(false)} />
      )}

      {editGroup && <ModalEditGroup openModal={editGroup} closeModal={(e) => setEditGroup(false)} info={dataGroup} />}

      {modalPassword && (
        <ModalPassword
          openModal={modalPassword}
          closeModal={() => setModalPassword(false)}
          nextFunction={() => {
            dispatch(deleteTopic({ ID: idDelete }));
            setModalPassword(false);
          }}
        />
      )}
    </div>
  );
};

export default ViewNotigication;
