import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationLogs: { totalResult: 0, totalPages: 1 },
  loadingLogs: false,
  messageLogs: '',
  dataLogs: [],
  errorLogs: false,
};

export const logsActionLoading = createAction('LOGS_ACTION_LOADING');
export const logsActionSuccess = createAction('LOGS_ACTION_SUCCESS');
export const logsActionError = createAction('LOGS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [logsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingLogs: true,
      errorLogs: false,
      paginationLogs: {},
      dataLogs: [],
    };
  },
  [logsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLogs: false,
      errorLogs: false,
      messageLogs: payload?.mensagem,
      paginationLogs: payload?.pagination ?? { totalResult: 0, totalPages: 1 },
      dataLogs: payload?.logs,
    };
  },
  [logsActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLogs: false,
      errorLogs: true,
      messageLogs: payload?.mensagem,
      paginationLogs: {},
      dataLogs: [],
    };
  },
});
