import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingSaldoGeral: false,
  messageSaldoGeral: '',
  dataSaldoGeral: [],
  errorSaldoGeral: false,
};

export const imageLoading = createAction('IMAGE_ACTION_LOADING');
export const imageSuccess = createAction('IMAGE_ACTION_SUCCESS');
export const imageError = createAction('IMAGE_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [imageLoading.type]: (state) => {
    return {
      ...state,
      loadingImage: true,
      errorImage: false,
    };
  },
  [imageSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingImage: false,
      errorImage: false,
      typeImage: payload?.typeImage,
      imgData: payload?.imgData,
    };
  },
  [imageError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingImage: false,
      errorImage: true,
      typeImage: '',
      imgData: '',
    };
  },
});
