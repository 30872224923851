import styled from 'styled-components';
import { TextField as InputMaterial } from '@mui/material';

export const Container = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;

export const Input = styled(InputMaterial)`
  width: ${(props) => props.width};
  background-color: ${(props) => props.backcolor + ' !important'};
  border-radius: ${(props) => props.radius ?? '5px'};

  .MuiInputBase-input {
    color: ${(props) => props.styledcolor + ' !important'};
  }

  input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px ${(props) => props.autofill ?? props.theme.colors.veryLightGray_4} inset;
  }

  & label {
    color: ${(props) =>
      props.type != 'date' ? props.labelcolor + ' !important' : props.theme.colors.whiteTransparent};
    margin-left: ${(props) => props.spacelabelinput ?? '0.2vw'};
    white-space: nowrap;
  }

  & label.Mui-focused {
    color: ${(props) => props.labelfocuscolor + ' !important'};
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${(props) => props.borderfocuscolor + ' !important'};
  }

  & .MuiFilledInput-underline:after {
    border-bottom-color: ${(props) => props.borderfocuscolor + ' !important'};
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${(props) => props.borderfocuscolor + ' !important'};
    }
  }

  &.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiInputBase-input.MuiInput-input {
    margin-left: ${(props) => props.spacelabelinput ?? '0.2vw'};
  }

  .MuiFormLabel-root.Mui-disabled {
    font-size: 14px;
    font-weight: 800;
  }
`;
