import Styled from 'styled-components';

export const CheckContainer = Styled.div`
  display: flex; 
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const CheckGrid = Styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 30px;
  overflow-y: scroll;
  height: 280px;
`;

export const DescribeSection = Styled.div`
  display: flex;
  padding-bottom: 1rem;
`;

export const RowSection = Styled.div`
  padding-right: 1rem;
`;

export const ConteinerBottom = Styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0;

  & span{
    font-size: 12px;
    white-space: nowrap;
  }
`;

export const Menssagen = Styled.h3`
  display: flex;
  justify-content: space-around;
`;

export const ButtonSearch = Styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.6rem;
`;

export const FirstFilterLine = Styled.div`
  display: flex;
  border-radius: 5px;
  justify-content: flex-start;
  width: ${(props) => props.width || 'auto'};
  align-items: center;
  margin-bottom: 1vw;
  gap: 1vw;
`;

export const myHr = Styled.hr`
  border-top: 1px solid rgba(0,0,0,.1);
  margin: 3.2vw 0;
`;

export const FormSearch = Styled.form`
  margin-top: 1.5rem;
`;

export const InputsContainer = Styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5vw;

  div {
    padding-right: 1vw;
  }
`;
