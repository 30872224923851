import React from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../../../../components/Modal/Modal';
import * as S from './EditGroup.styles';
import { useDispatch } from 'react-redux';
import Button from '../../../../../components/Button/Button';
import { useTheme } from 'styled-components';
import TextField from '../../../../../components/TextField';
import { editTopic } from '../../../../../store/fetchActions/notification';

const EditDescription = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({});

  const Edit = (data) => {
    const param = {
      ID: props?.info?.id,
      TITULO: data.titulo,
      DESCRICAO: data.descricao,
    };
    dispatch(editTopic(param, (props.closeModal(), props.closeInitial())));
  };

  return (
    <Modal open={props.openModal} close={props.closeModal} width={'100vh'} title={'Editar Descrição'}>
      <form onSubmit={handleSubmit(Edit)}>
        <S.DescribeSection>
          <S.RowSection>
            <TextField
              label="Título"
              name={'titulo'}
              value={props?.info?.nome}
              control={control}
              required
              width={'100%'}
              multiline
            />
          </S.RowSection>
          <S.RowSection>
            <TextField
              label="Descrição"
              name={'descricao'}
              value={props?.info?.descricao}
              control={control}
              required
              width={'100%'}
              multiline
            />
          </S.RowSection>
        </S.DescribeSection>
        <S.ConteinerBottom>
          <Button
            backgroundcolor={theme.colors.buttonConfirm}
            width="12.153vw"
            margin={'0 1.389vw 0 0'}
            hoverbgcolor={theme.colors.buttonConfirmHover}
            hovercolor={theme.colors.buttonConfirmHoverText}
            mycolor={theme.colors.buttonConfirmText}
            type={'submit'}
          >
            <span>Salvar</span>
          </Button>
          <Button
            backgroundcolor={theme.colors.buttonBack}
            width="12.153vw"
            margin={'0 1.389vw 0 0'}
            hoverbgcolor={theme.colors.buttonBackHover}
            hovercolor={theme.colors.buttonBackHoverText}
            mycolor={theme.colors.buttonBackText}
            onClick={() => props?.closeModal()}
          >
            <span>Cancelar</span>
          </Button>
        </S.ConteinerBottom>
      </form>
    </Modal>
  );
};

export default EditDescription;
