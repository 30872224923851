import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    limitBalanceData: [],
    paginationLimitBalance: { totalResult: 0 },
    errorMessageLimitBalance: '',
};

export const limitBalanceActionLoading = createAction('LIMIT_BALANCE_ACTION_LOADING');
export const limitBalanceActionSuccess = createAction('LIMIT_BALANCE_ACTION_SUCCESS');
export const limitBalanceActionError = createAction('LIMIT_BALANCE_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
    [limitBalanceActionLoading.type]: (state, payload) => {
        return {
            ...state,
            loadingLimitBalance: true,
            errorLimitBalance: false,
            LimitBalanceData: [],
            paginationLimitBalance: {},
        };
    },
    [limitBalanceActionSuccess.type]: (state, { payload }) => {
        return {
            ...state,
            loadingLimitBalance: false,
            errorLimitBalance: false,
            LimitBalanceData: payload.data,
            paginationLimitBalance: payload.pagination,
        };
    },
    [limitBalanceActionError.type]: (state, { payload }) => {
        return {
            ...state,
            loadingLimitBalance: false,
            errorLimitBalance: true,
            errorMessageLimitBalance: payload?.mensagem,
            LimitBalanceData: [],
            paginationLimitBalance: {},
        };
    },
});
