import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 40 40"
      style={{ cursor: 'pointer', paddingLeft: '0.6rem', paddingTop: '0.2rem' }}
      onClick={(e) => props.onClick(e)}
    >
      <path
        fill="#000"
        d="M21.499 19.994L32.755 8.727a1.064 1.064 0 00-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494 8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 00-.309.751c0 .284.11.55.309.747L18.5 19.993 7.245 31.263a1.064 1.064 0 00.003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27a1.054 1.054 0 001.153.228 1.057 1.057 0 00.655-.981c0-.283-.11-.55-.31-.747L21.499 19.994z"
      ></path>
    </svg>
  );
}

export default Icon;
