import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { BiTransfer } from 'react-icons/bi';
import { MdOutlinePayments } from 'react-icons/md';
import { VscHome } from 'react-icons/vsc';
import {
  GerenciarContas,
  Logs,
  Relatorio,
  GerenciarClientes,
  TransacaoBoleto,
  ServicosDigitais,
  Pix,
  Notificacao,
  TransferenciaTed,
  BarrConfig,
  Antifraude,
} from '../../../assets/icon';
import * as Styled from '../../Tabs/Tabs.styles';

export default function ListItemSidebar(props) {
  const { classes } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('/dashboard');

  useEffect(() => {
    setSelectedTab(location.pathname);
  }, [location]);

  const menus = [
    { navigate: '/gerenciador', name: 'Gerenciador', icon: <BarrConfig /> },
    {
      navigate: '/gerenciar-clientes',
      name: 'Gerenciar Clientes',
      icon: <GerenciarClientes />,
      badge: (
        <Styled.UserNotificationContainer>
          <Styled.Badge color="default" badgeContent={props?.pending}></Styled.Badge>
        </Styled.UserNotificationContainer>
      ),
    },
    {
      navigate: '/gerenciar-contas',
      name: 'Gerenciar Contas',
      icon: <GerenciarContas />,
      badge: (
        <Styled.UserNotificationContainer>
          <Styled.Badge color="default" badgeContent={props?.pendingAccount}></Styled.Badge>
        </Styled.UserNotificationContainer>
      ),
    },
    { navigate: '/antifraude', name: 'Relatório Antifraude', icon: <Antifraude /> },
    { navigate: '/transferencias', name: 'Transf. Interna', icon: <BiTransfer /> },
    { navigate: '/transferencia-ted', name: 'Transf. TED', icon: <TransferenciaTed /> },
    { navigate: '/pix', name: 'Transações PIX', icon: <Pix /> },
    { navigate: '/pagamentos', name: 'Pagamentos', icon: <MdOutlinePayments /> },
    { navigate: '/boletos', name: 'Transação de Boletos', icon: <TransacaoBoleto /> },
    { navigate: '/recarga', name: 'Recargas de celular', icon: <ServicosDigitais /> },
    { navigate: '/relatorios', name: 'Relatórios', icon: <Relatorio /> },
    { navigate: '/logs', name: 'Logs', icon: <Logs /> },
    { navigate: '/notificacao', name: 'Notificações', icon: <Notificacao /> },
  ];

  return (
    <List>
      <ListItem
        selected={selectedTab === '/dashboard' || selectedTab === '/'}
        button
        onClick={() => navigate('/dashboard')}
        key={'menus' + 0}
      >
        <ListItemIcon className={classes.icons}>
          <VscHome />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {menus.map((val, indx) => (
        <ListItem
          selected={selectedTab === val.navigate}
          button
          onClick={() => navigate(val.navigate)}
          key={'menus' + (indx + 1)}
        >
          <ListItemIcon className={classes.icons}>{val.icon}</ListItemIcon>
          <ListItemText primary={val.name} />
          {val?.badge}
        </ListItem>
      ))}
    </List>
  );
}

ListItemSidebar.propTypes = {
  text: PropTypes.string,
  index: PropTypes.number,
  classes: PropTypes.object,
};
