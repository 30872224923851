import formatCPF from '../../utils/functions/formatCPF';
import formatCNPJ from '../../utils/functions/formatCNPJ';
import formatMoney from '../../utils/functions/formatMoneyBRL';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import verifyEmptyValues from '../../utils/verifyEmptyValues';

export default function convertObjToArrayBoletos(dataDetailBankSlip) {
  const {
    CPFCNPJ,
    CPFCNPJ_BENEFICIARIO,
    DATA,
    CODSTATUS,
    IDPAGAMENTO,
    ID_TRANSACAO,
    NOME,
    NOME_BENEFICIARIO,
    NUMEROCODIGOBARRA,
    TAXA,
    VALOR,
    STATUS,
    VENDA_DIGITADA,
  } = dataDetailBankSlip;

  const detailBankSlipsArray = [
    {
      name: 'Código da Transação',
      value: verifyEmptyValues(ID_TRANSACAO),
    },
    {
      name: 'Status',
      value: STATUS ? <StatusContainer code={CODSTATUS} description={STATUS}></StatusContainer> : '-----',
    },
    { name: 'Data de Emissão', value: verifyEmptyValues(DATA) },
    {
      name: 'Nome',
      value: verifyEmptyValues(NOME),
    },
    {
      name: 'CPF/CNPJ',
      value: CPFCNPJ ? (CPFCNPJ.length === 11 ? formatCPF(CPFCNPJ) : formatCNPJ(CPFCNPJ)) : '-----',
    },
    {
      name: 'Beneficiário',
      value: NOME_BENEFICIARIO,
    },
    {
      name: 'CPF/CNPJ do Beneficiário',
      value: CPFCNPJ_BENEFICIARIO
        ? CPFCNPJ_BENEFICIARIO.length === 11
          ? formatCPF(CPFCNPJ_BENEFICIARIO)
          : formatCNPJ(CPFCNPJ_BENEFICIARIO)
        : '-----',
    },

    {
      name: 'Valor Total',
      value: VENDA_DIGITADA ? 'R$ ' + formatMoney(VENDA_DIGITADA) : '-----',
    },
    { name: 'Valor', value: VALOR ? 'R$ ' + formatMoney(VALOR) : '-----' },
    { name: 'Taxa', value: TAXA ? 'R$ ' + formatMoney(TAXA) : '-----' },
    {
      name: 'ID do Boleto',
      value: IDPAGAMENTO,
    },
    {
      name: 'Código de Barras',
      value: NUMEROCODIGOBARRA,
    },
  ];

  return detailBankSlipsArray;
}
