import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import Tabs from '../../components/Tabs/Tabs';
import Perfis from './tabContent/Perfis';
import Usuarios from './tabContent/Usuarios';
import Limite from './tabContent/Limite';
import { useDispatch } from 'react-redux';
import { cobansFetchAction } from '../../store/fetchActions/cobansActions';

export default function GerenciarClientes() {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Perfis', 'Usuários', 'Limite'];

  useEffect(() => {
    dispatch(cobansFetchAction());
  }, []);

  return (
    <Wrapper title="Gerenciador" margin="4.1vw 0">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" margin="1.5vw 0" />
      <Perfis value={tabIndex} index={0} />
      <Usuarios value={tabIndex} index={1} />
      <Limite value={tabIndex} index={2} />
    </Wrapper>
  );
}
