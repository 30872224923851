import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../../../../components/Modal/Modal';
import { FormControlLabel, Checkbox } from '@mui/material';
import * as S from './EditGroup.styles';
import { useDispatch } from 'react-redux';
import { listTopicCustumers, removeCustumersTopic } from '../../../../../store/fetchActions/notification';
import Button from '../../../../../components/Button/Button';
import { useTheme } from 'styled-components';
import { showAlert } from '../../../../../store/ducks/sweetAlert';

const AddClient = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [select, setSelect] = useState(false);

  const { handleSubmit } = useForm({});

  useEffect(() => {
    dispatch(
      listTopicCustumers({ LIMIT: 2147483646, PAGE: 1, ID: props.info.id }, (res) => {
        const updatedOptions = res.map((p) => {
          return {
            title: p.nome + ' - ' + p.cpfCnpj,
            CPFCNPJ: p.cpfCnpj,
            nome: p.NOME,
          };
        });

        setItems(updatedOptions);
      }),
    );
  }, []);

  const Edit = () => {
    const selectGroup = items?.filter((e) => e.selected === true);

    const array = [];
    selectGroup.map((val) => {
      array.push(val.CPFCNPJ);
      return array;
    });

    array.length > 0
      ? dispatch(removeCustumersTopic({ ID: props.info.id, USERS: array }, (props.closeModal(), props.closeInitial())))
      : dispatch(
          showAlert({
            title: 'Atenção',
            text: 'Nenhum cliente selecionado.',
            icon: 'warning',
          }),
        );
  };

  const changeCheck = (index, checked) => {
    if (select && !checked) setSelect(false);

    const itemRef = [...items];
    itemRef[index].selected = checked;
    setItems([...itemRef]);
  };

  const changeCheckAll = (checked) => {
    const itemRef = [...items];

    itemRef.map((_item, i) => {
      itemRef[i].selected = checked;
    });

    setSelect(checked);
    setItems([...itemRef]);
  };

  return (
    <Modal open={props.openModal} close={props.closeModal} width={'100vh'} title={'Remover Clientes'}>
      <>
        <form onSubmit={handleSubmit(Edit)}>
          <>
            <h4>Clientes contidos no grupo</h4>
            <S.CheckContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={select}
                    onChange={(_e, checked) => changeCheckAll(checked)}
                    sx={{
                      '&.Mui-checked': {
                        color: theme.colors.darkModerateLimeGreen,
                      },
                      '& .MuiSvgIcon-root': { fontSize: 28 },
                    }}
                  />
                }
                label="Todos"
              />
              <S.CheckGrid>
                {items?.map((item, i) => (
                  <FormControlLabel
                    key={i + '-formLabel'}
                    control={
                      <Checkbox
                        key={item.CPFCNPJ}
                        checked={item.selected === true ? true : false}
                        onChange={(_e, checked) => changeCheck(i, checked)}
                        sx={{
                          '&.Mui-checked': {
                            color: theme.colors.darkModerateLimeGreen,
                          },
                          '& .MuiSvgIcon-root': { fontSize: 28 },
                        }}
                      />
                    }
                    label={item.title}
                  />
                ))}
              </S.CheckGrid>
            </S.CheckContainer>
          </>
          <S.ConteinerBottom>
            <Button
              backgroundcolor={theme.colors.buttonConfirm}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonConfirmHover}
              hovercolor={theme.colors.buttonConfirmHoverText}
              mycolor={theme.colors.buttonConfirmText}
              type={'submit'}
            >
              <span>Salvar</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonBack}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonBackHover}
              hovercolor={theme.colors.buttonBackHoverText}
              mycolor={theme.colors.buttonBackText}
              onClick={() => props?.closeModal()}
            >
              <span>Cancelar</span>
            </Button>
          </S.ConteinerBottom>
        </form>
      </>
    </Modal>
  );
};

export default AddClient;
