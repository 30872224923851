const formatCPF = (string) => {
  try {
    if (typeof string != 'string') {
      string = string + '';
    }
    const rawNumber = string.replace('/', '').replace('-', '').replace(/\./g, '');
    const firstDigits = rawNumber.substr(0, 3);
    const secondDigits = rawNumber.substr(3, 3);
    const thirdDigits = rawNumber.substr(6, 3);
    const lastDigits = rawNumber.substr(9, 2);
    const maskedString =
      `${firstDigits}` +
      (secondDigits && `.${secondDigits}`) +
      (thirdDigits && `.${thirdDigits}`) +
      (lastDigits && `-${lastDigits}`);
    return maskedString;
  } catch (e) {
    return '';
  }
};

export default formatCPF;
