import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none" viewBox="0 0 19 22">
      <path
        fill="#fff"
        d="M14 0H2C.9 0 0 .9 0 2v14h2V2h12V0zm-1 4H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H17c1.1 0 2-.9 2-2V10l-6-6zM6 20V6h6v5h5v9H6z"
      ></path>
    </svg>
  );
}

export default Icon;
