import Modal from '../../../../components/Modal/Modal';
import * as S from './ConfirmationSend,styles';
import { useForm } from 'react-hook-form';
import Button from '../../../../components/Button/Button';
import TextField from '../../../../components/TextField';
import { NotificationAlert } from '../../../../assets/icon/index';
import { useDispatch } from 'react-redux';
import {
  sendMensagemTopic,
  sendMensagemUnique,
  sendMensagemMultiple,
} from '../../../../store/fetchActions/notification';
import { useTheme } from 'styled-components';

const ConfirmationSend = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({});

  const sendFinish = () => {
    props.Data.TOPICO && dispatch(sendMensagemTopic(props.Data, props.closeModal));
    props.Data.USUARIO && dispatch(sendMensagemUnique(props.Data, props.closeModal));
    props.Data.USUARIOS && dispatch(sendMensagemMultiple(props.Data, props.closeModal));
  };

  return (
    <Modal open={props.openModal} close={props.backClose}>
      <S.SmallerModal>
        <NotificationAlert size={'80'} />
        <h3>{'Confirmação de envio'}</h3>
        <form onSubmit={handleSubmit(sendFinish)}>
          <S.ContainerMensagem>
            <TextField
              width={'24.042vw'}
              label="Título"
              name="TITULO"
              variant="standard"
              value={props.Data?.TITULO}
              disabled
              multiline={true}
              control={control}
              color={theme.colors.black + ' !important'}
            />
            <S.SeparatorInput>
              <TextField
                width={'24.042vw'}
                label="Texto"
                name="MENSAGEM"
                variant="standard"
                value={props.Data?.MENSAGEM}
                disabled
                multiline={true}
                control={control}
                color={theme.colors.black + ' !important'}
              />
            </S.SeparatorInput>
          </S.ContainerMensagem>
          <S.ButtonContainer>
            <Button
              backgroundcolor={theme.colors.buttonBack}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.buttonBackHover}
              hovercolor={theme.colors.buttonBackHoverText}
              mycolor={theme.colors.buttonBackText}
              onClick={props.backClose}
            >
              <span>Cancelar</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type={'submit'}
            >
              <span>{'Enviar'}</span>
            </Button>
          </S.ButtonContainer>
        </form>
      </S.SmallerModal>
    </Modal>
  );
};

export default ConfirmationSend;
