import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationReversedBills: { totalResult: 0 },
  loadingReversedBills: false,
  messageReversedBills: '',
  dataReversedBills: [],
  errorReversedBills: false,
};

export const reversedBillsActionLoading = createAction('REVERSED_BILLS_ACTION_LOADING');
export const reversedBillsActionSuccess = createAction('REVERSED_BILLS_ACTION_SUCCESS');
export const reversedBillsActionError = createAction('REVERSED_BILLS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [reversedBillsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingReversedBills: true,
      errorReversedBills: false,
    };
  },
  [reversedBillsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingReversedBills: false,
      errorReversedBills: false,
      messageReversedBills: payload?.mensagem,
      paginationReversedBills: payload?.data?.pagination,
      dataReversedBills: payload?.data,
    };
  },
  [reversedBillsActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingReversedBills: false,
      errorReversedBills: true,
      messageReversedBills: payload?.mensagem,
      paginationReversedBills: {},
      dataReversedBills: [],
    };
  },
});

