import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  approvedUsersData: [],
  paginationApprovedUsers: { totalResult: 0 },
  errorMessageApprovedUsers: '',
};

export const approvedUsersActionLoading = createAction('APPROVED_USERS_ACTION_LOADING');
export const approvedUsersActionSuccess = createAction('APPROVED_USERS_ACTION_SUCCESS');
export const approvedUsersActionError = createAction('APPROVED_USERS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [approvedUsersActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingApprovedUsers: true,
      errorApprovedUsers: false,
      approvedUsersData: [],
      paginationApprovedUsers: {},
    };
  },
  [approvedUsersActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApprovedUsers: false,
      errorApprovedUsers: false,
      approvedUsersData: payload.data,
      paginationApprovedUsers: payload.pagination,
    };
  },
  [approvedUsersActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApprovedUsers: false,
      errorApprovedUsers: true,
      errorMessageApprovedUsers: payload?.mensagem,
      approvedUsersData: [],
      paginationApprovedUsers: {},
    };
  },
});
