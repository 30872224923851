import React, { useState, useEffect } from 'react';
import * as Styled from '../Dashboard.styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Card from '../../../components/Card/Card';
import { dailyMovementsFetchAction } from '../../../store/fetchActions/dailyMovementsActions';
import { useDispatch, useSelector } from 'react-redux';
import SaldoContaDigital from '../../../assets/icon/SaldoContaDigital';
import { useLocation } from 'react-router-dom';
import { getSaldoAdminFetchAction } from '../../../store/fetchActions/getSaldoAdmin';
import { useTheme } from 'styled-components';

export default function CardContainer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    dispatch(dailyMovementsFetchAction({ LIMIT: 10, PAGE: 1 }));
    dispatch(getSaldoAdminFetchAction());
  }, [dispatch, location]);

  const extendedHeight = '29vw';
  const contractedHeight = '11vw';

  const { dataSaldoGeral } = useSelector((state) => state.saldoGeral);
  const { dailyMovements } = useSelector((state) => state.dailyMovements);

  return (
    <Styled.CardContainer>
      <Styled.CardElement>
        <Card
          icon={<SaldoContaDigital />}
          iconColor={theme.colors.iconCardContaDigital}
          backgroundColorContainer={theme.colors.iconCardContaDigitalContainer}
          expandedHeight={extendedHeight}
          height={contractedHeight}
          name="Conta Digital"
          text="Saldo do dia"
          value={dataSaldoGeral}
          isExpanded={isExpanded}
          setIsExpanded={(e) => setIsExpanded(e)}
          color={theme.colors.iconCardContaDigitalBorder}
        />
      </Styled.CardElement>
      <Styled.CardElement>
        <Card
          icon={<ArrowDownwardIcon />}
          iconColor={theme.colors.iconCardCredito}
          backgroundColorContainer={theme.colors.iconCardCreditoContainer}
          expandedHeight={extendedHeight}
          height={contractedHeight}
          name="Crédito"
          text="Valor total"
          value={dailyMovements?.totalCredito}
          isExpanded={isExpanded}
          setIsExpanded={(e) => setIsExpanded(e)}
          data={[
            {
              name: 'Empréstimos',
              value: dailyMovements?.totalEmprestimo,
            },
            {
              name: 'Crédito Avulso',
              value: dailyMovements?.totalAvulsoCredito,
            },
            {
              name: 'Interna',
              value: dailyMovements?.totalTransacaoInternaCredito,
            },
            {
              name: 'TED',
              value: dailyMovements?.totalCreditoTed,
            },
            {
              name: 'PIX',
              value: dailyMovements?.totalAvulsoCredito,
            },
          ]}
          color={theme.colors.iconCardCreditoBorder}
        />
      </Styled.CardElement>
      <Styled.CardElement>
        <Card
          icon={<ArrowUpwardIcon />}
          iconColor={theme.colors.iconCardDebito}
          backgroundColorContainer={theme.colors.iconCardDebitoContainer}
          expandedHeight={extendedHeight}
          height={contractedHeight}
          name="Débito"
          text="Valor total"
          value={dailyMovements?.totalDebito}
          isExpanded={isExpanded}
          setIsExpanded={(e) => setIsExpanded(e)}
          data={[
            {
              name: 'Pagamentos',
              value: dailyMovements?.totalPagamento,
            },
            {
              name: 'Débito Avulso',
              value: dailyMovements?.totalAvulsoDebito,
            },
            {
              name: 'Tarifa',
              value: dailyMovements?.totalTarifas,
            },
            {
              name: 'Recarga de Celular',
              value: dailyMovements?.totalRecargas,
            },
            {
              name: 'Interna',
              value: dailyMovements?.totalTransacaoInternaDebito,
            },

            {
              name: 'TED',
              value: dailyMovements?.totalDebitoTed,
            },
            {
              name: 'PIX',
              value: dailyMovements?.totalAvulsoDebito,
            },
          ]}
          color={theme.colors.iconCardDebitoBorder}
        />
      </Styled.CardElement>
    </Styled.CardContainer>
  );
}
