import React from 'react';
import * as Styled from './Wrapper.styles';
import { Typography } from '@material-ui/core';
export default function Wrapper({ title, children, margin }) {
  return (
    <Styled.Container>
      <Styled.Header margin={margin}>
        <Typography variant="h4">{title} </Typography>
      </Styled.Header>
      {children}
    </Styled.Container>
  );
}
