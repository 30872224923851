import InformationPix from './components/InformationPix';
import InformationTed from './components/InformationTed';
import InformationInterna from './components/InformationInterna';

export const InformationTransfer = (params) => {
  const { data } = params;

  switch (data?.TIPO) {
    case 'Pix':
      return <InformationPix data={data} />;
    case 'Ted':
      return <InformationTed data={data} />;
    case 'Interna':
      return <InformationInterna data={data} />;
    default:
      return '';
  }
};

export default InformationTransfer;
