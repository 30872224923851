import React, { useState, useEffect } from 'react';
import Table from '../../../components/Table/Table';
import { listMensagens } from '../../../store/fetchActions/notification';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../components/Filter/Filter';
import TextField from '../../../components/TextField';
import DateInput from '../../../components/DateInput';
import Button from '../../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import { useForm } from 'react-hook-form';
import { SecondFilterLine } from '../../../components/Filter/Filter.styles';
import SelectOptions from '../../../components/SelectOptions';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import { FirstFilterLine } from './ViewNotification.styles.jsx';
import * as dayjs from 'dayjs';
import Modal from './modal/DetailMensagem';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { pagesOptions } from '../../../utils/constant';
import Loading from '../../../components/Loading';

const ViewNotigication = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [idConta, setIdConta] = useState('');
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control, reset } = useForm({});

  const { listMensagem, paginationListMensagem, loadingListMensagem, errorListMensagem, errorMenssagemList } =
    useSelector((state) => state.notification);

  useCallFilter(filterData, setFilter, listMensagens);

  useEffect(() => {
    setFilterData('');
    reset({ SEARCH: '', DATA_INICIAL: '', DATA_FINAL: '', LIMIT: 10, PAGE: 1 });
  }, []);

  useEffect(() => {
    dispatch(listMensagens(filter));
  }, [filter.PAGE]);

  const header = [
    {
      id: 'data de registro',
      name: 'DATA DE REGISTRO',
      align: 'left',
    },
    {
      id: 'TITULO',
      name: 'TITULO',
      align: 'left',
    },
    {
      id: 'TEXTO',
      name: 'TEXTO',
      align: 'left',
    },
  ];

  const body = listMensagem?.map((val) => {
    return {
      data: [
        {
          info: dayjs(val.data).format('DD/MM/YYYY HH:mm:ss') || '-----',
          align: 'left',
        },
        {
          info: val.titulo,
          align: 'left',
        },
        {
          info: val.mensagem,
          align: 'left',
        },
      ],
      onClick: () => inspectUser(val),
    };
  });

  function inspectUser(id) {
    setIsModalInfoOpen(true);
    setIdConta(id);
  }

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  return (
    <div>
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Título" name={'SEARCH'} width={'28.042vw'} margin={'0 0 0 1.369vw'} control={control} />
            <DateInput
              label="Data Inicial"
              name={'DATA_INICIAL'}
              control={control}
              value={dateInicial}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <DateInput
              label="Data Final"
              name={'DATA_FINAL'}
              control={control}
              value={dateFinal}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
              }
            />

            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>

          <SecondFilterLine>
            <h5>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {paginationListMensagem?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>

      {loadingListMensagem ? (
        <Loading />
      ) : body ? (
        <Table
          loading={loadingListMensagem}
          error={errorListMensagem}
          hasPagination={true}
          filter={filter}
          pagination={paginationListMensagem}
          setFilter={setFilter}
          errorMessage={errorMenssagemList}
          filterTable={filterTable}
          header={header}
          body={body}
        />
      ) : (
        <Table
          loading={false}
          error={false}
          hasPagination={true}
          filter={filter}
          pagination={paginationListMensagem}
          setFilter={setFilter}
          errorMessage={errorMenssagemList}
          filterTable={filterTable}
          header={header}
          body={[]}
        />
      )}

      {isModalInfoOpen && (
        <Modal openModal={isModalInfoOpen} closeModal={(e) => setIsModalInfoOpen(false)} info={idConta} />
      )}
    </div>
  );
};

export default ViewNotigication;
