import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPayments: [],
  errorPayments: false,
  errorMessagePayments: '',
  loadingPayments: true,
  paginationPayments: { totalResult: 0 },
};

export const paymentsActionLoading = createAction('PAYMENTS_ACTION_LOADING');
export const paymentsActionSuccess = createAction('PAYMENTS_ACTION_SUCCESS');
export const paymentsActionError = createAction('PAYMENTS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [paymentsActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingPayments: true,
      errorPayments: false,
      paginationPayments: {},
    };
  },
  [paymentsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPayments: false,
      errorPayments: false,
      dataPayments: payload.data,
      paginationPayments: payload.pagination,
    };
  },
  [paymentsActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPayments: false,
      errorPayments: true,
      errorMessagePayments: payload?.mensagem,
      payments: [],
      paginationPayments: {},
    };
  },
});
