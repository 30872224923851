import React, { useState, useEffect } from 'react';
import * as Styled from './Accounts.styles';
import WhiteStatus from '../../../../components/StatusContainer/WhiteStatusContainer';
import formatMoney from '../../../../utils/functions/formatMoneyBRL';
import { useSelector, useDispatch } from 'react-redux';
import { balanceAccountsFetchAction } from '../../../../store/fetchActions/accountActions';
import { useTheme } from 'styled-components';

const Accounts = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [saldo, setSaldo] = useState([]);

  const { CONTAS } = useSelector((state) => state.user.usersData);

  CONTAS?.forEach((data, index) => {
    useEffect(() => {
      dispatch(
        balanceAccountsFetchAction(data.ID_CONTA, (res) => {
          setSaldo((stat) => [
            ...stat,
            {
              SALDO: res?.saldo,
              ...data,
            },
          ]);
        }),
      );
    }, [index]);
  });

  return (
    <Styled.ContainerStyled>
      <Styled.TitleStyled>Contas do cliente</Styled.TitleStyled>
      <Styled.Account>
        {saldo?.map((data, index) => {
          return (
            <Styled.RowContainer key={index} role={'information-row'}>
              <Styled.Row>
                <Styled.ParagraphName>Nº da conta</Styled.ParagraphName>
                <Styled.ParagraphValue color={theme.colors.gray} bold={true}>
                  {data?.CONTA}
                </Styled.ParagraphValue>
              </Styled.Row>
              <Styled.Row>
                <Styled.ParagraphName>Saldo</Styled.ParagraphName>
                <Styled.ParagraphValue color={theme.colors.gray} bold={true}>
                  {'R$ ' + formatMoney(data?.SALDO)}
                </Styled.ParagraphValue>
              </Styled.Row>
              <Styled.Row>
                <Styled.ParagraphName>Status</Styled.ParagraphName>
                <Styled.ParagraphValue color={theme.colors.gray} bold={true}>
                  <WhiteStatus status={data?.STATUS} />
                </Styled.ParagraphValue>
              </Styled.Row>
            </Styled.RowContainer>
          );
        })}
      </Styled.Account>
    </Styled.ContainerStyled>
  );
};

export default Accounts;
