import React, { useState, useEffect } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Checkbox } from '@mui/material';
import * as S from '../CreatGroup.styles';
import Filter from '../../../../../components/Filter/Filter';
import Button from '../../../../../components/Button/Button';
import SelectOptions from '../../../../../components/SelectOptions';
import { useTheme } from 'styled-components';
import { ImSearch } from 'react-icons/im';
import TextField from '../../../../../components/TextField';
import {
  addCustumersTopic,
  listTopicCustumers,
  searchTopicSendMensagem,
} from '../../../../../store/fetchActions/notification';
import DateInput from '../../../../../components/DateInput';
import moment from 'moment';

const status = [
  { id: '', nome: 'Todos' },
  { id: '1', nome: 'È PDV' },
  { id: '0', nome: 'Não PDV' },
];

const AddClient = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [select, setSelect] = useState(false);
  const [mensagemSearch, setMensagemSearch] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA: '',
    LIMIT: 2147483646,
    PAGE: 1,
    IS_PDV: '',
  });
  const [dateInicial, setDateInicial] = useState(null);

  const { handleSubmit, control } = useForm({});
  const { handleSubmit: groupSubmit, control: groupControl } = useForm({});

  useEffect(() => {
    dispatch(listTopicCustumers({ PAGE: 1, LIMIT: 2147483646, ID: props?.info?.id }));
  }, []);

  const { ListTopicCustumersData } = useSelector((event) => event?.notification);

  const searchTopicP = (filter) => {
    dispatch(
      searchTopicSendMensagem(filter, (res) => {
        const updatedOptions = res?.filter((val) => {
          return ListTopicCustumersData.every((data) => {
            return data.cpfcnpj !== val.CPFCNPJ && data.nome !== val.NOME;
          });
        });

        const updated = updatedOptions.map((p) => {
          return {
            title: p.NOME + ' - ' + p.CPFCNPJ,
            CPFCNPJ: p.CPFCNPJ,
            NOME: p.NOME,
          };
        });

        setItems(updated);
        setMensagemSearch(true);
      }),
    );
  };

  const changeCheck = (index, checked) => {
    if (select && !checked) setSelect(false);

    const itemRef = [...items];
    itemRef[index].selected = checked;
    setItems([...itemRef]);
  };

  const changeCheckAll = (checked) => {
    const itemRef = [...items];

    itemRef.map((_item, i) => {
      itemRef[i].selected = checked;
    });

    setSelect(checked);
    setItems([...itemRef]);
  };

  const saveGroup = () => {
    const selectGroup = items.filter((e) => e.selected === true);

    const array = [];
    selectGroup.map((val) => {
      array.push(val.CPFCNPJ);
      return array;
    });

    dispatch(
      addCustumersTopic(
        {
          TOPICO: { ID: props.info.id, TITULO: props.info.nome, DESCRICAO: props.info.descricao },
          USERS: array,
        },
        (props.closeModal, props.closeInitial),
      ),
    );
  };

  const validateGroup = () => {
    const selectGroup = items.filter((e) => e.selected === true);
    return selectGroup.length > 0 ? false : true;
  };

  const filterAddClient = (data) => {
    data.DATA = dateInicial ?? '';
    setFilter({ ...data, LIMIT: 2147483646, PAGE: 1 });
    searchTopicP({ ...data, LIMIT: 2147483646, PAGE: 1 });
  };

  return (
    <Modal open={props.openModal} close={props.closeModal} width={'800vh'} title={'Adicionar Clientes'}>
      <Filter>
        <S.FormSearch onSubmit={handleSubmit(filterAddClient)}>
          <S.InputsContainer>
            <TextField label="Título" name={'SEARCH'} width={'28.042vw'} margin={'0 0 0 1.369vw'} control={control} />
            <DateInput
              label="Data Nascimento"
              name={'DATA'}
              control={control}
              value={dateInicial}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <SelectOptions
              label={'PDV'}
              width={'9.528vw'}
              name={'IS_PDV'}
              control={control}
              options={status}
              initialValue={''}
            />

            <S.ButtonSearch>
              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width="12.153vw"
                margin={'0 1.389vw 0 0'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </S.ButtonSearch>
          </S.InputsContainer>
        </S.FormSearch>
      </Filter>
      <S.myHr />
      <form onSubmit={groupSubmit(saveGroup)}>
        <div>
          {items.length > 0 ? (
            <>
              <S.CheckContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={select}
                      onChange={(_e, checked) => changeCheckAll(checked)}
                      sx={{
                        '&.Mui-checked': {
                          color: theme.colors.darkModerateLimeGreen,
                        },
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                      }}
                    />
                  }
                  label="Todos"
                />
                <S.CheckGrid>
                  {items?.map((item, i) => (
                    <FormControlLabel
                      key={i + '-formLabel'}
                      control={
                        <Checkbox
                          key={item.CPFCNPJ}
                          checked={item.selected === true ? true : false}
                          onChange={(_e, checked) => changeCheck(i, checked)}
                          sx={{
                            '&.Mui-checked': {
                              color: theme.colors.darkModerateLimeGreen,
                            },
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                          }}
                        />
                      }
                      label={item.title}
                    />
                  ))}
                </S.CheckGrid>
              </S.CheckContainer>
              <hr />
              <S.ConteinerBottom>
                <Button
                  backgroundcolor={theme.colors.buttonConfirm}
                  width="12.153vw"
                  margin={'0 1.389vw 0 0'}
                  hoverbgcolor={theme.colors.buttonConfirmHover}
                  hovercolor={theme.colors.buttonConfirmHoverText}
                  mycolor={theme.colors.buttonConfirmText}
                  type="submit"
                  disabled={validateGroup()}
                >
                  <span>Salvar</span>
                </Button>
                <Button
                  backgroundcolor={theme.colors.buttonBack}
                  width="12.153vw"
                  margin={'0 1.389vw 0 0'}
                  hoverbgcolor={theme.colors.buttonBackHover}
                  hovercolor={theme.colors.buttonBackHoverText}
                  mycolor={theme.colors.buttonBackText}
                  type="submit"
                  onClick={() => {
                    props?.closeModal();
                  }}
                >
                  <span>Cancelar</span>
                </Button>
              </S.ConteinerBottom>
            </>
          ) : (
            <S.CheckContainer>
              {mensagemSearch ? (
                <S.Menssagen>Não houve resultados nesta busca</S.Menssagen>
              ) : (
                <S.Menssagen>Faça sua busca de clientes</S.Menssagen>
              )}
            </S.CheckContainer>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default AddClient;
