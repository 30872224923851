import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Logo from '../../assets/Images/Mon/Logo.svg';
import Button from '../../components/Button/Button';
import { authenticateFetchAction, loginFetchAction } from '../../store/fetchActions/loginActions';
import Modal from '../../components/Modal/Modal';
import React, { useState, useRef } from 'react';
import * as Styled from './Login.styles';
import { Typography } from '@material-ui/core';
import TextInputMaskCpfCnpj from '../../components/TextInputMaskCpfCnpj/TextInputMaskCpfCnpj';
import ReactInputVerificationCode from 'react-input-verification-code';
import InputMask from '../../components/InputMask';
import TextField from '../../components/TextField';
import { useTheme } from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { showAlert } from '../../store/ducks/sweetAlert';
import { useNavigate } from 'react-router';

function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalTokenOpen, setIsModalTokenOpen] = useState(false);
  const [token, setToken] = useState('');
  const [fone, setFone] = useState('');
  const [loginData, setLoginData] = useState({
    CFPCNPJ: '',
    FONECEL: '',
    SENHA: '',
    RESPONSE: '',
  });

  const captchaRef = useRef(null);

  const { handleSubmit: handleLogin, control: loginControl } = useForm({});
  const { handleSubmit: handleToken, control: tokenControl } = useForm({});

  const loginUser = (data) => {
    data.FONECEL = fone;
    const token = captchaRef?.current?.getValue();
    captchaRef?.current?.reset();
    token
      ? (setLoginData(data), dispatch(authenticateFetchAction({ ...data, RESPONSE: token }, openModalToken)))
      : dispatch(
          showAlert({
            title: 'Ops...',
            text: 'É necessário completar o captcha',
            icon: 'error',
          }),
        );
  };

  const sendToken = () => {
    dispatch(loginFetchAction({ ...loginData, TOKEN: token }, navigate));
  };

  const closeModalToken = () => {
    setIsModalTokenOpen(false), setToken('');
  };
  const openModalToken = () => setIsModalTokenOpen(true);

  return (
    <>
      <Modal open={isModalTokenOpen} close={closeModalToken} width={'38.889vw'}>
        <Styled.ModalContainer>
          <Styled.ImageContainer width="13.667vw" margin="0 0 2vw 0">
            <img src={Logo} alt="Logo"></img>
          </Styled.ImageContainer>
          <Styled.LoginInfoToken>
            <Typography variant="h4">Token</Typography>
            <Typography variant="h6">Digite o código enviado para o seu telefone</Typography>
          </Styled.LoginInfoToken>
          <div>
            <form onSubmit={handleToken(sendToken)}>
              <Styled.TokenModalContainer>
                <Styled.StyledReactInputVerificationCode>
                  <ReactInputVerificationCode
                    value={token}
                    placeholder={null}
                    length={6}
                    autoFocus
                    onChange={(newValue) => {
                      setToken(newValue);
                    }}
                    name={'TOKEN'}
                    control={tokenControl}
                  />
                </Styled.StyledReactInputVerificationCode>
                <Typography align="center">
                  Não recebeu o Código?
                  <Styled.ResendLink
                    onClick={() => {
                      dispatch(authenticateFetchAction(loginData, openModalToken));
                    }}
                  >
                    Reenviar
                  </Styled.ResendLink>
                </Typography>
                <Typography align="center"></Typography>
                <Button
                  backgroundcolor={theme.colors.buttonConfirm}
                  width="31.250vw"
                  hoverbgcolor={theme.colors.buttonConfirmHover}
                  hovercolor={theme.colors.buttonConfirmHoverText}
                  mycolor={theme.colors.buttonConfirmText}
                  type="submit"
                  margin={'5vw 0 2.847vw 0'}
                >
                  CONFIRMAR
                </Button>
              </Styled.TokenModalContainer>
            </form>
          </div>
        </Styled.ModalContainer>
      </Modal>

      <Styled.Container>
        <Styled.BackgroundImage></Styled.BackgroundImage>
        <Styled.LoginContainer>
          <Styled.ImageContainer width="16.667vw" margin="0.889vw 0 3.833vw 0">
            <img className="imageLogo" src={Logo} loading="eager" alt="Logo"></img>
          </Styled.ImageContainer>
          <Styled.LoginInfo>
            <Typography variant="h4">Entrar</Typography>
            <Typography variant="h6">Informe os dados para acessar a sua conta</Typography>
          </Styled.LoginInfo>
          <form onSubmit={handleLogin(loginUser)}>
            <Styled.InputsContainer>
              <Styled.SectionInputs>
                <TextInputMaskCpfCnpj
                  label="CPF"
                  name={'CPFCNPJ'}
                  width={'31.250vw'}
                  control={loginControl}
                  required={true}
                  inputProps={{ role: 'cpf/cnpj-input', maxLength: '14' }}
                  variant="standard"
                  spacelabelinput={'1vw'}
                />
              </Styled.SectionInputs>
              <Styled.SectionInputs>
                <InputMask
                  label="Telefone"
                  name={'FONECEL'}
                  width={'31.250vw'}
                  control={loginControl}
                  required={true}
                  inputProps={{ maxLength: 15 }}
                  mask={'(99) 99999-9999'}
                  spacelabelinput={'1vw'}
                  onChange={(e) => setFone(e)}
                />
              </Styled.SectionInputs>
              <Styled.SectionInputs>
                <TextField
                  label="Senha"
                  name={'SENHA'}
                  width={'31.250vw'}
                  control={loginControl}
                  required={true}
                  type={'password'}
                  spacelabelinput={'1vw'}
                />
              </Styled.SectionInputs>
              <Styled.StyledRecaptcha>
                <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_SECRET_KEY} />
              </Styled.StyledRecaptcha>

              <Button
                backgroundcolor={theme.colors.buttonConfirm}
                width="31.250vw"
                borderRadius="10px"
                height="2.9282576866764276vw"
                hoverbgcolor={theme.colors.buttonConfirmHover}
                hovercolor={theme.colors.buttonConfirmHoverText}
                mycolor={theme.colors.buttonConfirmText}
                type="submit"
                margin={'1vw 0'}
              >
                Entrar
              </Button>
            </Styled.InputsContainer>
          </form>
        </Styled.LoginContainer>
      </Styled.Container>
    </>
  );
}

export default Login;
