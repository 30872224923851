import React, { useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import Tabs from '../../components/Tabs/Tabs';
import TransacoesBoletos from './components/TabContent/TransacoesBoletos';
import BoletosEstornados from './components/TabContent/BoletosEstornados';

export default function GerenciarBoletos() {
  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Boletos', 'Boletos Estornados'];

  return (
    <Wrapper title="Gerenciar Boletos">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" />
      {tabIndex === 0 && <TransacoesBoletos value={tabIndex} index={0} />}
      {tabIndex === 1 && <BoletosEstornados value={tabIndex} index={1} />}
    </Wrapper>
  );
}
