import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loadingFaceMatch: false,
  dataFaceMatch: [],
  paginationFaceMatchOcrRelatorio: {},
};

export const faceMatchLoading = createAction('FACEMATCH_LOADING');
export const faceMatchSuccess = createAction('FACEMATCH_SUCCESS');
export const faceMatchError = createAction('FACEMATCH_ERROR');
export const faceMatchReset = createAction('FACEMATCH_RESET');
export const faceMatchOcrRelatorioLoading = createAction('FACEMATCH_OCR_RELATORIO_LOADING');
export const faceMatchOcrRelatorioSuccess = createAction('FACEMATCH_OCR_RELATORIO_SUCCESS');
export const faceMatchOcrRelatorioError = createAction('FACEMATCH_OCR_RELATORIO_ERROR');
export const faceMatchOcrRelatorioDetalheLoading = createAction('FACEMATCH_OCR_RELATORIO_DETALHE_LOADING');
export const faceMatchOcrRelatorioDetalheSuccess = createAction('FACEMATCH_OCR_RELATORIO_DETALHE_SUCCESS');
export const faceMatchOcrRelatorioDetalheError = createAction('FACEMATCH_OCR_RELATORIO_DETALHE_ERROR');

export default createReducer(INITIAL_STATE, {
  [faceMatchLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingFaceMatch: true,
      error: false,
    };
  },
  [faceMatchSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingFaceMatch: false,
      dataFaceMatch: [payload?.resposta],
      error: false,
    };
  },
  [faceMatchError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingFaceMatch: false,
      error: true,
      errorMenssagem: payload?.mensagem,
    };
  },
  [faceMatchReset.type]: (state, { payload }) => {
    return {
      ...state,
      loadingFaceMatch: false,
      dataFaceMatch: [],
    };
  },
  [faceMatchOcrRelatorioLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingFaceMatchOcrRelatorio: true,
      errorFaceMatchOcrRelatorio: false,
    };
  },
  [faceMatchOcrRelatorioSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingFaceMatchOcrRelatorio: false,
      dataFaceMatchOcrRelatorio: payload.data,
      paginationFaceMatchOcrRelatorio: payload.pagination,
      errorFaceMatchOcrRelatorio: false,
    };
  },
  [faceMatchOcrRelatorioError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingFaceMatchOcrRelatorio: false,
      errorFaceMatchOcrRelatorio: true,
      errorMenssagemFaceMatchOcrRelatorio: payload,
    };
  },
  [faceMatchOcrRelatorioDetalheLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingFaceMatchOcrRelatorioDetalhe: true,
      errorFaceMatchOcrRelatorioDetalhe: false,
    };
  },
  [faceMatchOcrRelatorioDetalheSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingFaceMatchOcrRelatorioDetalhe: false,
      dataFaceMatchOcrRelatorioDetalhe: payload,
      errorFaceMatchOcrRelatorioDetalhe: false,
    };
  },
  [faceMatchOcrRelatorioDetalheError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingFaceMatchOcrRelatorioDetalhe: false,
      errorFaceMatchOcrRelatorioDetalhe: true,
      dataFaceMatchOcrRelatorioDetalhe: [],
      errorMenssagemFaceMatchOcrRelatorioDetalhe: payload?.mensagem,
    };
  },
});
