import styled, { css } from 'styled-components';
import Divider from '@material-ui/core/Divider';

export const ContainerStyled = styled.div`
  padding-bottom: 4rem;
  width: 75%;
`;

export const TitleStyled = styled.span`
  margin-top: ${(props) => props.marginTop ?? '0.764vw'};
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  font-size: 1.389vw;
  line-height: 2.083vw;
`;

export const Bar = styled(Divider)`
  width: 100%;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
`;

export const ParagraphName = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  font-size: 0.9vw;
  line-height: 1.667vw;
  color: ${(props) => props.color ?? props.theme.colors.darkGrayishBlue};
  height: 1.667vw;
`;

export const ParagraphValue = styled(ParagraphName)`
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: ${(props) => props.color ?? props.theme.colors.veryDark_2};
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
`;

export const RowContainer = styled.div`
  padding-bottom: 0.694vw;
  width: ${(props) => props.width ?? '100%'};
  color: ${(props) => props.theme.colors.veryDark_2};

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 15px;

  .rowGrid1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .rowGrid2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .rowGrid3 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .rowGrid4 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .rowGrid5 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .rowGrid6 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .rowGrid7 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .rowGrid8 {
    grid-area: 3 / 3 / 4 / 4;
  }
  .rowGrid9 {
    grid-area: 4 / 1 / 5 / 4;
  }
  .rowGrid10 {
    grid-area: 2 / 3 / 2 / 4;
  }
  .rowGrid11 {
    grid-area: 2 / 4 / 3 / 5;
  }
`;

export const Taxx = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0.764vw;
  &:last-child {
    border: none;
  }
`;

export const SecondContainer = styled.div`
  margin-top: 2rem;
`;

export const PrimaryContainer = styled.div``;

export const Section = styled.hr`
  padding: 0;
`;
