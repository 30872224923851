import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  perfisData: [],
  pagination: { totalResult: 0 },
  errorMessage: '',

  profilesUsers: [],
  pagination: { totalResult: 0 },
  loadingProfiles: false,
  errorProfiles: false,
  errorMessageProfiles: '',
};

export const perfisActionLoading = createAction('PERFIS_ACTION_LOADING');
export const perfisActionSuccess = createAction('PERFIS_ACTION_SUCCESS');
export const perfisActionError = createAction('PERFIS_ACTION_ERROR');
export const deletePerfisActionLoading = createAction('DELETE_PERFIS_ACTION_LOADING');
export const deletePerfisActionSuccess = createAction('DELETE_PERFIS_ACTION_SUCCESS');
export const deletePerfisActionError = createAction('DELETE_PERFIS_ACTION_ERROR');
export const editPerfisActionLoading = createAction('EDIT_PERFIS_ACTION_LOADING');
export const editPerfisActionSuccess = createAction('EDIT_PERFIS_ACTION_SUCCESS');
export const editPerfisActionError = createAction('EDIT_PERFIS_ACTION_ERROR');

export const createPerfisActionLoading = createAction('CREATE_PERFIS_ACTION_LOADING');
export const createPerfisActionSuccess = createAction('CREATE_PERFIS_ACTION_SUCCESS');
export const createPerfisActionError = createAction('CREATE_PERFIS_ACTION_ERROR');
export const listProfilesUsersActionsLoading = createAction('LIST_PROFILES_USERS_ACTION_LOADING');
export const listProfilesUsersActionsSuccess = createAction('LIST_PROFILES_USERS_ACTION_SUCCESS');
export const listProfilesUsersActionsError = createAction('LIST_PROFILES_USERS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [perfisActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingPerfis: true,
      error: false,
      perfisData: [],
    };
  },
  [perfisActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPerfis: false,
      error: false,
      perfisData: payload?.perfis,
    };
  },
  [perfisActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPerfis: false,
      error: true,
      errorMessage: payload?.mensagem,
      perfisData: [],
    };
  },
  [deletePerfisActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingDeletePerfis: true,
      error: false,
    };
  },
  [deletePerfisActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeletePerfis: false,
      error: false,
    };
  },
  [deletePerfisActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeletePerfis: false,
      error: true,
      errorMessage: payload?.mensagem,
    };
  },
  [editPerfisActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingEditPerfis: true,
      error: false,
    };
  },
  [editPerfisActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingEditPerfis: false,
      error: false,
    };
  },
  [editPerfisActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingEditPerfis: false,
      error: true,
      errorMessage: payload?.mensagem,
    };
  },
  [createPerfisActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingCreatePerfis: true,
      error: false,
    };
  },
  [createPerfisActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingCreatePerfis: false,
      error: false,
    };
  },
  [createPerfisActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingCreatePerfis: false,
      error: true,
      errorMessage: payload?.mensagem,
    };
  },
  [listProfilesUsersActionsLoading.type]: (state) => {
    return {
      ...state,
      loadingProfiles: true,
      profilesUsers: [],
      errorProfiles: false,
      errorMessageProfiles: '',
    };
  },
  [listProfilesUsersActionsSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingProfiles: false,
      profilesUsers: payload?.data,
      errorProfiles: false,
      errorMessageProfiles: '',
    };
  },
  [listProfilesUsersActionsError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingProfiles: false,
      profilesUsers: [],
      errorProfiles: true,
      errorMessageProfiles: payload?.mensagem,
    };
  },
});
