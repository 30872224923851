import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

const fontPendingUsers =
  'font-family: Poppins; font-style: normal; font-weight: 400; font-size: 1.111vw; line-height: 1.667vw;';

export const ModalStyled = styled(Dialog)`
  .MuiDialog-paper {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-radius: 1.042vw;
  }
  .MuiDialog-paperWidthSm {
    max-width: none;
  }
  h5 {
    font-family: Poppins;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.667vw;
    letter-spacing: 0em;
    text-align: center;
  }
  .smallerModal {
    background: ${(props) => props.theme.colors.vividRed_2} !important;
    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          display: flex;
        }
      }
    }
  }
`;

export const IconStyled = styled(IconButton)`
  .MuiIconButton-root {
    top: 3.472vw !important;
  }
`;

export const TitleStyled = styled.span`
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  font-size: 1.389vw;
  line-height: 2.083vw;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubtitleStyled = styled.p`
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  font-size: 1.111vw;

  line-height: 1.667vw;
  margin-left: 0;
`;

export const ParagraphName = styled.p`
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  font-size: 1.111vw;
  line-height: 1.667vw;
  color: ${(props) => props.theme.colors.veryDark_2};
  margin-top: 0.764vw;
  height: 1.667vw;
`;

export const ParagraphValue = styled(ParagraphName)`
  font-weight: 400;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
`;

export const Bar = styled(Divider)`
  width: 35.111vw;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.694vw;
  width: 35.111vw;
`;

export const DivideContainer = styled(Bar)`
  width: 35.111vw !important;
  margin-top: 1.042vw;
`;

export const ParagraphInviteUser = styled.p`
  ${fontPendingUsers};
  margin-top: 1.042vw;
`;
