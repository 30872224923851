import React from 'react';
//
function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" fill="none" viewBox="0 0 22 12">
      <path
        fill="#000"
        d="M2 10h18c.55 0 1 .45 1 1s-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1zm-.5-4.43c.36.21.82.08 1.03-.28L3 4.47l.48.83c.21.36.67.48 1.03.28.36-.21.48-.66.28-1.02l-.49-.84h.95c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H4.3l.47-.82c.21-.36.09-.82-.27-1.03a.764.764 0 00-1.03.28L3 1.47 2.53.65A.764.764 0 001.5.37c-.36.21-.48.67-.27 1.03l.47.82H.75c-.41 0-.75.34-.75.75s.34.75.75.75h.95l-.48.83c-.2.36-.08.82.28 1.02zm8 0c.36.21.82.08 1.03-.28l.47-.82.48.83c.21.36.67.48 1.03.28.36-.21.48-.66.28-1.02l-.48-.83h.95c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-.96l.47-.82A.76.76 0 0012.5.38a.746.746 0 00-1.02.27l-.48.82-.47-.82A.742.742 0 009.51.38c-.36.21-.48.67-.27 1.03l.47.82h-.96a.74.74 0 00-.75.74c0 .41.34.75.75.75h.95l-.48.83c-.2.36-.08.82.28 1.02zM22 2.97c0-.41-.34-.75-.75-.75h-.95l.47-.82A.76.76 0 0020.5.37a.746.746 0 00-1.02.27l-.48.83-.47-.82a.742.742 0 00-1.02-.27c-.36.21-.48.67-.27 1.03l.47.82h-.95a.743.743 0 00-.76.74c0 .41.34.75.75.75h.95l-.48.83a.74.74 0 00.28 1.02c.36.21.82.08 1.03-.28l.47-.82.48.83c.21.36.67.48 1.03.28.36-.21.48-.66.28-1.02l-.48-.83h.95c.4-.01.74-.35.74-.76z"
      ></path>
    </svg>
  );
}

export default Icon;
