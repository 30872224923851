import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {};

export const limitPaymentActionLoading = createAction('LIMIT_PAYMENT_ACTION_LOADING');
export const limitPaymentActionSuccess = createAction('LIMIT_PAYMENT_ACTION_SUCCESS');
export const limitPaymentActionError = createAction('LIMIT_PAYMENT_ACTION_ERROR');
export const editLimitPaymentLoading = createAction('EDIT_LIMIT_PAYMENT_LOADING');
export const editLimitPaymentSuccess = createAction('EDIT_LIMIT_PAYMENT_SUCCESS');
export const editLimitPaymentError = createAction('EDIT_LIMIT_PAYMENT_ERROR');

export default createReducer(INITIAL_STATE, {
  [limitPaymentActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingLimitPayment: true,
      errorLimitPayment: false,
      limitPaymentData: [],
    };
  },
  [limitPaymentActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLimitPayment: false,
      errorLimitPayment: false,
      limitPaymentData: payload,
    };
  },
  [limitPaymentActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingLimitPayment: false,
      errorLimitPayment: true,
      errorMessageLimitPayment: payload,
      limitPaymentData: [],
    };
  },
  [editLimitPaymentLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingEditLimitPayment: true,
      errorEditLimitPayment: false,
      editLimitPaymentData: [],
    };
  },
  [editLimitPaymentSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingEditLimitPayment: false,
      errorEditLimitPayment: false,
      editLimitPaymentData: payload,
    };
  },
  [editLimitPaymentError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingEditLimitPayment: false,
      errorEditLimitPayment: true,
      errorMessageEditLimitPayment: payload,
      editLimitPaymentData: [],
    };
  },
});
