import styled, { css } from 'styled-components';

export const ContainerDoc = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const TitleStyled = styled.h3`
  font-family: Poppins;
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.667vw;
  letter-spacing: 1px;
  text-align: left;
  padding-bottom: 0.8rem;
`;

export const DocContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Section = styled.div`
  display: flex;
`;

export const ImageButton = styled.button`
  padding: 0 0.5rem;
  height: 2.083vw;
  background-color: ${(props) => props.theme.colors.veryLightGray};
  border-radius: 100px;
  border: none;
  font-family: Poppins;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.667vw;
  letter-spacing: 0.15000000596046448px;
  color: ${(props) => props.theme.colors.black};
  margin: 0.278vw;
  cursor: pointer;
  :hover {
    font-weight: 600;
    color: ${(props) => props.theme.colors.black};
  }
  ${(props) =>
    props.index === props.selectedImage &&
    css`
      background-color: ${(props) => props.theme.colors.veryLightGray};
      color: ${(props) => props.theme.colors.black};
      font-weight: 600;
    `}
`;
