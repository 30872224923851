import styled, { css } from 'styled-components';
import { Card as CardMaterial } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

export const CardComponent = styled(CardMaterial)`
  background: ${(props) => props.theme.white};

  width: ${(props) => props.width || 'auto'} !important;
  height: ${(props) => props.height || 'auto'} !important;
  margin-top: ${(props) => props.marginTop || '0'} !important;
  display: flex;
  transition: 1s !important;
  box-shadow: none;
  border-radius: 10px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1vw;

  & > div > .MuiTextField-root {
    width: 11.5vw;
  }
`;

export const LineData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1vw;

  & > div > .MuiTextField-root {
    width: 11.5vw;
  }
`;

export const LineValue = styled(Line)`
  flex-direction: column;

  h4 {
    margin: 0;
  }
`;

export const IconContainer = styled.div`
  border-radius: 50%;
  width: 2.778vw;
  height: 2.778vw;
  background-color: ${(props) => props.backgroundcolor || props.theme.colors.white} !important;
  display: flex;
  align-items: center;
  color: ${(props) => props.iconColor || props.theme.colors.black} !important;
  justify-content: center;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1vw;

  & span {
    color: ${(props) => props.theme.colors.veryDark};
  }
`;

export const Name = styled.h4`
  font-family: Poppins;
  font-size: 1.319vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.014vw;
  letter-spacing: 0.01vw;
  margin: 1.944vw 0 1.944vw 0;
`;

export const NameTotal = styled.h4`
  font-family: Poppins;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.014vw;
  letter-spacing: 0.01vw;
  margin: 1.944vw 0 1.944vw 0;
`;

export const NameValue = styled(Name)`
  font-weight: 400;
  font-size: 1vw;
  margin: 0;
`;

export const Button = styled(IconButton)``;

export const Text = styled.h5`
  font-family: Poppins;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25vw;
  letter-spacing: 0.028vw;
  text-align: center;
  color: ${(props) => props.theme.colors.darkGrayishBlue};
`;

export const Value = styled.h3`
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 1.319vw;
  line-height: 2.5vw;
  margin-bottom: 1vw;
`;

export const TextInCard = styled.h3`
  font-family: Poppins;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.458vw;
  color: ${(props) => props.theme.colors.black};
  letter-spacing: 0.007vw;
  text-align: left;
`;

export const LineInCard = styled.div`
  display: ${(props) => props.display};
  justify-content: space-between;
  margin: 0.347vw 1vw;
`;

export const ValueInCard = styled.h3`
  font-family: Poppins;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.458vw;
  letter-spacing: 0.007vw;
  text-align: right;
`;

export const Rectangle = styled.div`
  background-color: ${(props) => props.color};
  width: 0.694vw;
  height: 100%;
`;

export const Container = styled.div`
  flex-grow: 1;
`;

export const LineSeparator = styled.hr`
  border: 1px solid ${(props) => props.theme.colors.veryDarkGray};
  width: 93%;
  margin-right: auto;
  margin-left: auto;
`;
