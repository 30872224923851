import { memo, useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import { deniedUsersFetchAction } from '../../../store/fetchActions/deniedUsersActions';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../components/Filter/Filter';
import DateInput from '../../../components/DateInput';
import Button from '../../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import { useForm } from 'react-hook-form';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import SelectOptions from '../../../components/SelectOptions';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import { useTheme } from 'styled-components';
import TextField from '../../../components/TextField';
import moment from 'moment';
import Loading from '../../../components/Loading';
import { pagesOptions } from '../../../utils/constant';

function UsuariosNegados({ value, index }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const { handleSubmit, control } = useForm({});
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { deniedUsersData, paginationDeniedUsers, loadingDeniedUsers, errorDeniedUsers, messageDeniedUsers } =
    useSelector((state) => state.deniedUsers);

  useCallFilter(filterData, setFilter, deniedUsersFetchAction, value, index);

  useEffect(() => {
    dispatch(deniedUsersFetchAction(filter));
  }, [filter.PAGE]);

  const header = [
    {
      id: 'data de registro',
      name: 'DATA DE REGISTRO',
      align: 'center',
    },
    {
      id: 'CPFCNPJ',
      name: 'CPF/CNPJ',
      align: 'left',
    },
    {
      id: 'nome',
      name: 'NOME',
      align: 'left',
    },
    {
      id: 'observação',
      name: 'OBSERVAÇÃO',
      align: 'left',
    },
  ];

  const body = deniedUsersData?.map((user) => {
    return {
      data: [
        {
          info: user.DATA_INSERT,
          align: 'center',
        },
        {
          info: user.CPFCNPJ?.length === 11 ? formatCPF(user.CPFCNPJ) : formatCNPJ(user.CPFCNPJ),
          align: 'left',
        },
        {
          info: user.NOME,
          align: 'left',
        },
        {
          info: user.MENSAGEM,
          align: 'left',
          width: '30vw',
        },
      ],
    };
  });

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial ?? '';
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  return (
    <div>
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'38.042vw'} control={control} />
            <DateInput
              label="Data Inicial"
              name={'DATA_INICIAL'}
              control={control}
              value={dateInicial}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <DateInput
              label="Data Final"
              name={'DATA_FINAL'}
              control={control}
              value={dateFinal}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
              }
            />

            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>

          <SecondFilterLine>
            <h5>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {paginationDeniedUsers?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>
      {loadingDeniedUsers ? (
        <Loading />
      ) : (
        <Table
          loading={loadingDeniedUsers}
          error={errorDeniedUsers}
          hasPagination={true}
          filter={filter}
          pagination={paginationDeniedUsers}
          setFilter={setFilter}
          errorMessage={messageDeniedUsers}
          filterTable={filterTable}
          header={header}
          body={body}
        />
      )}
    </div>
  );
}

export default memo(UsuariosNegados);
