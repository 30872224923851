import api from '../../utils/services/api';
import {
  dailyMovementsActionLoading,
  dailyMovementsActionSuccess,
  dailyMovementsActionError,
} from '../ducks/dailyMovements';

export const dailyMovementsFetchAction = (filtro) => {
  return async (dispatch) => {
    dispatch(dailyMovementsActionLoading());
    try {
      const response = await api.get(`/transacoes-dashboard?PAGE=${filtro.PAGE}&LIMIT=${filtro.LIMIT}`);

      dispatch(dailyMovementsActionSuccess(response.data));
    } catch (error) {
      dispatch(dailyMovementsActionError());
    }
  };
};
