import { useState, useMemo } from 'react';
import {} from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Button from '../../../../../../components/Button/Button';
import Loading from '../../../../../../components/Loading';
import { ocrMatchConsultaStatus, ocrUpload } from '../../../../../../store/fetchActions/ocrServicesActions';
import verifyArray from '../../../../../../utils/verifyArray';
import ReOcrMatch from './ReOcrMatch';
import * as S from './FaceMatchOcr.styles';
import { NoSpecialCharacter } from '../../../../../../utils/regex';
import { AddCircle } from '../../../../../../assets/icon';
import CardImages from '../../../../../../components/ReadImages/CardImages';

const Ocr = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [resultOCR, setResultOCR] = useState(false);
  const [openModalReOcrMatch, setOpenModalReOcrMatch] = useState(false);

  const { detailAccountsData } = useSelector((state) => state.account);
  const { loadingOcr, dataOcr } = useSelector((state) => state.ocr);
  const { usersData } = useSelector((state) => state.user);

  const ocrDocuments = useMemo(() => {
    let filter = detailAccountsData?.documentos?.filter((e) => e.ID_CATEGORIA === 8);
    return filter?.map((val) => {
      return { data: { description: val.CATEGORIA, image: val.CAMINHO_ARQUIVO } };
    });
  }, [detailAccountsData?.documentos]);

  const startOcr = () => {
    let params = {
      CPFCNPJ_USUARIO: usersData?.CPFCNPJ,
      CPFCNPJ: NoSpecialCharacter(localStorage.getItem('cpfcnpj')),
    };
    dispatch(
      ocrMatchConsultaStatus(
        {
          CPFCNPJ_USUARIO: usersData?.CPFCNPJ,
        },
        (res) => {
          res?.mensagem === 'Sucesso!' ? dispatch(ocrUpload(params)) : setOpenModalReOcrMatch(true);
        },
      ),
    );
  };

  const colorMatch = (val, name) => {
    switch (dataOcr[0]?.validation_detections[0][val]?.valid) {
      case true:
        return <S.ColorOcrMatch color={theme.colors.darklimeGreen}>{name}</S.ColorOcrMatch>;

      case 'NOT_VALIDATED':
        return <S.ColorOcrMatch color={theme.colors.vividRed_2}>{name}</S.ColorOcrMatch>;
    }
  };
  return (
    <S.ContainerOCR>
      <S.CardInfo>
        {loadingOcr ? (
          <Loading />
        ) : (
          <>
            <S.Flex>
              <S.ConteinerSearch>
                <h1>OCR</h1>
                <Button
                  backgroundcolor={theme.colors.strongBlue}
                  width="15.111vw"
                  hoverbgcolor={theme.colors.strongBlue}
                  hovercolor={theme.colors.white}
                  mycolor={theme.colors.white}
                  onClick={() => startOcr()}
                  disabled={verifyArray(dataOcr)}
                  margin={' 1.083vw 0'}
                >
                  <S.SpanButtonTitle>Consultar OCR</S.SpanButtonTitle>
                </Button>
              </S.ConteinerSearch>
              <S.ConteinerResult>
                <S.SectionLabel>
                  <S.StyleH3>Resultado</S.StyleH3>
                  {verifyArray(dataOcr) && <AddCircle onClick={() => setResultOCR(!resultOCR)} />}
                </S.SectionLabel>
                {verifyArray(dataOcr) ? (
                  <>
                    <S.CompareDocument>
                      <h5>RG Verso</h5>
                      <h5 style={{ color: theme.colors.darklimeGreen, paddingLeft: ' 1.5vw' }}>VERIFICADO</h5>
                    </S.CompareDocument>
                    <S.ResultOCR>
                      <div>
                        <h4>CPF</h4>
                        <h4>{dataOcr[0]?.validation_detection_status?.CPF_status == true ? 'VALIDO' : 'NÃO VÁLIDO'}</h4>
                      </div>
                      <S.defaultResultOcr>
                        <h4>Documento</h4>
                        <h4>
                          {dataOcr[0]?.validation_detection_status?.document_valid == 'VALID' ? 'VALIDO' : 'NÃO VÁLIDO'}
                        </h4>
                      </S.defaultResultOcr>
                      {dataOcr[0]?.validation_detection_status?.death === true && (
                        <S.defaultResultOcr>
                          <h4>Óbito</h4>
                          <h4>{dataOcr[0]?.validation_detection_status?.death?.toString() == true ? 'SIM' : 'NÃO'}</h4>
                        </S.defaultResultOcr>
                      )}
                    </S.ResultOCR>
                  </>
                ) : (
                  <S.CompareDocument className="result_default_ocr">Consulta não realizada</S.CompareDocument>
                )}
              </S.ConteinerResult>
            </S.Flex>
            {resultOCR && (
              <>
                <hr />
                <S.DetailInfoOcr>
                  <div>
                    <CardImages imagesMaxWidth={'12vw'} imagesMaxHeight={'22vw'} images={ocrDocuments} />
                  </div>
                  <S.InfoOcr>
                    <S.TitleResultOcr>Resultado OCR</S.TitleResultOcr>
                    {verifyArray(dataOcr[0]?.msg_infos) ? (
                      <row>{dataOcr[0]?.msg_infos[0]?.msg}</row>
                    ) : dataOcr[0]?.detections[0]?.fields !== undefined ? (
                      Object?.entries(dataOcr[0]?.detections[0]?.fields)?.map((val, indx) => (
                        <S.ContainerResultOcrDetail>
                          <h3>{val[0]?.toLocaleUpperCase() + ': '}</h3>
                          {colorMatch(val[0], val[1])}
                        </S.ContainerResultOcrDetail>
                      ))
                    ) : (
                      'Não foram geradas informações suficientes do documento.'
                    )}
                  </S.InfoOcr>
                </S.DetailInfoOcr>
              </>
            )}
          </>
        )}
      </S.CardInfo>
      {openModalReOcrMatch && (
        <ReOcrMatch open={openModalReOcrMatch} close={() => setOpenModalReOcrMatch(false)} user={usersData?.CPFCNPJ} />
      )}
    </S.ContainerOCR>
  );
};

export default Ocr;
