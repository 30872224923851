import api from '../../utils/services/api';

import { blockedUsersActionError, blockedUsersActionLoading, blockedUsersActionSuccess } from '../ducks/blockedUsers';

export const blockedUsersFetchAction = (filtro = { limit: 20, page: 1 }) => {
  return async (dispatch) => {
    dispatch(blockedUsersActionLoading());
    try {
      const response = await api.post(`/usuario/listar/bloqueado`, filtro);
      dispatch(blockedUsersActionSuccess(response.data));
    } catch (error) {
      dispatch(blockedUsersActionError(error?.response?.data));
    }
  };
};
