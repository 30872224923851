import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      fill="none"
      viewBox="0 0 24 24"
      style={{ paddingRight: props.paddingRight }}
    >
      <path
        fill={props.color ? props.color : '#B80000'}
        d="M12 .024C5.386.024.024 5.387.024 12S5.386 23.976 12 23.976c6.613 0 11.976-5.363 11.976-11.976S18.613.024 12 .024zm0 21.92c-5.49 0-9.944-4.453-9.944-9.944a9.9 9.9 0 012.232-6.277l13.988 13.989A9.9 9.9 0 0112 21.944zm7.712-3.668L5.723 4.288A9.9 9.9 0 0112 2.056c5.49 0 9.944 4.453 9.944 9.944a9.9 9.9 0 01-2.232 6.276z"
      ></path>
    </svg>
  );
}

export default Icon;
