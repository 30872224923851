import { useDispatch } from 'react-redux';
import { MdErrorOutline } from 'react-icons/md';
import Modal from '../../../../../../components/Modal/Modal';
import { ButtonContainer, SmallerModal } from '../../../../styles/GerenciarClientes.styles';
import Button from '../../../../../../components/Button/Button';
import { ocrUpload } from '../../../../../../store/fetchActions/ocrServicesActions';
import { useTheme } from 'styled-components';
import { NoSpecialCharacter } from '../../../../../../utils/regex';

const ReFaceMatch = ({ open, close, user }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const executeMatch = () => {
    let params = {
      CPFCNPJ_USUARIO: user,
      CPFCNPJ: NoSpecialCharacter(localStorage.getItem('cpfcnpj')),
    };

    dispatch(ocrUpload(params));
    close();
  };

  return (
    <Modal open={open} close={close}>
      <SmallerModal>
        <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
        <h3>{'Já existe uma consulta feita para esse usuário!'}</h3>
        <h5>Tem certeza que deseja efetuar uma nova consulta ?</h5>
        <ButtonContainer>
          <Button
            backgroundcolor={theme.colors.buttonBack}
            width={'13.792vw'}
            height={'3.125vw'}
            hoverbgcolor={theme.colors.buttonBackHover}
            hovercolor={theme.colors.buttonBackHoverText}
            mycolor={theme.colors.buttonBackText}
            onClick={close}
          >
            <span>Voltar</span>
          </Button>
          <Button
            backgroundcolor={theme.colors.buttonConfirm}
            width={'13.792vw'}
            height={'3.125vw'}
            hoverbgcolor={theme.colors.buttonConfirmHover}
            hovercolor={theme.colors.buttonConfirmHoverText}
            mycolor={theme.colors.buttonConfirmText}
            onClick={() => executeMatch()}
          >
            <span>Confirmar</span>
          </Button>
        </ButtonContainer>
      </SmallerModal>
    </Modal>
  );
};

export default ReFaceMatch;
