import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Button from '../../../../../../components/Button/Button';
import Loading from '../../../../../../components/Loading';
import CardImages from '../../../../../../components/ReadImages/CardImages';
import {
  faceMatchConsultaStatus,
  faceMatchUpload,
} from '../../../../../../store/fetchActions/faceMatchServicesActions';
import { NoSpecialCharacter } from '../../../../../../utils/regex';
import verifyArray from '../../../../../../utils/verifyArray';
import ReFaceMatch from './ReFaceMatch';
import * as S from './FaceMatchOcr.styles';
import { AddCircle } from '../../../../../../assets/icon';

const FaceMatch = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [resultMatch, setResultMatch] = useState(false);
  const [openModalReFaceMatch, setOpenModalReFaceMatch] = useState(false);

  const { detailAccountsData } = useSelector((state) => state.account);
  const { loadingFaceMatch, dataFaceMatch } = useSelector((state) => state.faceMatch);
  const { usersData } = useSelector((state) => state.user);

  const faceMatchDocuments = useMemo(() => {
    let filter = detailAccountsData?.documentos?.filter((e) => e.ID_CATEGORIA === 5 || e.ID_CATEGORIA === 1);
    return filter?.map((val) => {
      return { data: { description: val.CATEGORIA, image: val.CAMINHO_ARQUIVO } };
    });
  }, [detailAccountsData?.documentos]);

  const startFaceMatch = () => {
    let params = {
      CPFCNPJ_USUARIO: usersData?.CPFCNPJ,
      CPFCNPJ: NoSpecialCharacter(localStorage.getItem('cpfcnpj')),
    };

    dispatch(
      faceMatchConsultaStatus(
        {
          CPFCNPJ_USUARIO: usersData?.CPFCNPJ,
        },
        (res) => {
          res?.mensagem === 'Sucesso' ? dispatch(faceMatchUpload(params)) : setOpenModalReFaceMatch(true);
        },
      ),
    );
  };

  return (
    <S.ContainerFaceMatch>
      <S.CardInfo>
        {loadingFaceMatch ? (
          <Loading />
        ) : (
          <>
            <S.Flex>
              <S.ConteinerSearch>
                <h1>Face Match</h1>
                <Button
                  backgroundcolor={theme.colors.strongBlue}
                  width="15.111vw"
                  hoverbgcolor={theme.colors.strongBlue}
                  hovercolor={theme.colors.white}
                  mycolor={theme.colors.white}
                  onClick={() => startFaceMatch()}
                  disabled={verifyArray(dataFaceMatch)}
                  margin={' 1.083vw 0'}
                >
                  <S.SpanButtonTitle>Realizar Face Match</S.SpanButtonTitle>
                </Button>
              </S.ConteinerSearch>
              <S.ConteinerResult>
                <S.SectionLabel>
                  <S.StyleH3>Resultado</S.StyleH3>
                  {verifyArray(dataFaceMatch) && <AddCircle onClick={() => setResultMatch(!resultMatch)} />}
                </S.SectionLabel>
                {verifyArray(dataFaceMatch) ? (
                  <>
                    <S.CompareDocument>RG x Selfie</S.CompareDocument>
                    <S.SectionResult>
                      <div>
                        <h4>Match</h4>
                        <S.ColorMatch
                          color={dataFaceMatch[0].match ? theme.colors.darklimeGreen : theme.colors.vividRed_2}
                        >
                          {dataFaceMatch[0].match.toString().toUpperCase()}
                        </S.ColorMatch>
                      </div>
                      <div>
                        <h4>Porcentagem</h4>
                        <S.ColorProbability
                          color={
                            dataFaceMatch[0].probability < 50
                              ? theme.colors.vividRed_2
                              : dataFaceMatch[0].probability < 80
                              ? theme.colors.vividOrange_2
                              : theme.colors.darklimeGreen
                          }
                        >
                          {dataFaceMatch[0].probability + '%'}
                        </S.ColorProbability>
                      </div>
                    </S.SectionResult>
                  </>
                ) : (
                  <S.CompareDocument className="result_default">Consulta não realizada</S.CompareDocument>
                )}
              </S.ConteinerResult>
            </S.Flex>
            {resultMatch && (
              <>
                <hr />
                <CardImages imagesMaxWidth={'12vw'} imagesMaxHeight={'22vw'} images={faceMatchDocuments} />
              </>
            )}
          </>
        )}
      </S.CardInfo>

      {openModalReFaceMatch && (
        <ReFaceMatch
          open={openModalReFaceMatch}
          close={() => setOpenModalReFaceMatch(false)}
          user={usersData?.CPFCNPJ}
        />
      )}
    </S.ContainerFaceMatch>
  );
};

export default FaceMatch;
