import styled, { css } from 'styled-components';
import { AppBar as MaterialAppBar } from '@material-ui/core/';
import { Tab } from '@material-ui/core';
import { Badge as MaterialBadge } from '@material-ui/core/';
import Tooltip from '@material-ui/core/Tooltip';

export const AppBar = styled(MaterialAppBar)`
  background-color: ${(props) => props.theme.colors.white_2};
  color: ${(props) => props.theme.colors.black};
  box-shadow: none;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.111vw;
  font-style: normal;
  line-height: 1.667vw;
  letter-spacing: 0em;
  text-align: left;
  margin: ${(props) => props.margin || '0 0 1vw 0'};

  .Mui-selected {
    color: ${(props) => props.theme.colors.gray};
    background-color: ${(props) => props.theme.colors.darkBlueTransparent};
    border-bottom: 2px solid;
  }
  .MuiTab-root {
    text-transform: capitalize !important;
    font-weight: 600;
    min-width: 72px !important;
    width: ${(props) => (props.width ? props.width : 'auto')};
    font-size: 1.111vw;
  }
  .MuiTabs-indicator {
    background-color: transparent;
  }
`;

export const UserNotificationContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.4vw;
`;

export const UsersNumberInfo = styled.div`
  background: ${(props) => props.theme.colors.darklimeGreen};
  display: inline-flex;
  border-radius: 50%;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  min-width: 1.5vw;

  h5 {
    font-size: 0.8vw;
  }
`;

export const TabUsers = styled(Tab)`
  width: 19.175vw;
`;

export const Badge = styled(MaterialBadge)`
  .MuiBadge-badge {
    background-color: ${(props) => props.theme.colors.brown};
    right: -20%;
    top: 50%;
    padding: 0 4px;
    color: ${(props) => props.theme.colors.white};
    font-weight: bold;
    font-size: 11px;
  }
`;

export const Tooltips = styled((props) => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    background-color: ${(props) => props.theme.colors.veryPaleRed};
    color: ${(props) => props.theme.colors.brightRed};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.8rem;
  }
  & .MuiTooltip-tooltipPlacementBottom {
    margin: 0 0 14px 0 !important;
  }
  & .MuiTooltip-tooltip {
    max-width: 500px;
  }
`;
