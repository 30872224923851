import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './CardImages.styles.jsx';
import ImageElement from '../ImageElement/ImageElement';

const CardImages = (props) => {
  const { images, imagesMaxWidth, imagesMaxHeight } = props;

  return (
    <Styled.ImgWrapper>
      <Styled.ImgContainer>
        {images &&
          images?.map((eachImage, index) => {
            return (
              <Styled.ImgSection key={index + '-KeyCardImages'}>
                <h4>{eachImage.data.description}</h4>
                <ImageElement
                  key={index + '-CardImages'}
                  maxWidth={imagesMaxWidth}
                  maxHeight={imagesMaxHeight}
                  subtitle={eachImage.data.description}
                  image={eachImage.data.image}
                />
              </Styled.ImgSection>
            );
          })}
      </Styled.ImgContainer>
    </Styled.ImgWrapper>
  );
};

export default CardImages;

CardImages.defaultProps = {
  images: '',
  title: '',
  imagesMaxWidth: '20vw',
};

CardImages.propTypes = {
  title: PropTypes.string,
  images: PropTypes.array,
  imagesMaxWidth: PropTypes.string,
};
