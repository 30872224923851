const AmericanDate = (date) => {
  let array = date?.split('/');
  let Data = '';

  array?.length > 0 ? (Data = `${array[2]}-${array[1]}-${array[0]}`) : (Data = date);

  return Data;
};

export default AmericanDate;
