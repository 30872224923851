const Permission = ({ children, permissions }) => {
  const accessLevel = localStorage.getItem('nivelAcesso');

  if (
    permissions.some((permission) => {
      return permission === Number(accessLevel);
    })
  ) {
    return children;
  }

  return null;
};

export default Permission;
