import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MdModeEdit, MdDelete, MdErrorOutline } from 'react-icons/md';
import * as Styled from '../PerfisUsuarios.styles';
import Table from '../../../components/Table/Table';
import Tooltip from '@material-ui/core/Tooltip';
import formatCPF from '../../../utils/functions/formatCPF';
import { LoadingContainer } from '../../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@material-ui/core';
import ModalPassword from '../../../components/ModalPassword/ModalPassword';
import Password from '../../../assets/icon/Password';
import { useTheme } from 'styled-components';
import ModalDeleteUser from '../Modals/Usuario/DeleteUser';
import ModalCreateUser from '../Modals/Usuario/CreateUser';
import ModalEditUser from '../Modals/Usuario/EditUser';
import ModalChangePassword from '../Modals/Usuario/ChangePasswordUser';

function Usuarios({ value, index }) {
  const theme = useTheme();
  const [editUsuario, setEditUsuario] = useState({});
  const [deleteSystemUser, setDeleteSystemUser] = useState({
    name: '',
    id: '',
  });
  const [modalDeleteUser, setModalDeleteUser] = useState(false);
  const [modalPasswordDeleteUser, setModalPasswordDeleteUser] = useState(false);
  const [modalCreateUser, setModalCreateUser] = useState(false);
  const [modalPasswordCreateUser, setModalPasswordCreateUser] = useState(false);
  const [modalEditUser, setModalEditUser] = useState(false);
  const [modalPasswordEditUser, setModalPasswordEditUser] = useState(false);
  const [modalValidatePasswordEditUser, setModalValidatePasswordEditUser] = useState(false);
  const [modalChangePasswordUser, setModalChangePasswordUser] = useState(false);

  const { cobansData, loadingCobans, errorCobans, errorMessageCobans } = useSelector((state) => state.cobans);
  const { perfisData } = useSelector((store) => store.perfis);

  function filterProfile(profile) {
    if (profile.CPFCNPJ != null) {
      return true;
    } else {
      return false;
    }
  }

  function ordenArray(a, b) {
    return b.ID - a.ID;
  }

  const profilesData = perfisData?.map((profile) => {
    return {
      ID: profile?.ID,
      NOME: profile?.PERFIL,
    };
  });

  const header = [
    {
      id: 'cpfcnpj',
      name: 'CPF/CNPJ',
      align: 'left',
    },
    {
      id: 'nome',
      name: 'NOME',
      align: 'left',
    },
    {
      id: 'ações',
      name: 'AÇÕES',
      align: 'center',
    },
  ];

  const body = cobansData
    ?.filter(filterProfile)
    .sort(ordenArray)
    .map((perfil, index) => {
      return {
        data: [
          { info: formatCPF(perfil.CPFCNPJ), align: 'left' },
          { info: perfil.NOME, align: 'left' },
          {
            info: (
              <Styled.Row>
                <Tooltip title="Alterar senha usuário">
                  <Styled.IconContainer
                    onClick={() => {
                      setEditUsuario({
                        CPFCNPJ: perfil?.CPFCNPJ,
                      });
                      setModalValidatePasswordEditUser(true);
                    }}
                    backgroundcolor={theme.colors.grayTransparent}
                  >
                    <Password />
                  </Styled.IconContainer>
                </Tooltip>
                <Tooltip title="Editar Usuário">
                  <Styled.IconContainer
                    onClick={() => {
                      setEditUsuario({
                        name: perfil.NOME,
                        id: perfil.ID,
                        CPFCNPJ: perfil.CPFCNPJ,
                        EMAIL: perfil.EMAIL,
                        FONECEL: perfil.FONECEL,
                      });
                      setModalPasswordEditUser(true);
                    }}
                    backgroundcolor={theme.colors.grayTransparent}
                  >
                    <MdModeEdit size={25} />
                  </Styled.IconContainer>
                </Tooltip>
                <Tooltip title="Remover Usuário">
                  <Styled.IconContainer
                    onClick={() => {
                      setDeleteSystemUser({
                        name: perfil.NOME,
                        id: perfil.ID,
                        cpfcnpjcoban: perfil.CPFCNPJ,
                        email: perfil.EMAIL,
                        fonecel: perfil.FONECEL,
                      });
                      setModalPasswordDeleteUser(true);
                    }}
                    backgroundcolor={theme.colors.redTransparent1}
                    color={theme.colors.vividRed}
                  >
                    <MdDelete size={25} />
                  </Styled.IconContainer>
                </Tooltip>
              </Styled.Row>
            ),
            align: 'center',
          },
        ],
      };
    });

  return (
    <div hidden={value !== index}>
      <Styled.ButtonContainer>
        <Styled.ButtonStatusUsers
          backgroundcolor={theme.colors.buttonCreateUsuario}
          width="12.153vw"
          height="3vw"
          hoverbgcolor={theme.colors.buttonCreateUsuarioHover}
          styledcolor={theme.colors.buttonCreateUsuarioText}
          hovercolor={theme.colors.buttonCreateUsuarioHoverText}
          onClick={() => setModalPasswordCreateUser(true)}
        >
          <span className="plus-span">+</span>
          <span>CRIAR USUÁRIO</span>
        </Styled.ButtonStatusUsers>
      </Styled.ButtonContainer>

      {loadingCobans ? (
        <LoadingContainer>
          <CircularProgress size={'5vw'} color="inherit" />
        </LoadingContainer>
      ) : (
        <Table
          loading={loadingCobans}
          error={errorCobans}
          errorMessage={errorMessageCobans}
          header={header}
          body={body}
        />
      )}

      {modalDeleteUser && (
        <ModalDeleteUser open={modalDeleteUser} close={() => setModalDeleteUser(false)} info={deleteSystemUser} />
      )}
      {modalCreateUser && (
        <ModalCreateUser open={modalCreateUser} close={() => setModalCreateUser(false)} info={profilesData} />
      )}
      {modalEditUser && (
        <ModalEditUser
          open={modalEditUser}
          close={() => setModalEditUser(false)}
          editUsuario={editUsuario}
          info={profilesData}
        />
      )}

      {modalChangePasswordUser && (
        <ModalChangePassword
          open={modalChangePasswordUser}
          close={() => setModalChangePasswordUser(false)}
          editUsuario={editUsuario}
        />
      )}
      <ModalPassword
        openModal={modalPasswordDeleteUser}
        closeModal={() => setModalPasswordDeleteUser(false)}
        nextFunction={() => setModalDeleteUser(true)}
      />
      <ModalPassword
        openModal={modalPasswordEditUser}
        closeModal={() => setModalPasswordEditUser(false)}
        nextFunction={() => setModalEditUser(true)}
      />
      <ModalPassword
        openModal={modalValidatePasswordEditUser}
        closeModal={() => setModalValidatePasswordEditUser(false)}
        nextFunction={() => setModalChangePasswordUser(true)}
      />
      <ModalPassword
        openModal={modalPasswordCreateUser}
        closeModal={() => setModalPasswordCreateUser(false)}
        nextFunction={() => setModalCreateUser(true)}
      />
    </div>
  );
}

export default Usuarios;
