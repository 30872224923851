import api from '../../utils/services/api';

import { saldoAdminActionError, saldoAdminActionLoading, saldoAdminActionSuccess } from '../ducks/saldoAdmin';

export const getSaldoAdminFetchAction = () => {
  return async (dispatch) => {
    dispatch(saldoAdminActionLoading());
    try {
      const response = await api.get('/conta-bacen-mae/balance');
      dispatch(saldoAdminActionSuccess(response.data));
    } catch (error) {
      dispatch(saldoAdminActionError(error?.response?.data));
    }
  };
};
