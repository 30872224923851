import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Styled from './SweetAlert.styles';
import Swal from 'sweetalert2';

function SweetAlert() {
  const dispatch = useDispatch();

  const sweetAlert = useSelector((state) => state.sweetAlert);
  const { alert, message, func, loadingSpinner, close } = sweetAlert;

  useEffect(() => {
    const sweet = () => {
      message.title !== undefined
        ? Swal.fire({
            className: 'swal-text',
            title: `${message.title}`,
            text: `${message.text}`,
            icon: `${message.icon}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              loadingSpinner && Swal.showLoading();
            },
          }).then(() => {
            if (func) {
              func();
            }
          })
        : Swal.fire({
            customClass: 'swal-text-loading',
            title: 'Aguarde ...!',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
    };

    if (close) Swal.close();

    if (alert) sweet();
  }, [alert, message, dispatch, func, loadingSpinner]);

  return <Styled.Container></Styled.Container>;
}

export default SweetAlert;
