import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none" viewBox="0 0 13 22">
      <path
        fill="#fff"
        d="M10.5 0h-8A2.5 2.5 0 000 2.5v17A2.5 2.5 0 002.5 22h8a2.5 2.5 0 002.5-2.5v-17A2.5 2.5 0 0010.5 0zm-4 21c-.83 0-1.5-.67-1.5-1.5S5.67 18 6.5 18s1.5.67 1.5 1.5S7.33 21 6.5 21zm4.5-4H2V3h9v14z"
      ></path>
    </svg>
  );
}

export default Icon;
