const formatPhoneNumber = (string) => {
  try {
    if (typeof string != 'string') {
      string = string + '';
    }
    const rawNumber = string.replace(' ', '').replace('-', '').replace('(', '').replace(')', '');

    const ddd = rawNumber.substr(0, 2);
    const firstDigits = rawNumber.length == 11 ? rawNumber.substr(2, 5) : rawNumber.substr(2, 4);
    const lastDigits = rawNumber.length == 11 ? rawNumber.substr(7, 4) : rawNumber.substr(6, 4);

    const maskedString = (ddd && `(${ddd}`) + (firstDigits && `)${firstDigits}`) + (lastDigits && `-${lastDigits}`);
    return maskedString;
  } catch (e) {
    return '';
  }
};
export default formatPhoneNumber;
