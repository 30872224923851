import Modal from '../../../../components/Modal/Modal';
import { SmallerModal, ContainerMensagem } from './ConfirmationSend,styles';
import TextField from '../../../../components/TextField';
import { useForm } from 'react-hook-form';
import { NotificationDetail } from '../../../../assets/icon/index';

const DetailMensagem = (props) => {
  const { control } = useForm({});

  return (
    <Modal open={props.openModal} close={props.closeModal}>
      <SmallerModal>
        <NotificationDetail size={'80'} />
        <h3>{'Detalhes de envio'}</h3>
        <ContainerMensagem>
          <TextField
            width={'28.042vw'}
            label="Título"
            name="TITULO"
            variant="standard"
            value={props.info?.titulo}
            disabled
            multiline={true}
            control={control}
            color={'#000 !important'}
          />
          <TextField
            width={'28.042vw'}
            margin={'0.8rem 0 0.8rem 0'}
            label="Texto"
            name="MENSAGEM"
            variant="standard"
            value={props.info?.mensagem}
            disabled
            multiline={true}
            control={control}
            color={'#000 !important'}
          />
          {props.info?.id_topico && (
            <TextField
              width={'28.042vw'}
              margin={'0.8rem 0 0.8rem 0'}
              label="Grupo"
              name="GRUPO"
              variant="standard"
              value={props.info?.topico}
              disabled
              multiline={true}
              control={control}
              color={'#000 !important'}
            />
          )}
        </ContainerMensagem>
      </SmallerModal>
    </Modal>
  );
};

export default DetailMensagem;
