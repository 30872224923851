import React, { useState, useRef } from 'react';
import { ButtonGroup, ClickAwayListener, Paper, MenuList, MenuItem } from '@material-ui/core';
import * as Styled from './DropDown.styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

export default function CustomizedMenus(props) {
  const anchorRef = useRef(null);
  const [openDropDownMenu, setOpenDropDownMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const handleButtonsGroup = () => {
    setOpenDropDownMenu((oldState) => !oldState);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropDownMenu(false);
  };

  const handleMenuItemClick = (event, index, nameOption) => {
    setSelectedItem(index);
    props.setNameSelectedItem(nameOption);
    setOpenDropDownMenu(false);
  };

  return (
    <div>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Styled.ButtonOptions
          aria-controls={openDropDownMenu ? 'split-button-menu' : undefined}
          aria-expanded={openDropDownMenu ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleButtonsGroup}
        >
          <span>GERENCIAR</span>
          {openDropDownMenu ? <ArrowDropUp /> : <ArrowDropDown />}
        </Styled.ButtonOptions>
      </ButtonGroup>
      <Styled.PopperButtonsGroup open={openDropDownMenu} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Styled.GrowButtonsGroup
            {...TransitionProps}
            transformOrigin={placement === 'bottom' ? 'center top' : 'center bottom'}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {props.title ? (
                  <MenuList>
                    {props.title.map((val, ind) => {
                      const itemMenu = props.options[ind]?.map((option, index) => (
                        <MenuItem
                          key={option?.label}
                          selected={index === selectedItem - 1}
                          onClick={(event) => {
                            !props.isEditing && props.openModalPassword();
                            handleMenuItemClick(event, index + 1, option?.action);
                            props?.setOption(option?.label);
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ));

                      return (
                        <>
                          {<div style={{ paddingLeft: '0.4rem' }}>{val}</div>}
                          {itemMenu}
                        </>
                      );
                    })}
                  </MenuList>
                ) : (
                  <MenuList>
                    {props.options?.map((option, index) => (
                      <MenuItem
                        key={option?.label}
                        selected={index === selectedItem - 1}
                        onClick={(event) => {
                          !props.isEditing && props.openModalPassword();
                          handleMenuItemClick(event, index + 1, option?.action);
                        }}
                      >
                        {option?.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </ClickAwayListener>
            </Paper>
          </Styled.GrowButtonsGroup>
        )}
      </Styled.PopperButtonsGroup>
    </div>
  );
}
