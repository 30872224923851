import styled from 'styled-components';

export const ContainerImg = styled.div`
  align-self: center;
`;

export const Img = styled.img`
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
  cursor: pointer;
`;
