import PropTypes from 'prop-types';
import * as Styled from './DateInput.styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/pt-br';

const DateInput = ({ label, disabled, value, width, fullWidth, variant, onChange, required }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <Styled.Input
            {...params}
            width={width}
            disabled={disabled}
            fullWidth={fullWidth}
            variant={variant}
            required={required}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateInput;

DateInput.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
};
