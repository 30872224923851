import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  transfersData: [],
  paginationTransfers: { totalResult: 0 },
  messageTransfers: '',
  loadingTransfers: false,
};

export const transfersActionLoading = createAction('TRANSFERS_ACTION_LOADING');
export const transfersActionSuccess = createAction('TRANSFERS_ACTION_SUCCESS');
export const transfersActionError = createAction('TRANSFERS_ACTION_ERROR');
export const transfersIDActionLoading = createAction('TRANSFERS_ID_ACTION_LOADING');
export const transfersIDActionSuccess = createAction('TRANSFERS_ID_ACTION_SUCCESS');
export const transfersIDActionError = createAction('TRANSFERS_ID_ACTION_ERROR');
export const transfersTedActionLoading = createAction('TRANSFERS_TED_ACTION_LOADING');
export const transfersTedctionSuccess = createAction('TRANSFERS_TED_ACTION_SUCCESS');
export const transfersTedctionError = createAction('TRANSFERS_TED_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [transfersActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingTransfers: true,
      errorTransfers: false,
    };
  },
  [transfersActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingTransfers: false,
      errorTransfers: false,
      transfersData: payload.data,
      paginationTransfers: payload.pagination,
    };
  },
  [transfersActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingTransfers: false,
      errorTransfers: true,
      messageTransfers: payload,
      transfersData: [],
      paginationTransfers: {},
    };
  },
  [transfersIDActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingIDTransfers: true,
      errorIDTransfers: false,
    };
  },
  [transfersIDActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingIDTransfers: false,
      errorIDTransfers: false,
      transferIDData: payload.data,
    };
  },
  [transfersIDActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingIDTransfers: false,
      errorIDTransfers: true,
      messageIDTransfers: payload,
      transferIDData: [],
    };
  },
  [transfersTedActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingTedTransfers: true,
      errorTedTransfers: false,
    };
  },
  [transfersTedctionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingTedTransfers: false,
      errorTedTransfers: false,
      transferTedData: payload.data,
    };
  },
  [transfersTedctionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingTedTransfers: false,
      errorTedTransfers: true,
      messageTedTransfers: payload,
      transferTedData: [],
    };
  },
});
