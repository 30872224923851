import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1vw" height="1vw" fill="none" viewBox="0 0 20 20">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.356 15.311a2.918 2.918 0 01-2.077-.86l-3.011-3.011a.57.57 0 00-.788 0l-3 3a2.919 2.919 0 01-2.077.86H4.04l3.81 3.81a3.038 3.038 0 004.297 0l3.8-3.799h-.592zm-4.104 2.904a1.762 1.762 0 01-1.252.518 1.76 1.76 0 01-1.253-.518l-2.221-2.221c.308-.18.592-.4.85-.658l2.498-2.499 2.51 2.51c.298.299.632.547.995.74l-2.127 2.128zM12.148.89a3.038 3.038 0 00-4.297 0L4.041 4.7h.362c.784 0 1.522.306 2.077.86l3 3a.556.556 0 00.788 0l3.01-3.01a2.922 2.922 0 012.078-.861h.591L12.148.89zm.236 3.764l-2.51 2.51-2.499-2.5a4.201 4.201 0 00-.849-.657l2.221-2.222a1.775 1.775 0 012.505 0l2.127 2.128a4.192 4.192 0 00-.995.741z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M19.11 7.852l-2.28-2.28h-1.474c-.54 0-1.07.22-1.453.602l-3.01 3.011c-.282.28-.65.42-1.019.42-.369 0-.738-.14-1.018-.42l-3-3a2.066 2.066 0 00-1.454-.603H3.16L.89 7.852a3.038 3.038 0 000 4.297l2.27 2.269h1.242a2.07 2.07 0 001.454-.602l3-2.999c.544-.545 1.492-.544 2.037 0l3.01 3.01c.383.383.912.602 1.453.602h1.473l2.281-2.28a3.038 3.038 0 000-4.297zM4.96 12.92a.789.789 0 01-.558.231h-.718l-1.898-1.897a1.774 1.774 0 010-2.506l1.898-1.899h.718c.209 0 .411.084.559.231L7.88 10 4.96 12.92zm13.254-1.666l-1.908 1.91h-.95a.79.79 0 01-.557-.232L11.87 10l2.93-2.93a.79.79 0 01.557-.232h.95l1.908 1.91c.335.334.519.779.519 1.252 0 .475-.184.92-.519 1.254z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
