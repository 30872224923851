import React from 'react';
import formatPhoneNumber from '../../../../../utils/functions/formatPhoneNumber';
import formatMoneyBRL from '../../../../../utils/functions/formatMoneyBRL';
import formatCEP from '../../../../../utils/functions/formatCEP';
import * as Styled from './InformationColum.styles';
import { useSelector } from 'react-redux';
import DocumentRow from '../../Collums/DocumentRow';
import formatCPF from '../../../../../utils/functions/formatCPF';

export default function InformationPf({ data }) {
  const { detailAccountsData } = useSelector((state) => state.account);

  return (
    <>
      <Styled.ContainerStyled>
        <Styled.PrimaryContainer>
          <Styled.TitleStyled>{'Informações pessoais'}</Styled.TitleStyled>
          <div>
            <Styled.RowContainer>
              <Styled.Row className="rowGrid1">
                <Styled.ParagraphName>{'Nome'}</Styled.ParagraphName>
                <Styled.ParagraphValue>{data?.NOME || '-----'}</Styled.ParagraphValue>
              </Styled.Row>
              <Styled.Row className="rowGrid2">
                <Styled.ParagraphName>{'CPF'}</Styled.ParagraphName>
                <Styled.ParagraphValue>{formatCPF(data?.CPFCNPJ)}</Styled.ParagraphValue>
              </Styled.Row>
              <Styled.Row className="rowGrid3">
                <Styled.ParagraphName>{'Data de nascimento'}</Styled.ParagraphName>
                <Styled.ParagraphValue>{data.DATA_FUND_NASC || '-----'}</Styled.ParagraphValue>
              </Styled.Row>
              <Styled.Row className="rowGrid4">
                <Styled.ParagraphName>{'E-mail'}</Styled.ParagraphName>
                <Styled.ParagraphValue>{data?.EMAIL || '-----'}</Styled.ParagraphValue>
              </Styled.Row>
              <Styled.Row className="rowGrid5">
                <Styled.ParagraphName>{'Telefone'}</Styled.ParagraphName>
                <Styled.ParagraphValue>{formatPhoneNumber(data?.FONECEL) || '-----'}</Styled.ParagraphValue>
              </Styled.Row>
              <Styled.Row className="rowGrid10">
                <Styled.ParagraphName>{'Renda'}</Styled.ParagraphName>
                <Styled.ParagraphValue>{'R$ ' + formatMoneyBRL(data?.RENDA_FATURAMENTO)}</Styled.ParagraphValue>
              </Styled.Row>
            </Styled.RowContainer>
          </div>
        </Styled.PrimaryContainer>
      </Styled.ContainerStyled>
      <Styled.Section />
      <Styled.ContainerStyled>
        <Styled.SecondContainer>
          <Styled.TitleStyled marginTop={'2.764vw'}>{'Endereço'}</Styled.TitleStyled>
          <Styled.RowContainer>
            <Styled.Row className="rowGrid1">
              <Styled.ParagraphName>{'CEP'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{formatCEP(data?.CEP) || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid2">
              <Styled.ParagraphName>{'Logradouro'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.ENDERECO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid3">
              <Styled.ParagraphName>{'Número'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.NUMERO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid4">
              <Styled.ParagraphName>{'Complemento'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.COMPLEMENTO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid5">
              <Styled.ParagraphName>{'Bairro'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.BAIRRO || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid10">
              <Styled.ParagraphName>{'Cidade'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.CIDADE || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
            <Styled.Row className="rowGrid11">
              <Styled.ParagraphName>{'Estado'}</Styled.ParagraphName>
              <Styled.ParagraphValue>{data?.UF || '-----'}</Styled.ParagraphValue>
            </Styled.Row>
          </Styled.RowContainer>
        </Styled.SecondContainer>
      </Styled.ContainerStyled>
      <Styled.Section />
      <Styled.ContainerStyled>
        <DocumentRow document={detailAccountsData?.documentos} />
      </Styled.ContainerStyled>
    </>
  );
}
