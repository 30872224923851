import { memo, useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import DateInput from '../../../../components/DateInput';
import Filter from '../../../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../../../components/Filter/Filter.styles';
import TextField from '../../../../components/TextField';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { ImSearch } from 'react-icons/im';
import SelectOptions from '../../../../components/SelectOptions';
import { useDispatch, useSelector } from 'react-redux';
import StatusContainer from '../../../../components/StatusContainer/StatusContainer';
import convertObjToArrayBoletos from '../../ConvertObjToArrayBoletos';
import { bankSlipsFetchAction } from '../../../../store/fetchActions/bankSlipsActions';
import { bankSlipDetailFetchAction } from '../../../../store/fetchActions/bankSlipDetailActions';
import formatCPF from '../../../../utils/functions/formatCPF';
import formatMoney from '../../../../utils/functions/formatMoneyBRL';
import { translateMessage } from '../../../../utils/functions/translateMessage';
import Information from '../../../../components/Information/Information';
import useCallFilter from '../../../../utils/hooks/useCallFilter';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { arrayStatus, pagesOptions } from '../../../../utils/constant';
import Loading from '../../../../components/Loading';

function TransacoesBoletos() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    STATUS: 0,
    LIMIT: '10',
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control } = useForm({});

  const { paginationBankSlips, loadingBankSlips, messageBankSlips, dataBankSlips, errorBankSlips } = useSelector(
    (state) => state.bankSlips,
  );

  const { dataBankSlipDetail, loadingBankSlipDetail, messageBankSlipDetail, errorBankSlipDetail } = useSelector(
    (state) => state.bankSlipDetail,
  );

  const dataBankSlipDetailArray = convertObjToArrayBoletos(dataBankSlipDetail);

  useCallFilter(filterData, setFilter, bankSlipsFetchAction, 0, 0);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  useEffect(() => {
    dispatch(bankSlipsFetchAction(filter));
  }, [filter.PAGE]);

  const header = [
    {
      id: 'data de emissÃo',
      name: 'DATA DE EMISSÃO',
      align: 'center',
    },
    { id: 'cpf/cnpj remetente', name: 'CPF/CNPJ REMETENTE', align: 'left' },
    { id: 'nome remetente', name: 'NOME REMETENTE', align: 'left' },
    {
      id: 'cpf/cnpj beneficiario',
      name: 'CPF/CNPJ BENEFICIÁRIO',
      align: 'left',
    },
    { id: 'beneficiario', name: 'BENEFICIÁRIO', align: 'left' },
    { id: 'vencimento', name: 'VENCIMENTO', align: 'center' },
    { id: 'valor', name: 'VALOR', align: 'right' },
    { id: 'status', name: 'STATUS', align: 'center' },
  ];

  const body = dataBankSlips?.map((bankSlip) => {
    return {
      data: [
        {
          info: bankSlip.DATAREGISTRO,
          align: 'center',
        },
        {
          info: formatCPF(bankSlip.CPFCNPJ),
          align: 'left',
        },
        {
          info: bankSlip.NOME,
          align: 'left',
        },
        {
          info: formatCPF(bankSlip.CPFCNPJBENEFICIARIO),
          align: 'left',
        },
        {
          info: bankSlip.NOMEBENEFICIARIO,
          align: 'left',
        },
        {
          info: bankSlip.DATAVENCIMENTO,
          align: 'center',
        },
        {
          info: `R$ ${formatMoney(bankSlip.VALORDOCUMENTO)}`,
          align: 'right',
        },
        {
          info: bankSlip.STATUSID ? (
            <StatusContainer code={bankSlip.STATUSID} description={bankSlip.STATUS}></StatusContainer>
          ) : (
            '-----'
          ),
          align: 'center',
        },
      ],
      onClick: () => inspectUser(bankSlip.ID),
    };
  });

  const inspectUser = (id) => {
    dispatch(bankSlipDetailFetchAction(id));
    setIsModalInfoOpen(true);
  };

  return (
    <>
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'25.042vw'} control={control} />
            <SelectOptions
              control={control}
              options={arrayStatus}
              name={'STATUS'}
              width={'11.528vw'}
              label={'Status'}
              initialValue={0}
            />
            <DateInput
              label="Data Inicial"
              name={'DATA_INICIAL'}
              control={control}
              value={dateInicial}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <DateInput
              label="Data Final"
              name={'DATA_FINAL'}
              control={control}
              value={dateFinal}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
              }
            />
            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
              margin={'0 1.389vw 0 0'}
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>
          <SecondFilterLine>
            <h5>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {paginationBankSlips?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>

      {loadingBankSlips ? (
        <Loading />
      ) : (
        <Table
          loading={loadingBankSlips}
          error={errorBankSlips}
          hasPagination={true}
          filter={filter}
          pagination={paginationBankSlips}
          setFilter={setFilter}
          errorMessage={messageBankSlips}
          filterTable={filterTable}
          header={header}
          body={body}
          limit={filter.LIMIT}
        />
      )}

      <Modal open={isModalInfoOpen} close={() => setIsModalInfoOpen(false)} title="Dados do Boleto" width={'45.889vw'}>
        {loadingBankSlipDetail ? (
          <Loading />
        ) : errorBankSlipDetail ? (
          <h1>{translateMessage(messageBankSlipDetail)}</h1>
        ) : (
          <Information data={dataBankSlipDetailArray} />
        )}
      </Modal>
    </>
  );
}

export default memo(TransacoesBoletos);
