import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import Tabs from '../../components/Tabs/Tabs';
import PixKeys from './tabContent/PixKeys';
import PixTransactions from './tabContent/PixTransactions';
import { useDispatch } from 'react-redux';
import { pixTransactionsFetchAction } from '../../store/fetchActions/pixTransactionsActions';
import { pixKeysFetchAction } from '../../store/fetchActions/pixKeysActions';

export default function GerenciarClientes() {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Transações', 'Chaves'];

  useEffect(() => {
    if (tabIndex === 0) dispatch(pixTransactionsFetchAction());
    if (tabIndex === 1) dispatch(pixKeysFetchAction());
  }, [tabIndex]);

  return (
    <Wrapper title="Pix - Transações/Chaves">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" margin="1.5vw 0" />
      {tabIndex === 0 ? <PixTransactions value={tabIndex} index={0} /> : <PixKeys value={tabIndex} index={1} />}
    </Wrapper>
  );
}
