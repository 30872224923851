import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  preapprovedUsersData: [],
  pagination: { totalResult: 0 },
  errorMessage: '',
};

export const preapprovedUsersActionLoading = createAction('PREAPPROVED_USERS_ACTION_LOADING');
export const preapprovedUsersActionSuccess = createAction('PREAPPROVED_USERS_ACTION_SUCCESS');
export const preapprovedUsersActionError = createAction('PREAPPROVED_USERS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [preapprovedUsersActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingPreApprovedUsers: true,
      error: false,
      preapprovedUsersData: [],
      pagination: {},
    };
  },
  [preapprovedUsersActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPreApprovedUsers: false,
      error: false,
      preapprovedUsersData: payload.data,
      pagination: payload.pagination,
    };
  },
  [preapprovedUsersActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPreApprovedUsers: false,
      error: true,
      errorMessage: payload?.mensagem,
      preapprovedUsersData: [],
      pagination: {},
    };
  },
});
