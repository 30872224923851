import { useDispatch, useSelector } from 'react-redux';
import * as Styled from '../styles/ContasAprovados.styles';
import formatMoney from '../../../utils/functions/formatMoneyBRL';
import Button from '../../../components/Button/Button';
import Table from '../../../components/Table/Table';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import Filter from '../../../components/Filter/Filter';
import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import DateInput from '../../../components/DateInput';
import { ImSearch } from 'react-icons/im';
import SelectOptions from '../../../components/SelectOptions';
import dotPrimary from '../../../assets/dotPrimary.svg';
import { userBalanceFetchAction } from '../../../store/fetchActions/usersActions';
import { balanceAccountsFetchAction } from '../../../store/fetchActions/accountActions';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { pagesOptions } from '../../../utils/constant';
import Loading from '../../../components/Loading';

export default function GraphicSheet({ contas }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [saldo, setSaldo] = useState(0);
  const [filterData, setFilterData] = useState({
    ID_CONTA: '',
    CPFCNPJ_CLIENTE: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control } = useForm({});

  const {
    usersBalance: { HISTORICO },
    errorUserBalance,
    loadingUserBalance,
    usersData,
  } = useSelector((state) => state.user);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  useEffect(() => {
    const params = {
      ID_CONTA: contas?.ID_CONTA,
      CPFCNPJ_CLIENTE: usersData?.CPFCNPJ,
      DATAINICIAL: filterData.DATA_INICIAL,
      DATAFINAL: filterData.DATA_FINAL,
      LIMIT: filterData.LIMIT,
      PAGE: filterData.PAGE,
    };

    if (contas?.ID_CONTA !== undefined) {
      dispatch(userBalanceFetchAction(params));
    }
  }, [contas?.ID_CONTA, filterData]);

  const header = [
    {
      id: 'data da transferência',
      name: 'DATA DA TRANSFERÊNCIA',
      align: 'center',
    },
    { id: 'tipo', name: 'TIPO', align: 'left' },
    { id: 'descrição', name: 'DESCRIÇÃO', align: 'left' },
    { id: 'valor', name: 'VALOR', align: 'right' },
    { id: 'saldo anterior', name: 'SALDO ANTERIOR', align: 'right' },
    { id: 'saldo atual', name: 'SALDO ATUAL', align: 'right' },
  ];

  const body = HISTORICO?.data?.map((operacao) => {
    return {
      data: [
        { info: operacao.DATAHORA, align: 'center' },
        {
          info: operacao.TIPO,
          align: 'left',
        },
        {
          info: operacao.DESCRICAO,
          align: 'left',
        },
        { info: `R$ ${formatMoney(operacao.VALOR)}`, align: 'right' },
        {
          info: `R$ ${formatMoney(operacao.SALDO_ANTERIOR)}`,
          align: 'right',
        },
        { info: `R$ ${formatMoney(operacao.SALDO_ATUAL)}`, align: 'right' },
      ],
    };
  });

  useMemo(() => {
    if (contas?.ID_CONTA !== undefined) {
      dispatch(
        balanceAccountsFetchAction(contas.ID_CONTA, (res) => {
          setSaldo(res.saldo);
        }),
      );
    }
  }, [contas?.ID_CONTA]);

  return (
    <Styled.GraphicSheetContainer>
      <>
        <Styled.FirstLine>
          <h2>Extrato</h2>
          <Styled.BalanceInfo>
            <img src={dotPrimary} /> <h4>Saldo Atual:</h4>
            <h3>R$ {formatMoney(saldo)}</h3>
          </Styled.BalanceInfo>
        </Styled.FirstLine>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine width={'50vw'}>
              <DateInput
                label="Data Inicial"
                name={'DATA_INICIAL'}
                control={control}
                value={dateInicial}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATA_FINAL'}
                control={control}
                value={dateFinal}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />
              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width="12.153vw"
                height="3.333vw"
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por página</h5>
              <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} width={'4vw'} initialValue={10} />
              <h5>Total de Resultados: {HISTORICO?.pagination?.totalResult}</h5>
            </SecondFilterLine>
          </form>
        </Filter>

        {loadingUserBalance ? (
          <Loading />
        ) : (
          <Table
            loading={loadingUserBalance}
            error={errorUserBalance}
            header={header}
            body={body}
            filter={filterData}
            setFilter={setFilterData}
            hasPagination={true}
            pagination={HISTORICO?.pagination}
            limit={filterData.LIMIT}
          />
        )}
      </>
    </Styled.GraphicSheetContainer>
  );
}
