import { CircularProgress } from '@mui/material';
import * as S from './Loading.styles';

const Loading = () => {
  return (
    <S.LoadingContainer>
      <CircularProgress size={'5vw'} color="inherit" />
    </S.LoadingContainer>
  );
};

export default Loading;
