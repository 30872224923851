import styled from 'styled-components';

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
`;
export const FirstFilterLine = styled.div`
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  width: ${(props) => props.width || 'auto'};
  align-items: center;
  margin-bottom: 1vw;
  gap: 1vw;
`;

export const SecondFilterLine = styled.div`
  display: flex;
  border-radius: 5px;
  justify-content: flex-end;
  width: ${(props) => props.width || 'auto'};

  align-items: center;
  margin: 1vw 0;
  h5 {
    margin-left: 1vw;
    font-family: Poppins;
    font-size: 0.972vw;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }
`;
