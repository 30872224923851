import * as Styled from './Card.styles';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import formatMoney from '../../utils/functions/formatMoneyBRL';
import verifyArray from '../../utils/verifyArray';
import { useDispatch } from 'react-redux';
import { getDataSaldoGeralFetchAction } from '../../store/fetchActions/getSaldoGeral';
import moment from 'moment';
import DateInput from '../DateInput/DataInput';
import validDate from '../../utils/functions/ValidDate';

export default function Card({
  isExpanded,
  setIsExpanded,
  expandedHeight,
  height,
  width,
  color,
  iconColor,
  backgroundColorContainer,
  icon,
  text,
  value,
  name,
  data,
}) {
  const dispatch = useDispatch();

  const initialDate = moment().format('YYYY-MM-DD');
  const [date, setDate] = useState({
    dataInicial: initialDate,
  });

  useMemo(() => {
    name === 'Conta Digital' &&
      validDate(date?.dataInicial) &&
      dispatch(getDataSaldoGeralFetchAction(date?.dataInicial ?? initialDate));
  }, [date?.dataInicial]);

  return (
    <Styled.CardComponent width={width} height={isExpanded ? expandedHeight : height}>
      <Styled.Rectangle color={color} />
      <Styled.Container>
        <Styled.Line>
          <Styled.CardHeaderContainer>
            <Styled.IconContainer iconColor={iconColor} backgroundcolor={backgroundColorContainer}>
              {icon}
            </Styled.IconContainer>
            <Styled.Name>{name}</Styled.Name>
          </Styled.CardHeaderContainer>
          <Styled.CardHeaderContainer>
            <Styled.Button size="small" onClick={() => setIsExpanded(!isExpanded)} data-cy="btn-isExpanded-cards">
              {isExpanded ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </Styled.Button>
          </Styled.CardHeaderContainer>
        </Styled.Line>
        <Styled.LineValue>
          <Styled.NameTotal>{text}</Styled.NameTotal>
          <Styled.Value>R$ {formatMoney(value)}</Styled.Value>
        </Styled.LineValue>

        {verifyArray(data) &&
          data.map((card, index) => {
            return (
              <div key={'itensCard-' + index}>
                <Styled.LineInCard display={isExpanded ? 'flex' : 'none'}>
                  <Styled.TextInCard>{card.name}</Styled.TextInCard>
                  <Styled.ValueInCard>
                    {card.name === 'Data' || card.name === 'Hora' ? card.value : 'R$ ' + formatMoney(card.value)}
                  </Styled.ValueInCard>
                </Styled.LineInCard>
                {isExpanded && index !== data?.length - 1 && <Styled.LineSeparator />}
              </div>
            );
          })}

        {name === 'Conta Digital' && (
          <Styled.LineData>
            <DateInput
              label="Data  "
              value={date?.dataInicial}
              onChange={(newValue) =>
                newValue?.$d
                  ? setDate({ dataInicial: moment(newValue?.$d).format('YYYY-MM-DD') })
                  : setDate({ dataInicial: '' })
              }
              fullWidth
            />
          </Styled.LineData>
        )}
      </Styled.Container>
    </Styled.CardComponent>
  );
}

Card.propTypes = {
  icon: PropTypes.object,
  width: PropTypes.string,
  expandedHeight: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.any,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  data: PropTypes.array,
  color: PropTypes.string,
  backgroundColorContainer: PropTypes.string,
  iconColor: PropTypes.string,
};
