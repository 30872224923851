import Styled from 'styled-components';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = Styled(MuiAccordion)`

`;

export const AccordionSummary = Styled(MuiAccordionSummary)`

  background-color: ${(props) => props.backcolor ?? props.theme.colors.veryDarkCyan} !important;
  .MuiAccordionSummary-content{
    color: ${(props) => props.mycolor ?? props.theme.colors.white}
  }

`;

export const AccordionDetails = Styled(MuiAccordionDetails)`

`;

export const Container = Styled.div`
  padding: 0.3rem;
  width: 100%;
`;
