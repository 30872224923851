import api from '../../utils/services/api';
import { showAlert } from '../ducks/sweetAlert';

import {
  taxesActionError,
  taxesActionLoading,
  taxesActionSuccess,
  editTaxesActionError,
  editTaxesActionLoading,
  editTaxesActionSuccess,
} from '../ducks/taxes';
import { perfisFetchAction } from './perfisActions';

export const taxesFetchAction = (id) => {
  return async (dispatch) => {
    dispatch(taxesActionLoading());
    try {
      const response = await api.get(`/coban/taxa/listar?ID_COBAN=${id}`);
      dispatch(taxesActionSuccess(response.data));
    } catch (error) {
      dispatch(taxesActionError(error?.response?.data));
    }
  };
};

export const editTaxesFetchAction = (data, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(perfisFetchAction());
    };
    dispatch(editTaxesActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.put(`/coban/atualizar/taxas`, data);
      dispatch(editTaxesActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso!',
          text: 'Taxas editadas com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(editTaxesActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
