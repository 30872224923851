import api from '../../utils/services/api';
import { cobansActionLoading, cobansActionSuccess, cobansActionError } from '../ducks/cobans';

export const cobansFetchAction = () => {
  return async (dispatch) => {
    dispatch(cobansActionLoading());
    try {
      const response = await api.get(`coban/listar`);
      dispatch(cobansActionSuccess(response.data));
    } catch (error) {
      dispatch(cobansActionError(error?.response?.data));
    }
  };
};
