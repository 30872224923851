import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  paginationBankSlips: { totalResult: 0 },
  loadingBankSlips: false,
  messageBankSlips: '',
  dataBankSlips: [],
  errorBankSlips: false,
};

export const bankSlipsActionLoading = createAction('BANK_SLIPS_ACTION_LOADING');
export const bankSlipsActionSuccess = createAction('BANK_SLIPS_ACTION_SUCCESS');
export const bankSlipsActionError = createAction('BANK_SLIPS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [bankSlipsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingBankSlips: true,
      errorBankSlips: false,
    };
  },
  [bankSlipsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBankSlips: false,
      errorBankSlips: false,
      messageBankSlips: payload?.mensagem,
      paginationBankSlips: payload?.data?.pagination,
      dataBankSlips: payload?.data?.RETORNO,
    };
  },
  [bankSlipsActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBankSlips: false,
      errorBankSlips: true,
      messageBankSlips: payload?.mensagem,
      paginationBankSlips: {},
      dataBankSlips: [],
    };
  },
});
