import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      style={{ cursor: 'pointer' }}
      onClick={(e) => props.onClick()}
    >
      <circle cx="20" cy="20" r="20" fill="#101410" fillOpacity="0.1"></circle>
      <path
        fill="#2F3545"
        d="M14 14c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4zm6 10.2c0-.96.5-1.86 1.2-2.46V20.5c0-.05.02-.11.02-.16-.99-.22-2.07-.34-3.22-.34-4.42 0-8 1.79-8 4v2h10v-1.8zm10 .1v3.5c0 .6-.6 1.2-1.3 1.2h-5.5c-.6 0-1.2-.6-1.2-1.3v-3.5c0-.6.6-1.2 1.2-1.2v-2.5c0-1.4 1.4-2.5 2.8-2.5s2.8 1.1 2.8 2.5v.5h-1.3v-.5c0-.8-.7-1.3-1.5-1.3s-1.5.5-1.5 1.3V23h4.3c.6 0 1.2.6 1.2 1.3z"
      ></path>
    </svg>
  );
}

export default Icon;
