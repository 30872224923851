import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  requestedUsersData: [],
  pagination: { totalResult: 0 },
};

export const requestedUsersActionLoading = createAction('REQUESTED_USERS_ACTION_LOADING');
export const requestedUsersActionSuccess = createAction('REQUESTED_USERS_ACTION_SUCCESS');
export const requestedUsersActionError = createAction('REQUESTED_USERS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [requestedUsersActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingRequestedUsers: true,
      requestedUsersData: [],
      pagination: {},
    };
  },
  [requestedUsersActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingRequestedUsers: false,
      error: false,
      requestedUsersData: payload.DATA,
      pagination: payload.PAGINATION,
    };
  },
  [requestedUsersActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingRequestedUsers: false,
      error: true,
      errorMessage: payload?.mensagem,
      requestedUsersData: [],
      pagination: {},
    };
  },
});
