import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="none" viewBox="0 0 24 22">
      <path
        fill="#000"
        d="M13.473 1.349a1.694 1.694 0 00-2.94 0L.248 18.849c-.686 1.168.136 2.651 1.47 2.651h20.569c1.334 0 2.157-1.485 1.47-2.65L13.473 1.348zM12 6.5c.803 0 1.431.693 1.35 1.493l-.525 5.26a.828.828 0 01-1.65 0l-.525-5.26A1.36 1.36 0 0112 6.5zm.003 9a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
      ></path>
    </svg>
  );
}

export default Icon;
