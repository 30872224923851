import React from 'react';
import PropTypes from 'prop-types';
import TabsMaterial from '@material-ui/core/Tabs';
import * as Styled from './Tabs.styles';
import { Aprovado, Bloqueado, Pendente, Negado, Encerrado } from '../../assets/icon';
import { useTheme } from 'styled-components';

export default function Tabs({ labels, setTabIndex, tabIndex, width, margin, variant }) {
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderTab = (label) => {
    return (
      <Styled.UserNotificationContainer>
        {label?.STATUS === 'ATIVO' ? (
          <>
            <Aprovado paddingRight={'0.4rem'} /> <h4>{label.CONTA}</h4>
          </>
        ) : label?.STATUS === 'PENDENTE' ? (
          <>
            <Pendente paddingRight={'0.4rem'} /> <h4>{label.CONTA}</h4>
          </>
        ) : label?.STATUS === 'BLOQUEADO' ? (
          <>
            <Bloqueado paddingRight={'0.4rem'} /> <h4>{label.CONTA}</h4>
          </>
        ) : label?.STATUS === 'NEGADO' ? (
          <>
            <Negado paddingRight={'0.4rem'} /> <h4>{label.CONTA}</h4>
          </>
        ) : label?.STATUS === 'ENCERRADO' ? (
          <>
            <Encerrado paddingRight={'0.4rem'} /> <h4>{label.CONTA}</h4>
          </>
        ) : (
          ''
        )}
      </Styled.UserNotificationContainer>
    );
  };

  return (
    <div>
      <Styled.AppBar position="static" width={width} margin={margin}>
        <TabsMaterial value={tabIndex} onChange={handleChange} variant={variant}>
          {labels?.map((label, index) => {
            return (
              <Styled.Tooltips
                title={
                  label?.STATUS === 'BLOQUEADO' ? (
                    <>
                      <Bloqueado paddingRight={'0.4rem'} color={theme.colors.brightRed} />
                      <h5>Conta bloqueada!</h5>
                    </>
                  ) : label?.STATUS === 'PENDENTE' ? (
                    <>
                      <Pendente paddingRight={'0.4rem'} color={theme.colors.brightRed} />
                      <h5>Conta pendente!</h5>
                    </>
                  ) : label?.STATUS === 'NEGADO' ? (
                    <>
                      <Negado paddingRight={'0.4rem'} color={theme.colors.brightRed} />
                      <h5>Conta negada!</h5>
                    </>
                  ) : label?.STATUS === 'ENCERRADO' ? (
                    <>
                      <Encerrado paddingRight={'0.4rem'} color={theme.colors.brightRed} />
                      <h5>Conta encerrada!</h5>
                    </>
                  ) : (
                    ''
                  )
                }
              >
                <Styled.TabUsers label={renderTab(label)} key={index}>
                  {label?.STATUS}
                </Styled.TabUsers>
              </Styled.Tooltips>
            );
          })}
        </TabsMaterial>
      </Styled.AppBar>
    </div>
  );
}

Tabs.propTypes = {
  labels: PropTypes.array,
  setTabIndex: PropTypes.func,
  tabIndex: PropTypes.number,
  width: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
};
