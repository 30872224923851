import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from '@mui/material/Typography';
import { useTheme } from 'styled-components';
import * as S from './Accordion.styles';

const Accordion = (props) => {
  const theme = useTheme();
  return (
    <S.Container>
      <S.Accordion expanded={props.expanded === props.open} onChange={props.handleChange(props.open)}>
        <S.AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            <ArrowForwardIosSharpIcon
              sx={{ fontSize: '0.9rem', transform: 'rotate(90deg)', color: props?.mycolor ?? theme.colors.white_2 }}
            />
          }
          backcolor={props?.backcolor}
          mycolor={props?.mycolor}
        >
          <Typography>{props?.title}</Typography>
        </S.AccordionSummary>
        <S.AccordionDetails>{props?.children}</S.AccordionDetails>
      </S.Accordion>
    </S.Container>
  );
};

export default Accordion;
