import api from '../../utils/services/api';

import {
  approvedUsersActionError,
  approvedUsersActionLoading,
  approvedUsersActionSuccess,
} from '../ducks/approvedUsers';

export const approvedUsersFetchAction = (filtro = { LIMIT: 10, PAGE: 1 }) => {
  return async (dispatch) => {
    dispatch(approvedUsersActionLoading());
    try {
      const response = await api.post(`usuario/listar/aprovado`, filtro);
      dispatch(approvedUsersActionSuccess(response.data));
    } catch (error) {
      dispatch(approvedUsersActionError(error?.response?.data));
    }
  };
};
