import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import InputFormatValue from '../../../../components/InputFormatValue';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { editTariffsFetchAction, tariffsFetchAction } from '../../../../store/fetchActions/tariffsActions';
import * as S from './TargetRates.styles';
import { useTheme } from 'styled-components';

const TargetRates = ({ open, close, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control, reset, register } = useForm({});

  useEffect(() => {
    reset();
    dispatch(tariffsFetchAction(id));
  }, []);

  const { tariffsData, loadingTariffs } = useSelector((selector) => selector.tariffs);

  const callEditTaxesAction = (data) => {
    const arrayTarifas = data?.Tarifas.reduce(function (target, key, index) {
      target[key.id] = key.valor?.replace('R$', '').replace(/\s/g, '');
      return target;
    }, {});

    const param = {
      ID_COBAN: id,
      TARIFAS: arrayTarifas,
    };

    dispatch(editTariffsFetchAction(param, (reset(), close())));
  };

  return (
    <Modal open={open} close={close} title={'Atualizar Tarifas'} width={'80vw'}>
      {!loadingTariffs && (
        <form onSubmit={handleSubmit(callEditTaxesAction)}>
          <S.Container>
            {tariffsData?.map((tax, indx) => {
              return (
                <S.SectionInput key={'Tarifas-' + tax.ID}>
                  <h3>{tax.DESCRICAO}</h3>

                  <input
                    hidden
                    {...register(`Tarifas.${indx}.id`, {
                      value: tax.ID,
                    })}
                  />
                  <InputFormatValue
                    name={tax?.DESCRICAO}
                    control={control}
                    width={'18vw'}
                    {...register(`Tarifas.${indx}.valor`, {
                      value: tax.VALOR,
                    })}
                  />
                </S.SectionInput>
              );
            })}
          </S.Container>
          <S.SectionButton>
            <Button
              backgroundcolor={theme.colors.buttonConfirm}
              width={'29.306vw'}
              height={'3.125vw'}
              margin={'1vw 0'}
              hoverbgcolor={theme.colors.buttonConfirmHover}
              hovercolor={theme.colors.buttonConfirmHoverText}
              mycolor={theme.colors.buttonConfirmText}
              type="submit"
            >
              <span>Salvar</span>
            </Button>
          </S.SectionButton>
        </form>
      )}
    </Modal>
  );
};

export default TargetRates;
