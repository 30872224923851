import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  walletsData: [],
  errorMessageWallets: '',
  errorWallets: false,
  loadingWallets: false,
  paginationWallets: {},
};

export const walletsActionLoading = createAction('WALLETS_ACTION_LOADING');
export const walletsActionSuccess = createAction('WALLETS_ACTION_SUCCESS');
export const walletsActionError = createAction('WALLETS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [walletsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingWallets: true,
      errorWallets: false,
    };
  },
  [walletsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingWallets: false,
      errorWallets: false,
      walletsData: payload.data,
      // paginationWallets: payload?.pagination,
    };
  },
  [walletsActionError.type]: (state) => {
    return {
      ...state,
      loadingWallets: false,
      errorWallets: true,
      walletsData: [],
      // errorMessageWallets: payload?.mensagem,
    };
  },
});
