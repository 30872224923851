import { memo, useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import { pendingAccountsFetchAction } from '../../../../store/fetchActions/userAccounts';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../../components/Filter/Filter';
import TextField from '../../../../components/TextField';
import SelectOptions from '../../../../components/SelectOptions';
import { ImSearch } from 'react-icons/im';
import Button from '../../../../components/Button/Button';
import { useForm } from 'react-hook-form';
import formatCPF from '../../../../utils/functions/formatCPF';
import formatCNPJ from '../../../../utils/functions/formatCNPJ';
import DateInput from '../../../../components/DateInput';
import { FirstFilterLine, SecondFilterLine } from '../../../../components/Filter/Filter.styles';
import useCallFilter from '../../../../utils/hooks/useCallFilter';
import { detailAccountsFetchAction } from '../../../../store/fetchActions/accountActions';
import * as dayjs from 'dayjs';
import { detailAccountsFetchActionReset } from '../../../../store/fetchActions/accountActions';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { pagesOptions } from '../../../../utils/constant';
import Loading from '../../../../components/Loading';
import ModalContasPendentes from '../Modals/ModalContasPendentes';

function UsuariosPendentes({ value, index }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [filterData, setFilterData] = useState('');
  const [idConta, setIdConta] = useState('');
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });

  const { handleSubmit, control } = useForm({});

  useCallFilter(filterData, setFilter, pendingAccountsFetchAction, value, index);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  useEffect(() => {
    dispatch(pendingAccountsFetchAction(filter));
  }, [filter.PAGE]);

  const { pendingAccountsData, pagination, loadingPendingAccounts, errorPendingAccounts } = useSelector(
    (state) => state.userAccounts,
  );

  const closeModalInfo = () => {
    setIsModalInfoOpen(false), dispatch(detailAccountsFetchActionReset());
  };

  const header = [
    {
      id: 'data de registro',
      name: 'DATA DE REGISTRO',
      align: 'center',
    },
    {
      id: 'CPFCNPJ',
      name: 'CPF/CNPJ',
      align: 'left',
    },
    {
      id: 'nome',
      name: 'NOME',
      align: 'left',
    },
  ];

  const body = pendingAccountsData?.map((user) => {
    return {
      data: [
        {
          info: dayjs(user.DT_INSERT).format('DD/MM/YYYY HH:mm:ss') || '-----',
          align: 'center',
        },
        {
          info: user.CPFCNPJ?.length === 11 ? formatCPF(user?.CPFCNPJ) : formatCNPJ(user?.CPFCNPJ),
          align: 'left',
        },
        { info: user.NOME, align: 'left' },
      ],
      onClick: () => inspectUser(user.ID),
    };
  });

  function inspectUser(id) {
    dispatch(detailAccountsFetchAction(id));
    setIsModalInfoOpen(true);
    setIdConta(id);
  }

  return (
    <div>
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField
              label="Nome ou CPF/CNPJ"
              name={'SEARCH'}
              width={'38.042vw'}
              margin={'0 0 0 1.369vw'}
              control={control}
            />
            <DateInput
              label="Data Inicial"
              name={'DATA_INICIAL'}
              control={control}
              value={dateInicial}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <DateInput
              label="Data Final"
              name={'DATA_FINAL'}
              control={control}
              value={dateFinal}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
              }
            />

            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>
          <SecondFilterLine>
            <h5>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {pagination?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>

      {loadingPendingAccounts ? (
        <Loading />
      ) : (
        <Table
          error={errorPendingAccounts}
          hasPagination={true}
          filter={filter}
          pagination={pagination}
          setFilter={setFilter}
          filterTable={filterTable}
          header={header}
          body={body}
          limit={filter.LIMIT}
        />
      )}

      {isModalInfoOpen && <ModalContasPendentes open={isModalInfoOpen} close={closeModalInfo} idConta={idConta} />}
    </div>
  );
}

export default memo(UsuariosPendentes);
