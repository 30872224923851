import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  cobansData: [],
  errorCobans: false,
  loadingCobans: true,
  errorMessageCobans: '',
};

export const cobansActionLoading = createAction('COBANS_ACTION_LOADING');
export const cobansActionSuccess = createAction('COBANS_ACTION_SUCCESS');
export const cobansActionError = createAction('COBANS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [cobansActionLoading.type]: (state, payload) => {
    return {
      ...state,
      loadingCobans: true,
      errorCobans: false,
      cobanDatas: [],
    };
  },
  [cobansActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingCobans: false,
      errorCobans: false,
      cobansData: payload.data,
    };
  },
  [cobansActionError.type]: (state, { payload }) => {
    return {
      ...state,
      loadingCobans: false,
      errorCobans: true,
      cobansData: [],
      errorMessageCobans: payload?.mensagem,
    };
  },
});
