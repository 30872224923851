import { useState, memo } from 'react';
import { useForm } from 'react-hook-form';
import { MdErrorOutline, MdHighlightOff } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../store/ducks/sweetAlert';
import { validatePasswordFetchAction } from '../../store/fetchActions/validatePasswordActions';
import Button from '../Button/Button';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import TextField from '../TextField';
import * as Styled from './ModalPassword.styles';
import { useTheme } from 'styled-components';

function ModalPassword({ openModal, closeModal, nextFunction }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isModalWrongPasswordOpen, setIsModalWrongPasswordOpen] = useState(false);
  const [openModalPassword, setOpenModalPassword] = useState(false);

  const { handleSubmit: handleUserPassword, control: controlPassword, reset: resetPassword } = useForm({});

  const closeModalPassword = () => {
    resetPassword();
    closeModal();
    setOpenModalPassword(false);
  };

  const openModalWrongPassword = () => setIsModalWrongPasswordOpen(true);
  const closeModalWrongPassword = () => setIsModalWrongPasswordOpen(false);

  const validatePassword = (data) => {
    dispatch(validatePasswordFetchAction(data, passwordValidation));
  };

  const tryPasswordValidationAgain = () => {
    setIsModalWrongPasswordOpen(false);
    setOpenModalPassword(true);
  };

  const passwordValidation = (status) => {
    if (status === 200) {
      nextFunction();
    } else if (status === 403) {
      openModalWrongPassword();
    } else {
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Algo inesperando aconteceu',
          icon: 'error',
        }),
      );
    }
    closeModalPassword();
  };

  return (
    <>
      <Modal open={openModal || openModalPassword} close={closeModalPassword}>
        <Styled.SmallerModal>
          <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
          <h3>Inserir Senha</h3>
          <h5>Para continuar, insira sua senha</h5>
          <h4>Senha</h4>
          <form onSubmit={handleUserPassword(validatePassword)}>
            <TextField
              name={'SENHA'}
              width={'100%'}
              control={controlPassword}
              required={true}
              type={'password'}
              inputProps={{ maxLength: 8 }}
            />
            <Styled.ButtonContainer>
              <Button
                backgroundcolor={theme.colors.buttonBack}
                width={'13.792vw'}
                height={'3.125vw'}
                hoverbgcolor={theme.colors.buttonBackHover}
                hovercolor={theme.colors.buttonBackHoverText}
                mycolor={theme.colors.buttonBackText}
                onClick={() => closeModalPassword()}
              >
                <span>Voltar</span>
              </Button>
              <Button
                backgroundcolor={theme.colors.buttonConfirm}
                width={'13.792vw'}
                height={'3.125vw'}
                hoverbgcolor={theme.colors.buttonConfirmHover}
                hovercolor={theme.colors.buttonConfirmHoverText}
                mycolor={theme.colors.buttonConfirmText}
                type="submit"
              >
                <span>Confirmar</span>
              </Button>
            </Styled.ButtonContainer>
          </form>
        </Styled.SmallerModal>
      </Modal>

      <Modal open={isModalWrongPasswordOpen} close={closeModalWrongPassword}>
        <Styled.SmallerModal>
          <MdHighlightOff color={theme.colors.strongRed} size={'6.944vw'} />
          <h3>Senha incorreta</h3>
          <h5>Tente novamente ou repita a ação mais tarde</h5>
          <Styled.ButtonContainer>
            <Button
              backgroundcolor={theme.colors.buttonBack}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.buttonBackHover}
              hovercolor={theme.colors.buttonBackHoverText}
              mycolor={theme.colors.buttonBackText}
              onClick={() => {
                closeModalWrongPassword();
              }}
            >
              <span>CANCELAR</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.red}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.red}
              hovercolor={theme.colors.white}
              mycolor={theme.colors.white}
              onClick={() => {
                tryPasswordValidationAgain();
              }}
            >
              <span>TENTAR NOVAMENTE</span>
            </Button>
          </Styled.ButtonContainer>
        </Styled.SmallerModal>
      </Modal>
    </>
  );
}

export default memo(ModalPassword);

ModalPassword.defaultProps = {
  nextFunction: {},
  closeModal: {},
  openModal: false,
};
ModalPassword.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  nextFunction: PropTypes.func,
};
