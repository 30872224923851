import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  pendingAccountsData: [],
  pagination: { totalResult: 0 },
  errorMessagePendingAccounts: '',
  errorMessageApprovedAccounts: '',
  approvedAccountsData: [],
  paginationApprovedAccounts: { totalResult: 0 },
  deniedAccountsData: [],
  paginationDeniedAccounts: { totalResult: 0 },
  blockedAccountsData: [],
  paginationBlockedAccounts: { totalResult: 0 },
  closedAccountsData: [],
  paginationClosedAccounts: { totalResult: 0 },
};

export const pendingAccountsActionLoading = createAction('PENDING_ACCOUNTS_ACTION_LOADING');
export const pendingAccountsActionSuccess = createAction('PENDING_ACCOUNTS_ACTION_SUCCESS');
export const pendingAccountsActionError = createAction('PENDING_ACCOUNTS_ACTION_ERROR');
export const approvedAccountsActionLoading = createAction('APPROVED_ACCOUNTS_ACTION_LOADING');
export const approvedAccountsActionSuccess = createAction('APPROVED_ACCOUNTS_ACTION_SUCCESS');
export const approvedAccountsActionError = createAction('APPROVED_ACCOUNTS_ACTION_ERROR');
export const deniedAccountsActionLoading = createAction('DENIED_ACCOUNTS_ACTION_LOADING');
export const deniedAccountsActionSuccess = createAction('DENIED_ACCOUNTS_ACTION_SUCCESS');
export const deniedAccountsActionError = createAction('DENIED_ACCOUNTS_ACTION_ERROR');
export const blockedAccountsActionLoading = createAction('BLOCKED_ACCOUNTS_ACTION_LOADING');
export const blockedAccountsActionSuccess = createAction('BLOCKED_ACCOUNTS_ACTION_SUCCESS');
export const blockedAccountsActionError = createAction('BLOCKED_ACCOUNTS_ACTION_ERROR');
export const closedAccountsActionLoading = createAction('CLOSED_ACCOUNTS_ACTION_LOADING');
export const closedAccountsActionSuccess = createAction('CLOSED_ACCOUNTS_ACTION_SUCCESS');
export const closedAccountsActionError = createAction('CLOSED_ACCOUNTS_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pendingAccountsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPendingAccounts: true,
      errorPendingAccounts: false,
      pendingAccountsData: [],
      pagination: {},
    };
  },
  [pendingAccountsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingPendingAccounts: false,
      errorPendingAccounts: false,
      pendingAccountsData: payload.data,
      pagination: payload?.pagination,
    };
  },
  [pendingAccountsActionError.type]: (state) => {
    return {
      ...state,
      loadingPendingAccounts: false,
      errorPendingAccounts: true,
      // errorMessagePendingAccounts: payload.mensagem,
      pendingAccountsData: [],
      pagination: {},
    };
  },
  [approvedAccountsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingApprovedAccounts: true,
      errorMessageApprovedAccounts: false,
      approvedAccountsData: [],
      paginationApprovedAccounts: {},
    };
  },
  [approvedAccountsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingApprovedAccounts: false,
      errorMessageApprovedAccounts: false,
      approvedAccountsData: payload.data,
      paginationApprovedAccounts: payload?.pagination,
    };
  },
  [approvedAccountsActionError.type]: (state) => {
    return {
      ...state,
      loadingApprovedAccounts: false,
      errorApprovedAccounts: true,
      approvedAccountsData: [],
      paginationApprovedAccounts: {},
    };
  },
  [deniedAccountsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDeniedAccounts: true,
      errorMessageDeniedAccounts: false,
      deniedAccountsData: [],
      paginationDeniedAccounts: {},
    };
  },
  [deniedAccountsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingDeniedAccounts: false,
      errorMessageDeniedAccounts: false,
      deniedAccountsData: payload.data,
      paginationDeniedAccounts: payload?.pagination,
    };
  },
  [deniedAccountsActionError.type]: (state) => {
    return {
      ...state,
      loadingDeniedAccounts: false,
      errorDeniedAccounts: true,
      deniedAccountsData: [],
      paginationDeniedAccounts: {},
    };
  },
  [blockedAccountsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingBlockedAccounts: true,
      errorMessageBlockedAccounts: false,
      blockedAccountsData: [],
      paginationBlockedAccounts: {},
    };
  },
  [blockedAccountsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingBlockedAccounts: false,
      errorMessageblockedAccounts: false,
      blockedAccountsData: payload.data,
      paginationBlockedAccounts: payload?.pagination,
    };
  },
  [blockedAccountsActionError.type]: (state) => {
    return {
      ...state,
      loadingBlockedAccounts: false,
      errorBlockedAccounts: true,
      blockedAccountsData: [],
      paginationBlockedAccounts: {},
    };
  },
  [closedAccountsActionLoading.type]: (state) => {
    return {
      ...state,
      loadingClosedAccounts: true,
      errorMessageClosedAccounts: false,
      closedAccountsData: [],
      paginationClosedAccounts: {},
    };
  },
  [closedAccountsActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingClosedAccounts: false,
      errorMessageClosedAccounts: false,
      closedAccountsData: payload.data,
      paginationClosedAccounts: payload?.pagination,
    };
  },
  [closedAccountsActionError.type]: (state) => {
    return {
      ...state,
      loadingClosedAccounts: false,
      errorClosedAccounts: true,
      closedAccountsData: [],
      paginationClosedAccounts: {},
    };
  },
});
