import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {};

export const retrievePasswordActionLoading = createAction('RETRIEVE_PASSWORD_ACTION_LOADING');
export const retrievePasswordActionSuccess = createAction('RETRIEVE_PASSWORD_ACTION_SUCCESS');
export const retrievePasswordActionError = createAction('RETRIEVE_PASSWORD_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [retrievePasswordActionLoading.type]: (state) => {
    return {
      ...state,
      loadingRetrievePassword: true,
      errorRetrievePassword: false,
    };
  },
  [retrievePasswordActionSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      loadingRetrievePassword: false,
      errorRetrievePassword: false,
    };
  },
  [retrievePasswordActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingRetrievePassword: false,
      errorRetrievePassword: true,
    };
  },
});
