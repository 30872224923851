import Wrapper from '../../components/Wrapper/Wrapper';
import Tabs from '../../components/Tabs/Tabs';
import React, { useState } from 'react';
import { SendNotification, ViewNotification, ManageGroup } from './components/index';

const Notificacoes = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Enviar Notificações', 'Histórico de notificações', 'Gerenciar Grupos'];

  return (
    <Wrapper title="Notificações">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" account={true} />
      {tabIndex === 0 && <SendNotification value={tabIndex} index={0} />}
      {tabIndex === 1 && <ViewNotification value={tabIndex} index={1} />}
      {tabIndex === 2 && <ManageGroup value={tabIndex} index={2} />}
    </Wrapper>
  );
};

export default Notificacoes;
