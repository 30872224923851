import React from 'react';
import * as Styled from './StatusContainer.style';
import PropTypes from 'prop-types';
export default function StatusContainer({ code, description }) {
  let background = '';
  let color = '';
  switch (code) {
    case '1':
      background = '#F8AF2240';
      color = '#FFAA05';
      break;
    case '2':
      background = 'rgba(10, 131, 8, 0.1)';
      color = '#0A8308';
      break;
    case '3':
      background = 'rgba(242, 48, 5, 0.1)';
      color = '#F23005';
      break;
    case '4':
      background = 'rgba(125, 125, 125, 0.15)';
      color = '#7D7D7D';
      break;
    case '5':
      background = 'rgba(255, 107, 64, 0.25)';
      color = 'rgba(246, 105, 25, 1)';
      break;
    case '6':
      background = 'rgba(0, 165, 255, 0.25)';
      color = 'rgba(0, 99, 255, 1)';
      break;
    case '7':
      background = 'rgba(36, 5, 242, 0.25)';
      color = 'rgba(36, 5, 242, 1)';
      break;
    case 'EFETIVADA':
      background = 'rgba(10, 131, 8, 0.1)';
      color = '#0A8308';
      break;
    case 'CANCELADA':
      background = 'rgba(242, 48, 5, 0.1)';
      color = '#F23005';
      break;
    default:
      background = 'rgba(125, 125, 125, 0.15)';
      color = '#7D7D7D';
  }

  return (
    <div>
      <Styled.ContainerStatus background={background} color={color}>
        {description}
      </Styled.ContainerStatus>
    </div>
  );
}

StatusContainer.propTypes = {
  code: PropTypes.string,
  description: PropTypes.string,
};
